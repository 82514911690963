import {
    IDBProject,
    IProject,
    ETables,
    IAPIProject,
    IAPIProjectsFilter,
    IAPIProjectInput,
    TFilterKey,
    ProjectStatus,
    IAdaptorWithCache,
} from '@ab-task/types';
import { normalize, ID2GUID, GUID2ID } from './core';
import { PROJECT_STATUSES } from '@ab-task/data';
import { T } from '@ab-task/internationalization';
import { CoreError } from '@ab-task/errors';
import { creatorAlignmentsAPI2JS, creatorAlignmentsJS2API } from './creatorAlignments';

export function projectDB2JS(dbProject: IDBProject): IProject {
    return {
        id: dbProject.p_id,
        workspaceId: dbProject.p_workspace_id,
        name: dbProject.p_name,
        status: dbProject.p_status,
        creatorId: dbProject.p_creator_id,
        createdAt: dbProject.p_created_at,
        updatedAt: dbProject.p_updated_at,
        creatorAlignments: normalize(dbProject.p_creator_alignments),
        messagedAt: normalize(dbProject.p_messaged_at),
        unreads: dbProject.p_unreads,
        hasMentions: dbProject.p_has_mentions,
    };
}

export function projectDB2API(dbProject: IDBProject): IAPIProject {
    return {
        __typename: 'Project',
        id: ID2GUID(ETables.projects, dbProject.p_id),
        workspaceId: ID2GUID(ETables.workspaces, dbProject.p_workspace_id),
        name: dbProject.p_name,
        status: dbProject.p_status,
        creatorId: ID2GUID(ETables.users, dbProject.p_creator_id),
        createdAt: dbProject.p_created_at.toISOString(),
        updatedAt: dbProject.p_updated_at.toISOString(),
        creatorAlignments: dbProject.p_creator_alignments
            ? creatorAlignmentsJS2API(dbProject.p_creator_alignments)
            : null,
        messagedAt: dbProject.p_messaged_at ? dbProject.p_messaged_at.toISOString() : null,
        unreads: dbProject.p_unreads,
        hasMentions: dbProject.p_has_mentions,
    };
}

export function projectAPI2JS(apiProject: IAPIProject): IProject {
    return {
        id: GUID2ID(apiProject.id)[1],
        workspaceId: GUID2ID(apiProject.workspaceId)[1],
        name: apiProject.name,
        status: apiProject.status,
        creatorId: GUID2ID(apiProject.creatorId)[1],
        createdAt: new Date(apiProject.createdAt),
        updatedAt: new Date(apiProject.updatedAt),
        creatorAlignments: apiProject.creatorAlignments
            ? creatorAlignmentsAPI2JS(apiProject.creatorAlignments)
            : undefined,
        messagedAt: apiProject.messagedAt ? new Date(apiProject.messagedAt) : undefined,
        unreads: apiProject.unreads,
        hasMentions: apiProject.hasMentions,
    };
}

export function projectJS2APIInput(project: IProject): IAPIProjectInput {
    return {
        name: project.name,
    };
}

// Utils
export function getProjectsFilterKeyLabelMap() {
    return new Map<TFilterKey<IAPIProjectsFilter>, string>([
        ['id', 'Id'],
        ['workspaceId', T('workspace')],
        ['name', T('name')],
        ['status', T('status')],
        ['unreads', T('unreads')],
    ]);
}

export const projectFilterKey2Human: IAdaptorWithCache<
    TFilterKey<IAPIProjectsFilter>,
    string
> = filterKey => {
    if (!projectFilterKey2Human.cache) {
        projectFilterKey2Human.cache = new Map([
            ['id', 'ID'],
            ['workspaceId', T('workspace')],
            ['name', T('name')],
            ['status', T('status')],
            ['unreads', T('unreads')],
        ]);
    }

    const labelsMap = projectFilterKey2Human.cache;
    const label = labelsMap.get(filterKey);

    if (label === undefined) {
        throw CoreError.TYPE_ADAPTOR_FAILED({
            info: `Expected label for filterKey "${filterKey}" but got undefined`,
        });
    }

    return label;
};

export const projectStatus2Human: IAdaptorWithCache<ProjectStatus, string> = status => {
    if (!projectStatus2Human.cache) {
        projectStatus2Human.cache = new Map(
            PROJECT_STATUSES.map(status => [status, T(`status:${status}`)])
        );
    }

    const labelsMap = projectStatus2Human.cache;
    const label = labelsMap.get(status);

    if (label === undefined) {
        throw CoreError.TYPE_ADAPTOR_FAILED({
            info: `Expected label for status "${status}" but got undefined`,
        });
    }

    return label;
};
