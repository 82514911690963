/* eslint-disable */
import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import {
    MessageFieldsFragmentDoc,
    AttachmentFieldsFragmentDoc,
    SelectionFieldsFragmentDoc,
} from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchMessageQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchMessageQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Emoji' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | {
              __typename: 'Message';
              id: string;
              creatorId: string;
              authorIds: Array<string>;
              content?: string | null;
              version: number;
              isFirst: boolean;
              isLast: boolean;
              createdAt: string;
              updatedAt: string;
              isIncoming?: boolean | null;
              isReadonly?: boolean | null;
              isBig?: boolean | null;
              attachmentS3Keys?: Array<string> | null;
              watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
              reactions: Array<{
                  __typename: 'MessageReaction';
                  userId: string;
                  emojiId: string;
                  reactedAt: string;
              }>;
          }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Task' }
        | { __typename: 'Topic' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIUpdateMessageMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    messageId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
    clientGuid: Types.Scalars['ID']['input'];
    version: Types.Scalars['Int']['input'];
    steps: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type IAPIUpdateMessageMutation = {
    __typename: 'Mutation';
    updateMessage: {
        __typename: 'MessageMutationResult';
        clientId: string;
        channelId: string;
        steps: Array<string>;
        message: {
            __typename: 'Message';
            id: string;
            creatorId: string;
            authorIds: Array<string>;
            content?: string | null;
            version: number;
            isFirst: boolean;
            isLast: boolean;
            createdAt: string;
            updatedAt: string;
            isIncoming?: boolean | null;
            isReadonly?: boolean | null;
            isBig?: boolean | null;
            attachmentS3Keys?: Array<string> | null;
            watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
            reactions: Array<{
                __typename: 'MessageReaction';
                userId: string;
                emojiId: string;
                reactedAt: string;
            }>;
        };
    };
};

export type IAPIBroadcastMessageUpdateMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    clientId: Types.Scalars['ID']['input'];
    steps: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
    message: Types.IAPIMessageUpdateBroadcast;
}>;

export type IAPIBroadcastMessageUpdateMutation = {
    __typename: 'Mutation';
    broadcastMessageUpdate: {
        __typename: 'MessageMutationResult';
        clientId: string;
        channelId: string;
        steps: Array<string>;
        message: {
            __typename: 'Message';
            id: string;
            creatorId: string;
            authorIds: Array<string>;
            content?: string | null;
            version: number;
            isFirst: boolean;
            isLast: boolean;
            createdAt: string;
            updatedAt: string;
            isIncoming?: boolean | null;
            isReadonly?: boolean | null;
            isBig?: boolean | null;
            attachmentS3Keys?: Array<string> | null;
            watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
            reactions: Array<{
                __typename: 'MessageReaction';
                userId: string;
                emojiId: string;
                reactedAt: string;
            }>;
        };
    };
};

export type IAPIBroadcastSelectionUpdateMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    clientId: Types.Scalars['ID']['input'];
    selection: Types.IAPISelectionUpdateBroadcast;
}>;

export type IAPIBroadcastSelectionUpdateMutation = {
    __typename: 'Mutation';
    broadcastSelectionUpdate: {
        __typename: 'SelectionUpdateBroadcastResult';
        clientId: string;
        channelId: string;
        selection: {
            __typename: 'Selection';
            userId: string;
            userDisplayName: string;
            messageId?: string | null;
            messageVersion?: number | null;
            type?: Types.SelectionType | null;
            time?: number | null;
            anchor?: number | null;
            head?: number | null;
        };
    };
};

export type IAPIRemoveMessageMutationVariables = Types.Exact<{
    messageId: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveMessageMutation = {
    __typename: 'Mutation';
    removeMessage: {
        __typename: 'MessageMutationResult';
        clientId: string;
        channelId: string;
        steps: Array<string>;
        message: {
            __typename: 'Message';
            id: string;
            creatorId: string;
            authorIds: Array<string>;
            content?: string | null;
            version: number;
            isFirst: boolean;
            isLast: boolean;
            createdAt: string;
            updatedAt: string;
            isIncoming?: boolean | null;
            isReadonly?: boolean | null;
            isBig?: boolean | null;
            attachmentS3Keys?: Array<string> | null;
            watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
            reactions: Array<{
                __typename: 'MessageReaction';
                userId: string;
                emojiId: string;
                reactedAt: string;
            }>;
        };
    };
};

export type IAPIWatchMessagesMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    messageIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type IAPIWatchMessagesMutation = {
    __typename: 'Mutation';
    watchMessages: { __typename: 'WatchMessageResult'; unreads: number; hasMentions: boolean };
};

export type IAPIMentionUserMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    messageId: Types.Scalars['ID']['input'];
    userId: Types.Scalars['ID']['input'];
    mentionGuid: Types.Scalars['String']['input'];
}>;

export type IAPIMentionUserMutation = { __typename: 'Mutation'; mentionUser?: boolean | null };

export type IAPIUnmentionUserMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    messageId: Types.Scalars['ID']['input'];
    mentionGuid: Types.Scalars['String']['input'];
}>;

export type IAPIUnmentionUserMutation = { __typename: 'Mutation'; unmentionUser?: boolean | null };

export type IAPICreateReactionMutationVariables = Types.Exact<{
    messageId: Types.Scalars['ID']['input'];
    emojiId: Types.Scalars['ID']['input'];
}>;

export type IAPICreateReactionMutation = {
    __typename: 'Mutation';
    createReaction: {
        __typename: 'MessageMutationResult';
        clientId: string;
        channelId: string;
        steps: Array<string>;
        message: {
            __typename: 'Message';
            id: string;
            creatorId: string;
            authorIds: Array<string>;
            content?: string | null;
            version: number;
            isFirst: boolean;
            isLast: boolean;
            createdAt: string;
            updatedAt: string;
            isIncoming?: boolean | null;
            isReadonly?: boolean | null;
            isBig?: boolean | null;
            attachmentS3Keys?: Array<string> | null;
            watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
            reactions: Array<{
                __typename: 'MessageReaction';
                userId: string;
                emojiId: string;
                reactedAt: string;
            }>;
        };
    };
};

export type IAPIRemoveReactionMutationVariables = Types.Exact<{
    messageId: Types.Scalars['ID']['input'];
    emojiId: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveReactionMutation = {
    __typename: 'Mutation';
    removeReaction: {
        __typename: 'MessageMutationResult';
        clientId: string;
        channelId: string;
        steps: Array<string>;
        message: {
            __typename: 'Message';
            id: string;
            creatorId: string;
            authorIds: Array<string>;
            content?: string | null;
            version: number;
            isFirst: boolean;
            isLast: boolean;
            createdAt: string;
            updatedAt: string;
            isIncoming?: boolean | null;
            isReadonly?: boolean | null;
            isBig?: boolean | null;
            attachmentS3Keys?: Array<string> | null;
            watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
            reactions: Array<{
                __typename: 'MessageReaction';
                userId: string;
                emojiId: string;
                reactedAt: string;
            }>;
        };
    };
};

export type IAPIMessageUpdatedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPIMessageUpdatedSubscription = {
    __typename: 'Subscription';
    messageUpdated?: {
        __typename: 'MessageMutationResult';
        channelId: string;
        clientId: string;
        steps: Array<string>;
        message: {
            __typename: 'Message';
            id: string;
            creatorId: string;
            authorIds: Array<string>;
            content?: string | null;
            version: number;
            isFirst: boolean;
            isLast: boolean;
            createdAt: string;
            updatedAt: string;
            isIncoming?: boolean | null;
            isReadonly?: boolean | null;
            isBig?: boolean | null;
            attachmentS3Keys?: Array<string> | null;
            watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
            reactions: Array<{
                __typename: 'MessageReaction';
                userId: string;
                emojiId: string;
                reactedAt: string;
            }>;
        };
    } | null;
};

export type IAPISelectionUpdatedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPISelectionUpdatedSubscription = {
    __typename: 'Subscription';
    selectionUpdated?: {
        __typename: 'SelectionUpdateBroadcastResult';
        channelId: string;
        clientId: string;
        selection: {
            __typename: 'Selection';
            userId: string;
            userDisplayName: string;
            messageId?: string | null;
            messageVersion?: number | null;
            type?: Types.SelectionType | null;
            time?: number | null;
            anchor?: number | null;
            head?: number | null;
        };
    } | null;
};

export type IAPIMessageRemovedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPIMessageRemovedSubscription = {
    __typename: 'Subscription';
    messageRemoved?: {
        __typename: 'MessageMutationResult';
        channelId: string;
        clientId: string;
        steps: Array<string>;
        message: {
            __typename: 'Message';
            id: string;
            creatorId: string;
            authorIds: Array<string>;
            content?: string | null;
            version: number;
            isFirst: boolean;
            isLast: boolean;
            createdAt: string;
            updatedAt: string;
            isIncoming?: boolean | null;
            isReadonly?: boolean | null;
            isBig?: boolean | null;
            attachmentS3Keys?: Array<string> | null;
            watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
            reactions: Array<{
                __typename: 'MessageReaction';
                userId: string;
                emojiId: string;
                reactedAt: string;
            }>;
        };
    } | null;
};

export const FetchMessageDocument = gql`
    query FetchMessage($id: ID!) {
        node(id: $id) {
            ...MessageFields
        }
    }
    ${MessageFieldsFragmentDoc}
`;

/**
 * __useFetchMessageQuery__
 *
 * To run a query within a React component, call `useFetchMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchMessageQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchMessageQuery, IAPIFetchMessageQueryVariables> &
        ({ variables: IAPIFetchMessageQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchMessageDocument, options);
    return Apollo.useQuery<IAPIFetchMessageQuery, IAPIFetchMessageQueryVariables>(
        FetchMessageDocument,
        options
    );
}
export function useFetchMessageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchMessageQuery, IAPIFetchMessageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchMessageQuery, IAPIFetchMessageQueryVariables>(
        FetchMessageDocument,
        options
    );
}
export function useFetchMessageSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchMessageQuery,
        IAPIFetchMessageQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchMessageQuery, IAPIFetchMessageQueryVariables>(
        FetchMessageDocument,
        options
    );
}
export type FetchMessageQueryHookResult = ReturnType<typeof useFetchMessageQuery>;
export type FetchMessageLazyQueryHookResult = ReturnType<typeof useFetchMessageLazyQuery>;
export type FetchMessageSuspenseQueryHookResult = ReturnType<typeof useFetchMessageSuspenseQuery>;
export type FetchMessageQueryResult = Apollo.QueryResult<
    IAPIFetchMessageQuery,
    IAPIFetchMessageQueryVariables
>;
export const UpdateMessageDocument = gql`
    mutation UpdateMessage(
        $channelId: ID!
        $messageId: ID
        $clientGuid: ID!
        $version: Int!
        $steps: [String!]!
    ) {
        updateMessage(
            channelId: $channelId
            messageId: $messageId
            clientGuid: $clientGuid
            version: $version
            steps: $steps
        ) {
            clientId
            channelId
            steps
            message {
                ...MessageFields
            }
        }
    }
    ${MessageFieldsFragmentDoc}
`;
export type IAPIUpdateMessageMutationFn = Apollo.MutationFunction<
    IAPIUpdateMessageMutation,
    IAPIUpdateMessageMutationVariables
>;

/**
 * __useUpdateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageMutation, { data, loading, error }] = useUpdateMessageMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messageId: // value for 'messageId'
 *      clientGuid: // value for 'clientGuid'
 *      version: // value for 'version'
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useUpdateMessageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateMessageMutation,
        IAPIUpdateMessageMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateMessageMutation, IAPIUpdateMessageMutationVariables>(
        UpdateMessageDocument,
        options
    );
}
export type UpdateMessageMutationHookResult = ReturnType<typeof useUpdateMessageMutation>;
export type UpdateMessageMutationResult = Apollo.MutationResult<IAPIUpdateMessageMutation>;
export type UpdateMessageMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateMessageMutation,
    IAPIUpdateMessageMutationVariables
>;
export const BroadcastMessageUpdateDocument = gql`
    mutation BroadcastMessageUpdate(
        $channelId: ID!
        $clientId: ID!
        $steps: [String!]!
        $message: MessageUpdateBroadcast!
    ) {
        broadcastMessageUpdate(
            channelId: $channelId
            clientId: $clientId
            steps: $steps
            message: $message
        ) {
            clientId
            channelId
            steps
            message {
                ...MessageFields
            }
        }
    }
    ${MessageFieldsFragmentDoc}
`;
export type IAPIBroadcastMessageUpdateMutationFn = Apollo.MutationFunction<
    IAPIBroadcastMessageUpdateMutation,
    IAPIBroadcastMessageUpdateMutationVariables
>;

/**
 * __useBroadcastMessageUpdateMutation__
 *
 * To run a mutation, you first call `useBroadcastMessageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastMessageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastMessageUpdateMutation, { data, loading, error }] = useBroadcastMessageUpdateMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      clientId: // value for 'clientId'
 *      steps: // value for 'steps'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useBroadcastMessageUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIBroadcastMessageUpdateMutation,
        IAPIBroadcastMessageUpdateMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPIBroadcastMessageUpdateMutation,
        IAPIBroadcastMessageUpdateMutationVariables
    >(BroadcastMessageUpdateDocument, options);
}
export type BroadcastMessageUpdateMutationHookResult = ReturnType<
    typeof useBroadcastMessageUpdateMutation
>;
export type BroadcastMessageUpdateMutationResult =
    Apollo.MutationResult<IAPIBroadcastMessageUpdateMutation>;
export type BroadcastMessageUpdateMutationOptions = Apollo.BaseMutationOptions<
    IAPIBroadcastMessageUpdateMutation,
    IAPIBroadcastMessageUpdateMutationVariables
>;
export const BroadcastSelectionUpdateDocument = gql`
    mutation BroadcastSelectionUpdate(
        $channelId: ID!
        $clientId: ID!
        $selection: SelectionUpdateBroadcast!
    ) {
        broadcastSelectionUpdate(
            channelId: $channelId
            clientId: $clientId
            selection: $selection
        ) {
            clientId
            channelId
            selection {
                ...SelectionFields
            }
        }
    }
    ${SelectionFieldsFragmentDoc}
`;
export type IAPIBroadcastSelectionUpdateMutationFn = Apollo.MutationFunction<
    IAPIBroadcastSelectionUpdateMutation,
    IAPIBroadcastSelectionUpdateMutationVariables
>;

/**
 * __useBroadcastSelectionUpdateMutation__
 *
 * To run a mutation, you first call `useBroadcastSelectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastSelectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastSelectionUpdateMutation, { data, loading, error }] = useBroadcastSelectionUpdateMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      clientId: // value for 'clientId'
 *      selection: // value for 'selection'
 *   },
 * });
 */
export function useBroadcastSelectionUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIBroadcastSelectionUpdateMutation,
        IAPIBroadcastSelectionUpdateMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPIBroadcastSelectionUpdateMutation,
        IAPIBroadcastSelectionUpdateMutationVariables
    >(BroadcastSelectionUpdateDocument, options);
}
export type BroadcastSelectionUpdateMutationHookResult = ReturnType<
    typeof useBroadcastSelectionUpdateMutation
>;
export type BroadcastSelectionUpdateMutationResult =
    Apollo.MutationResult<IAPIBroadcastSelectionUpdateMutation>;
export type BroadcastSelectionUpdateMutationOptions = Apollo.BaseMutationOptions<
    IAPIBroadcastSelectionUpdateMutation,
    IAPIBroadcastSelectionUpdateMutationVariables
>;
export const RemoveMessageDocument = gql`
    mutation RemoveMessage($messageId: ID!) {
        removeMessage(messageId: $messageId) {
            clientId
            channelId
            steps
            message {
                ...MessageFields
            }
        }
    }
    ${MessageFieldsFragmentDoc}
`;
export type IAPIRemoveMessageMutationFn = Apollo.MutationFunction<
    IAPIRemoveMessageMutation,
    IAPIRemoveMessageMutationVariables
>;

/**
 * __useRemoveMessageMutation__
 *
 * To run a mutation, you first call `useRemoveMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMessageMutation, { data, loading, error }] = useRemoveMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useRemoveMessageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveMessageMutation,
        IAPIRemoveMessageMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveMessageMutation, IAPIRemoveMessageMutationVariables>(
        RemoveMessageDocument,
        options
    );
}
export type RemoveMessageMutationHookResult = ReturnType<typeof useRemoveMessageMutation>;
export type RemoveMessageMutationResult = Apollo.MutationResult<IAPIRemoveMessageMutation>;
export type RemoveMessageMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveMessageMutation,
    IAPIRemoveMessageMutationVariables
>;
export const WatchMessagesDocument = gql`
    mutation WatchMessages($channelId: ID!, $messageIds: [ID!]!) {
        watchMessages(channelId: $channelId, messageIds: $messageIds) {
            unreads
            hasMentions
        }
    }
`;
export type IAPIWatchMessagesMutationFn = Apollo.MutationFunction<
    IAPIWatchMessagesMutation,
    IAPIWatchMessagesMutationVariables
>;

/**
 * __useWatchMessagesMutation__
 *
 * To run a mutation, you first call `useWatchMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWatchMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [watchMessagesMutation, { data, loading, error }] = useWatchMessagesMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messageIds: // value for 'messageIds'
 *   },
 * });
 */
export function useWatchMessagesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIWatchMessagesMutation,
        IAPIWatchMessagesMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIWatchMessagesMutation, IAPIWatchMessagesMutationVariables>(
        WatchMessagesDocument,
        options
    );
}
export type WatchMessagesMutationHookResult = ReturnType<typeof useWatchMessagesMutation>;
export type WatchMessagesMutationResult = Apollo.MutationResult<IAPIWatchMessagesMutation>;
export type WatchMessagesMutationOptions = Apollo.BaseMutationOptions<
    IAPIWatchMessagesMutation,
    IAPIWatchMessagesMutationVariables
>;
export const MentionUserDocument = gql`
    mutation MentionUser($channelId: ID!, $messageId: ID!, $userId: ID!, $mentionGuid: String!) {
        mentionUser(
            channelId: $channelId
            messageId: $messageId
            userId: $userId
            mentionGuid: $mentionGuid
        )
    }
`;
export type IAPIMentionUserMutationFn = Apollo.MutationFunction<
    IAPIMentionUserMutation,
    IAPIMentionUserMutationVariables
>;

/**
 * __useMentionUserMutation__
 *
 * To run a mutation, you first call `useMentionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentionUserMutation, { data, loading, error }] = useMentionUserMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messageId: // value for 'messageId'
 *      userId: // value for 'userId'
 *      mentionGuid: // value for 'mentionGuid'
 *   },
 * });
 */
export function useMentionUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIMentionUserMutation,
        IAPIMentionUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIMentionUserMutation, IAPIMentionUserMutationVariables>(
        MentionUserDocument,
        options
    );
}
export type MentionUserMutationHookResult = ReturnType<typeof useMentionUserMutation>;
export type MentionUserMutationResult = Apollo.MutationResult<IAPIMentionUserMutation>;
export type MentionUserMutationOptions = Apollo.BaseMutationOptions<
    IAPIMentionUserMutation,
    IAPIMentionUserMutationVariables
>;
export const UnmentionUserDocument = gql`
    mutation UnmentionUser($channelId: ID!, $messageId: ID!, $mentionGuid: String!) {
        unmentionUser(channelId: $channelId, messageId: $messageId, mentionGuid: $mentionGuid)
    }
`;
export type IAPIUnmentionUserMutationFn = Apollo.MutationFunction<
    IAPIUnmentionUserMutation,
    IAPIUnmentionUserMutationVariables
>;

/**
 * __useUnmentionUserMutation__
 *
 * To run a mutation, you first call `useUnmentionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmentionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmentionUserMutation, { data, loading, error }] = useUnmentionUserMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messageId: // value for 'messageId'
 *      mentionGuid: // value for 'mentionGuid'
 *   },
 * });
 */
export function useUnmentionUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUnmentionUserMutation,
        IAPIUnmentionUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUnmentionUserMutation, IAPIUnmentionUserMutationVariables>(
        UnmentionUserDocument,
        options
    );
}
export type UnmentionUserMutationHookResult = ReturnType<typeof useUnmentionUserMutation>;
export type UnmentionUserMutationResult = Apollo.MutationResult<IAPIUnmentionUserMutation>;
export type UnmentionUserMutationOptions = Apollo.BaseMutationOptions<
    IAPIUnmentionUserMutation,
    IAPIUnmentionUserMutationVariables
>;
export const CreateReactionDocument = gql`
    mutation CreateReaction($messageId: ID!, $emojiId: ID!) {
        createReaction(messageId: $messageId, emojiId: $emojiId) {
            clientId
            channelId
            steps
            message {
                ...MessageFields
            }
        }
    }
    ${MessageFieldsFragmentDoc}
`;
export type IAPICreateReactionMutationFn = Apollo.MutationFunction<
    IAPICreateReactionMutation,
    IAPICreateReactionMutationVariables
>;

/**
 * __useCreateReactionMutation__
 *
 * To run a mutation, you first call `useCreateReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReactionMutation, { data, loading, error }] = useCreateReactionMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      emojiId: // value for 'emojiId'
 *   },
 * });
 */
export function useCreateReactionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateReactionMutation,
        IAPICreateReactionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateReactionMutation, IAPICreateReactionMutationVariables>(
        CreateReactionDocument,
        options
    );
}
export type CreateReactionMutationHookResult = ReturnType<typeof useCreateReactionMutation>;
export type CreateReactionMutationResult = Apollo.MutationResult<IAPICreateReactionMutation>;
export type CreateReactionMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateReactionMutation,
    IAPICreateReactionMutationVariables
>;
export const RemoveReactionDocument = gql`
    mutation RemoveReaction($messageId: ID!, $emojiId: ID!) {
        removeReaction(messageId: $messageId, emojiId: $emojiId) {
            clientId
            channelId
            steps
            message {
                ...MessageFields
            }
        }
    }
    ${MessageFieldsFragmentDoc}
`;
export type IAPIRemoveReactionMutationFn = Apollo.MutationFunction<
    IAPIRemoveReactionMutation,
    IAPIRemoveReactionMutationVariables
>;

/**
 * __useRemoveReactionMutation__
 *
 * To run a mutation, you first call `useRemoveReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReactionMutation, { data, loading, error }] = useRemoveReactionMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      emojiId: // value for 'emojiId'
 *   },
 * });
 */
export function useRemoveReactionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveReactionMutation,
        IAPIRemoveReactionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveReactionMutation, IAPIRemoveReactionMutationVariables>(
        RemoveReactionDocument,
        options
    );
}
export type RemoveReactionMutationHookResult = ReturnType<typeof useRemoveReactionMutation>;
export type RemoveReactionMutationResult = Apollo.MutationResult<IAPIRemoveReactionMutation>;
export type RemoveReactionMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveReactionMutation,
    IAPIRemoveReactionMutationVariables
>;
export const MessageUpdatedDocument = gql`
    subscription MessageUpdated($channelId: ID!) {
        messageUpdated(channelId: $channelId) {
            channelId
            clientId
            steps
            message {
                ...MessageFields
            }
        }
    }
    ${MessageFieldsFragmentDoc}
`;

/**
 * __useMessageUpdatedSubscription__
 *
 * To run a query within a React component, call `useMessageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageUpdatedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useMessageUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIMessageUpdatedSubscription,
        IAPIMessageUpdatedSubscriptionVariables
    > &
        ({ variables: IAPIMessageUpdatedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(MessageUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIMessageUpdatedSubscription,
            IAPIMessageUpdatedSubscriptionVariables
        >(MessageUpdatedDocument, options),
        connected,
    };
}
export type MessageUpdatedSubscriptionHookResult = ReturnType<typeof useMessageUpdatedSubscription>;
export type MessageUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIMessageUpdatedSubscription>;
export const SelectionUpdatedDocument = gql`
    subscription SelectionUpdated($channelId: ID!) {
        selectionUpdated(channelId: $channelId) {
            channelId
            clientId
            selection {
                ...SelectionFields
            }
        }
    }
    ${SelectionFieldsFragmentDoc}
`;

/**
 * __useSelectionUpdatedSubscription__
 *
 * To run a query within a React component, call `useSelectionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSelectionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectionUpdatedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useSelectionUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPISelectionUpdatedSubscription,
        IAPISelectionUpdatedSubscriptionVariables
    > &
        (
            | { variables: IAPISelectionUpdatedSubscriptionVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(SelectionUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPISelectionUpdatedSubscription,
            IAPISelectionUpdatedSubscriptionVariables
        >(SelectionUpdatedDocument, options),
        connected,
    };
}
export type SelectionUpdatedSubscriptionHookResult = ReturnType<
    typeof useSelectionUpdatedSubscription
>;
export type SelectionUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPISelectionUpdatedSubscription>;
export const MessageRemovedDocument = gql`
    subscription MessageRemoved($channelId: ID!) {
        messageRemoved(channelId: $channelId) {
            channelId
            clientId
            steps
            message {
                ...MessageFields
            }
        }
    }
    ${MessageFieldsFragmentDoc}
`;

/**
 * __useMessageRemovedSubscription__
 *
 * To run a query within a React component, call `useMessageRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageRemovedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useMessageRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIMessageRemovedSubscription,
        IAPIMessageRemovedSubscriptionVariables
    > &
        ({ variables: IAPIMessageRemovedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(MessageRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIMessageRemovedSubscription,
            IAPIMessageRemovedSubscriptionVariables
        >(MessageRemovedDocument, options),
        connected,
    };
}
export type MessageRemovedSubscriptionHookResult = ReturnType<typeof useMessageRemovedSubscription>;
export type MessageRemovedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIMessageRemovedSubscription>;
