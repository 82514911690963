export function insertIds(ids: number[], newIds: Array<string | number>) {
    const result = [...ids];

    for (let i = 0; i < newIds.length; i++) {
        const newId = Number(newIds[i]);

        let skip = false;
        let insertAtIndex = 0;
        for (let j = 0; j < result.length; j++) {
            if (result[j] < newId) {
                insertAtIndex++;
            } else {
                skip = result[j] === newId;
                break;
            }
        }

        if (!skip) {
            result.splice(insertAtIndex, 0, newId);
        }
    }

    return result;
}

export function arrayIntersect(arr1: string[], arr2: string[]): boolean;
export function arrayIntersect(arr1: number[], arr2: number[]): boolean;
export function arrayIntersect(arr1: any[], arr2: any[]) {
    for (const v of arr1) {
        if (!arr2.includes(v)) return false;
    }

    return true;
}

export function arrayExclude(arr1: string[], arr2: string[]): boolean;
export function arrayExclude(arr1: number[], arr2: number[]): boolean;
export function arrayExclude(arr1: any[], arr2: any[]) {
    for (const v of arr1) {
        if (arr2.includes(v)) return false;
    }

    return true;
}

export function arrayStartsWith(arr1: any[], arr2: any[]): boolean {
    return arr2.every((v, i) => arr1[i] === v);
}
