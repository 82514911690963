export interface IDropdownOption {
    label: React.ReactNode;
    onClick: () => void;
}

export function isDropdownOption(
    option: IDropdownOption | 'SEPARATOR' | React.ReactNode
): option is IDropdownOption {
    return Boolean(
        option &&
            typeof option === 'object' &&
            option.hasOwnProperty('label') &&
            option.hasOwnProperty('onClick')
    );
}

export interface IOption<VALUE = string> {
    label: React.ReactNode;
    value: VALUE;
}

export interface ISuggestionOption<VALUE> {
    suggestion: string;
    value: VALUE;
}

export interface ISuggestionRenderer {
    (
        suggestion: string,
        value: string,
        Replacement: (match: string) => React.ReactNode
    ): React.ReactNode[];
}

export interface ISelectOption<VALUE = string> extends IOption<VALUE> {
    disabled?: boolean;
}
