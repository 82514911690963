export type TPaddleAlertName =
    | 'subscription_created'
    | 'subscription_updated'
    | 'subscription_cancelled'
    | 'subscription_payment_succeeded'
    | 'subscription_payment_failed'
    | 'subscription_payment_refunded';

export type TPaddleSubscriptionStatus = 'active' | 'trialing' | 'past_due' | 'paused' | 'deleted';

export interface IPaddleAlert<NAME extends TPaddleAlertName> {
    alert_id: string;
    alert_name: NAME;
    checkout_id: string;
    currency: string;
    custom_data: string;
    email: string;
    event_time: string;
    marketing_consent: string; // 0 | 1
    next_bill_date: string;
    p_signature: string;
    passthrough: string;
    status: TPaddleSubscriptionStatus;
    subscription_id: string;
    subscription_plan_id: string;
    user_id: string;
}

export interface IPaddleSubscriptionCreated extends IPaddleAlert<'subscription_created'> {
    quantity: string;
    source: string;
    unit_price: string;
    cancel_url: string;
    update_url: string;
}

export interface IPaddleSubscriptionUpdated extends IPaddleAlert<'subscription_updated'> {
    new_price: string;
    new_quantity: string;
    new_unit_price: string;
    old_next_bill_date: string;
    old_price: string;
    old_quantity: string;
    old_status: TPaddleSubscriptionStatus;
    old_subscription_plan_id: string;
    old_unit_price: string;
    paused_at: string;
    paused_from: string;
    paused_reason: 'delinquent' | 'voluntary';
    cancel_url: string;
    update_url: string;
}

export interface IPaddleSubscriptionCancelled extends IPaddleAlert<'subscription_cancelled'> {
    cancellation_effective_date: string;
    quantity: string;
    unit_price: string;
    cancel_url: string;
    update_url: string;
}

export interface IPaddlePaymentSucceeded extends IPaddleAlert<'subscription_payment_succeeded'> {
    balance_currency: string;
    balance_earnings: string;
    balance_fee: string;
    balance_gross: string;
    balance_tax: string;
    country: string;
    coupon: string;
    customer_name: string;
    earnings: string;
    fee: string;
    initial_payment: string; // 0 | 1
    instalments: string;
    next_payment_amount: string;
    order_id: string;
    payment_method: 'card' | 'paypal';
    payment_tax: string;
    plan_name: string;
    quantity: string;
    receipt_url: string;
    sale_gross: string;
    subscription_payment_id: string;
    unit_price: string;
}

export interface IPaddlePaymentFailed extends IPaddleAlert<'subscription_payment_failed'> {
    amount: string;
    attempt_number: string;
    instalments: string;
    next_retry_date: string;
    order_id: string;
    quantity: string;
    subscription_payment_id: string;
    unit_price: string;
    cancel_url: string;
    update_url: string;
}

export interface IPaddlePaymentRefunded extends IPaddleAlert<'subscription_payment_refunded'> {
    amount: string;
    balance_currency: string;
    balance_earnings_decrease: string;
    balance_fee_refund: string;
    balance_gross_refund: string;
    balance_tax_refund: string;
    earnings_decrease: string;
    fee_refund: string;
    gross_refund: string;
    initial_payment: string;
    instalments: string;
    order_id: string;
    refund_reason: string;
    refund_type: 'full' | 'vat' | 'partial';
    subscription_payment_id: string;
    tax_refund: string;
    unit_price: string;
}

export type TPaddleEvent =
    | IPaddleSubscriptionCreated
    | IPaddleSubscriptionUpdated
    | IPaddleSubscriptionCancelled
    | IPaddlePaymentSucceeded
    | IPaddlePaymentFailed
    | IPaddlePaymentRefunded;

export interface IPaddleAPIResponse<DATA> {
    success: boolean;
    response: DATA;
    error: {
        code: number;
        message: string;
    };
}

export type TPaddleAPIListUsersResponse = IPaddleAPIResponse<IPaddleListUsersResponseData[]>;

interface IPaddleListUsersResponseData {
    subscription_id: number;
    plan_id: number;
    user_id: number;
    user_email: string;
    marketing_consent: boolean;
    state: TPaddleSubscriptionStatus;
    signup_date: string;
    last_payment: {
        amount: number;
        currency: string;
        date: string;
    };
    next_payment: {
        amount: number;
        currency: string;
        date: string;
    };
    update_url: string;
    cancel_url: string;
    paused_at: string;
    paused_from: string;
    payment_information: {
        payment_method: string;
        card_type:
            | 'master'
            | 'visa'
            | 'american_express'
            | 'discover'
            | 'jcb'
            | 'maestro'
            | 'diners_club'
            | 'unionpay';
        last_four_digits: string;
        expiry_date: string;
    };
}

export type TPaddleAPIListModifiersResponse = IPaddleAPIResponse<
    IPaddleListModifiersResponseData[]
>;

interface IPaddleListModifiersResponseData {
    modifier_id: number;
    subscription_id: number;
    amount: string;
    currency: 'USD';
    is_recurring: boolean;
    description: string;
}

export type TPaddleAPICreateModifierResponse =
    IPaddleAPIResponse<IPaddleCreateModifierResponseData>;

interface IPaddleCreateModifierResponseData {
    modifier_id: number;
    subscription_id: number;
}

export interface IPaddleModifier {
    total: string;
    description: string;
}

// Type guards
export function isPaddleSubscriptionEvent(
    payload: TPaddleEvent
): payload is
    | IPaddleSubscriptionCreated
    | IPaddleSubscriptionUpdated
    | IPaddleSubscriptionCancelled {
    return ['subscription_created', 'subscription_updated', 'subscription_cancelled'].includes(
        payload.alert_name
    );
}

export function isPaddlePaymentSucceededEvent(
    payload: TPaddleEvent
): payload is IPaddlePaymentSucceeded {
    return payload.alert_name === 'subscription_payment_succeeded';
}
