/* eslint-disable */
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { RoleFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchRolesQueryVariables = Types.Exact<{
    filter: Types.IAPIRolesFilter;
}>;

export type IAPIFetchRolesQuery = {
    __typename: 'Query';
    roles: Array<{ __typename: 'Role'; id: string; name: Types.UserRole; policy: string }>;
};

export const FetchRolesDocument = gql`
    query FetchRoles($filter: RolesFilter!) {
        roles(filter: $filter) {
            ...RoleFields
        }
    }
    ${RoleFieldsFragmentDoc}
`;

/**
 * __useFetchRolesQuery__
 *
 * To run a query within a React component, call `useFetchRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchRolesQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchRolesQuery, IAPIFetchRolesQueryVariables> &
        ({ variables: IAPIFetchRolesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchRolesDocument, options);
    return Apollo.useQuery<IAPIFetchRolesQuery, IAPIFetchRolesQueryVariables>(
        FetchRolesDocument,
        options
    );
}
export function useFetchRolesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchRolesQuery, IAPIFetchRolesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchRolesQuery, IAPIFetchRolesQueryVariables>(
        FetchRolesDocument,
        options
    );
}
export function useFetchRolesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchRolesQuery, IAPIFetchRolesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchRolesQuery, IAPIFetchRolesQueryVariables>(
        FetchRolesDocument,
        options
    );
}
export type FetchRolesQueryHookResult = ReturnType<typeof useFetchRolesQuery>;
export type FetchRolesLazyQueryHookResult = ReturnType<typeof useFetchRolesLazyQuery>;
export type FetchRolesSuspenseQueryHookResult = ReturnType<typeof useFetchRolesSuspenseQuery>;
export type FetchRolesQueryResult = Apollo.QueryResult<
    IAPIFetchRolesQuery,
    IAPIFetchRolesQueryVariables
>;
