import './main.scss';
import { runEditor } from './editing';
import { Menu } from './menu';
import { runTariffs } from './tariffs';
import './registration';
import { runCaptureEmail } from './capture_email';

window.addEventListener('DOMContentLoaded', event => {
    try {
        new Menu('#menu', '#menu_button', '#menu .Links li a');
    } catch (error) {
        console.error('Error initializing menu: ' + error);
    }

    try {
        runEditor();
    } catch (error) {
        // It's alright if we are not on index.html
        // TODO: configure webpack to use Editor only on index.html
    }

    try {
        runTariffs();
    } catch (error) {
        console.error('Error initializing tariffs: ' + error);
    }

    try {
        runCaptureEmail();
    } catch (error) {
        console.error('Error initializing capture email: ' + error);
    }
});
