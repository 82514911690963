import {
    ETables,
    IAclEntities,
    isAclEntities,
    IAPIAclEntities,
    TAcl,
    IAPITaskAcl,
    isAPIAclEntities,
    Maybe,
    IAPITaskAclBroadcast,
    IAPIAclEntitiesBroadcast,
} from '@ab-task/types';
import { GUID2ID, ID2GUID } from './core';

// JS -> API
export function taskAclJS2API(taskAcl?: Maybe<TAcl<'task'>>): IAPITaskAcl {
    const { exclusiveAccess = false, ...rules } = taskAcl || {};

    return Object.entries(rules).reduce(
        (accumulator, [key, entities]) => {
            if (isAclEntities(entities)) {
                accumulator = Object.assign(accumulator, {
                    [key]: authorizedEntitiesJS2API(entities),
                });
            }

            return accumulator;
        },
        { __typename: 'TaskAcl', exclusiveAccess }
    );
}

export function authorizedEntitiesJS2API(entities: IAclEntities): IAPIAclEntities {
    return {
        __typename: 'AclEntities',
        roleIdsCsv: entities.roleIds?.map(id => ID2GUID(ETables.roles, id)).join(','),
        userIdsCsv: entities.userIds?.map(id => ID2GUID(ETables.users, id)).join(','),
        groupIdsCsv: entities.groupIds?.map(id => ID2GUID(ETables.groups, id)).join(','),
    };
}

// API -> JS
export function taskAclAPI2JS(apiTaskAcl?: Maybe<IAPITaskAcl>): TAcl<'task'> {
    const { exclusiveAccess = false, ...rules } = apiTaskAcl || {};

    return Object.entries(rules).reduce(
        (accumulator, [key, entities]) => {
            if (isAPIAclEntities(entities)) {
                accumulator = Object.assign(accumulator, {
                    [key]: authorizedEntitiesAPI2JS(entities),
                });
            }

            return accumulator;
        },
        { exclusiveAccess: exclusiveAccess || undefined }
    );
}

export function authorizedEntitiesAPI2JS(apiEntities: IAPIAclEntities): IAclEntities {
    return {
        roleIds: apiEntities.roleIdsCsv?.split(',').map(guid => GUID2ID(guid)[1]),
        userIds: apiEntities.userIdsCsv?.split(',').map(guid => GUID2ID(guid)[1]),
        groupIds: apiEntities.groupIdsCsv?.split(',').map(guid => GUID2ID(guid)[1]),
    };
}

// API -> Broadcast
export function taskAclAPI2Broadcast(apiTaskAcl?: Maybe<IAPITaskAcl>): IAPITaskAclBroadcast {
    const { exclusiveAccess = false, ...rules } = apiTaskAcl || {};

    return Object.entries(rules).reduce(
        (accumulator, [key, entities]) => {
            if (isAPIAclEntities(entities)) {
                accumulator = Object.assign(accumulator, {
                    [key]: authorizedEntitiesAPI2Broadcast(entities),
                });
            }

            return accumulator;
        },
        { exclusiveAccess }
    );
}

export function authorizedEntitiesAPI2Broadcast(
    entities: IAPIAclEntities
): IAPIAclEntitiesBroadcast {
    return {
        roleIdsCsv: entities.roleIdsCsv,
        userIdsCsv: entities.userIdsCsv,
        groupIdsCsv: entities.groupIdsCsv,
    };
}
