import {
    IAPIDateFilter,
    IAPIIdFilter,
    IAPIIDsFilter, // TODO: why this D is capital?
    IAPINumberFilter,
    IAPIStringFilter,
    ISuggestionOption,
    Joiner,
    Maybe,
} from '.';

export type TFilterKey<FILTER extends TAPIFilter> = keyof Omit<FILTER, 'joiner'>;

export type TFilterTuple<
    KEY extends string = string,
    CONDITION extends TCondition = TCondition,
    VALUE = boolean | string | number,
> = [
    ISuggestionOption<KEY> | undefined,
    ISuggestionOption<CONDITION> | undefined,
    ISuggestionOption<VALUE> | undefined,
];

export type TCondition =
    | keyof IAPIIdFilter
    | keyof IAPIIDsFilter
    | keyof IAPIStringFilter
    | keyof IAPINumberFilter;

export type TAPIValueFilter =
    | IAPIIdFilter
    | IAPIIDsFilter
    | IAPIStringFilter
    | IAPINumberFilter
    | IAPIDateFilter;

export type TAPIFilter = Partial<
    { joiner: Maybe<Joiner> } & Record<string, Maybe<TAPIValueFilter> | Joiner | undefined>
>;

export function isAPIFilter(payload: any): payload is TAPIValueFilter {
    return (
        typeof payload === 'object' &&
        payload !== null &&
        (payload.hasOwnProperty('equal') ||
            payload.hasOwnProperty('notEqual') ||
            payload.hasOwnProperty('include') ||
            payload.hasOwnProperty('exclude') ||
            payload.hasOwnProperty('includes') ||
            payload.hasOwnProperty('excludes') ||
            payload.hasOwnProperty('startsWith') ||
            payload.hasOwnProperty('empty') ||
            payload.hasOwnProperty('lessThan') ||
            payload.hasOwnProperty('moreThan') ||
            payload.hasOwnProperty('matchSet') ||
            payload.hasOwnProperty('like') ||
            payload.hasOwnProperty('isNull'))
    );
}
