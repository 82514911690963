import { ETables, IDBUnread, IAPIUnread, IUnread } from '@ab-task/types';
import { ID2GUID, GUID2ID } from './core';

export function unreadDB2JS(dbUnread: IDBUnread): IUnread {
    return {
        messageId: dbUnread.ur_message_id,
        messageCreatedAt: dbUnread.ur_message_created_at,
        messageUpdatedAt: dbUnread.ur_message_updated_at,
        wasMentionedSinceWatched: dbUnread.ur_was_mentioned_since_watched,
    };
}

export function unreadDB2API(dbUnread: IDBUnread): IAPIUnread {
    return {
        __typename: 'Unread',
        // We don't have id in DB so we can use message id as unread id
        id: ID2GUID(ETables.messages, dbUnread.ur_message_id),
        messageId: ID2GUID(ETables.messages, dbUnread.ur_message_id),
        messageCreatedAt: dbUnread.ur_message_created_at.toISOString(),
        messageUpdatedAt: dbUnread.ur_message_updated_at.toISOString(),
        wasMentionedSinceWatched: dbUnread.ur_was_mentioned_since_watched,
    };
}

export function unreadAPI2JS(apiUnread: IAPIUnread): IUnread {
    return {
        messageId: GUID2ID(apiUnread.messageId)[1],
        messageCreatedAt: new Date(apiUnread.messageCreatedAt),
        messageUpdatedAt: new Date(apiUnread.messageUpdatedAt),
        wasMentionedSinceWatched: apiUnread.wasMentionedSinceWatched,
    };
}
