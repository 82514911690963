import { FrontendError } from '@ab-task/errors';
import { rvCurrentWorkspaceId } from '../variables';

export function ensureAndGetCurrentWorkspaceId(): number {
    const currentWorkspaceId = rvCurrentWorkspaceId();

    if (typeof currentWorkspaceId !== 'number') {
        throw FrontendError.GENERAL('No current workspace ID set. Cannot update context.');
    }

    return currentWorkspaceId;
}
