/* eslint-disable */
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIUpdateAvatarMutationVariables = Types.Exact<{
    s3Key: Types.Scalars['String']['input'];
    contentType: Types.Scalars['String']['input'];
}>;

export type IAPIUpdateAvatarMutation = {
    __typename: 'Mutation';
    updateAvatar: { __typename: 'UpdateAvatarResult'; uploadURL: string };
};

export type IAPIRemoveAvatarMutationVariables = Types.Exact<{ [key: string]: never }>;

export type IAPIRemoveAvatarMutation = {
    __typename: 'Mutation';
    removeAvatar: { __typename: 'RemoveAvatarResult'; s3Key?: string | null };
};

export const UpdateAvatarDocument = gql`
    mutation UpdateAvatar($s3Key: String!, $contentType: String!) {
        updateAvatar(s3Key: $s3Key, contentType: $contentType) {
            uploadURL
        }
    }
`;
export type IAPIUpdateAvatarMutationFn = Apollo.MutationFunction<
    IAPIUpdateAvatarMutation,
    IAPIUpdateAvatarMutationVariables
>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      s3Key: // value for 's3Key'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useUpdateAvatarMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateAvatarMutation,
        IAPIUpdateAvatarMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateAvatarMutation, IAPIUpdateAvatarMutationVariables>(
        UpdateAvatarDocument,
        options
    );
}
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<IAPIUpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateAvatarMutation,
    IAPIUpdateAvatarMutationVariables
>;
export const RemoveAvatarDocument = gql`
    mutation RemoveAvatar {
        removeAvatar {
            s3Key
        }
    }
`;
export type IAPIRemoveAvatarMutationFn = Apollo.MutationFunction<
    IAPIRemoveAvatarMutation,
    IAPIRemoveAvatarMutationVariables
>;

/**
 * __useRemoveAvatarMutation__
 *
 * To run a mutation, you first call `useRemoveAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAvatarMutation, { data, loading, error }] = useRemoveAvatarMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAvatarMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveAvatarMutation,
        IAPIRemoveAvatarMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveAvatarMutation, IAPIRemoveAvatarMutationVariables>(
        RemoveAvatarDocument,
        options
    );
}
export type RemoveAvatarMutationHookResult = ReturnType<typeof useRemoveAvatarMutation>;
export type RemoveAvatarMutationResult = Apollo.MutationResult<IAPIRemoveAvatarMutation>;
export type RemoveAvatarMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveAvatarMutation,
    IAPIRemoveAvatarMutationVariables
>;
