import { ETables, IDBTransaction, ITransaction, IAPITransaction } from '@ab-task/types';
import { ID2GUID, GUID2ID } from './core';

// DB -> JS
export function transactionDB2JS(dbTransaction: IDBTransaction): ITransaction {
    return {
        id: dbTransaction.trn_id,
        userId: dbTransaction.trn_user_id,
        method: dbTransaction.trn_method,
        receiptUrl: dbTransaction.trn_receipt_url,
        total: dbTransaction.trn_total,
        processedAt: dbTransaction.trn_processed_at,
    };
}

// DB -> API
export function transactionDB2API(dbTransaction: IDBTransaction): IAPITransaction {
    return {
        __typename: 'Transaction',
        id: ID2GUID(ETables.transactions, dbTransaction.trn_id),
        userId: ID2GUID(ETables.transactions, dbTransaction.trn_user_id),
        method: dbTransaction.trn_method,
        receiptUrl: dbTransaction.trn_receipt_url,
        total: dbTransaction.trn_total,
        processedAt: dbTransaction.trn_processed_at.toISOString(),
    };
}

// API -> JS
export function transactionAPI2JS(apiTransaction: IAPITransaction): ITransaction {
    return {
        id: GUID2ID(apiTransaction.id)[1],
        userId: GUID2ID(apiTransaction.userId)[1],
        method: apiTransaction.method,
        receiptUrl: apiTransaction.receiptUrl,
        total: apiTransaction.total,
        processedAt: new Date(apiTransaction.processedAt),
    };
}
