import { Maybe, SortDirection } from '..';

export interface ITableCol<T extends Record<string, any> = Record<string, any>> {
    title?: string;
    colName: keyof T;
    sortColName?: string;
    disabled?: boolean;
    align?: 'left' | 'center' | 'right';
    style?: { [key: string]: string };
    cellFormatterByRowFormatter?: { [key: string]: string };
    cellFormatter?: string;
    colGlobals?: Record<string, any>;
    handlers?: { [key: string]: Function };
}

export interface ITableRowProps {
    rowIndex: number;
    isSelected?: boolean;
}

export interface ITableCellProps extends ITableRowProps {
    colKey: string;
}

export type TSelection = Record<string, Maybe<boolean>>;
export type TSort = Record<string, Maybe<SortDirection>>;
