import { ETables, IAPIRole, IDBRole, IRole, TAvailableRoles } from '@ab-task/types';
import { GUID2ID, ID2GUID } from './core';

export function roleDB2JS(dbRole: IDBRole): IRole {
    return {
        id: dbRole.r_id,
        name: dbRole.r_name,
        policy: dbRole.r_policy,
    };
}

export function roleDB2API(dbRole: IDBRole): IAPIRole {
    return {
        __typename: 'Role',
        id: ID2GUID(ETables.roles, dbRole.r_id),
        name: dbRole.r_name,
        policy: JSON.stringify(dbRole.r_policy),
    };
}

export function roleAPI2JS(apiRole: IAPIRole): IRole {
    return {
        id: GUID2ID(apiRole.id)[1],
        name: apiRole.name,
        policy: JSON.parse(apiRole.policy),
    };
}

export function rolesARR2MAP(roles: IRole[]) {
    return roles.reduce<Partial<TAvailableRoles>>((accumulator, role) => {
        accumulator[role.name] = role;
        return accumulator;
    }, {}) as TAvailableRoles;
}
