// import cleanStack from 'clean-stack';

export function prettifyStack(stack: string, relative?: boolean) {
    stack = stack.replace(/\/.*webpack:\//g, ' ');
    if (relative) {
        // FIXME
        // stack = cleanStack(stack, {
        //            basePath: process.cwd() + '/',
        //        });
    }
    return stack;
}

export function prettifyError(err: Error) {
    return {
        ...err,
        stack: err.stack === undefined ? undefined : prettifyStack(err.stack, true),
    };
}

// TODO: choose better name
export function prettifyStackDecorator(target: any, name: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;
    descriptor.value = function () {
        const error = method(...arguments);
        return prettifyError(error);
    };
}
