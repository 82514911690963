export function snakeToUpperCamel(value: string) {
    return firstLetterToUpperCase(underscoresToCamelCase(value.toLowerCase()));
}

export function firstLetterToUpperCase(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function underscoresToCamelCase(value: string) {
    return value.replace(/_([a-z])/gi, g => g[1].toUpperCase());
}

export function camelCaseToUnderscores(value: string) {
    return value.replace(/([a-z0-9][A-Z])/g, g => g[0] + '_' + g[1].toLowerCase());
}

export function getPlaceholdersFromStr(str: string, regexp: RegExp) {
    const results: string[] = [];

    let result = regexp.exec(str);
    while (result) {
        results.push(result[1]);
        result = regexp.exec(str);
    }

    return results;
}

export function base64(str: string): string {
    return Buffer.from(str, 'utf8').toString('base64');
}

export function unbase64(str: string): string {
    return Buffer.from(str, 'base64').toString('utf8');
}

export function base64EncodedJson(value: Record<string, any>) {
    return btoa(JSON.stringify(value));
}

export function prependSql(statements: string | null | undefined, clause: string) {
    return statements && statements.length > 0 ? ` ${statements} ${clause} ` : '';
}

export function appendSql(clause: string, statements?: string | null) {
    return statements && statements.length > 0 ? ` ${clause} ${statements}` : '';
}

export function regEscape(v: string) {
    return v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
