import { IDBInvite, IInvite } from '@ab-task/types';
import { normalize } from '.';

export function inviteDB2JS(dbInvite: IDBInvite): IInvite {
    return {
        id: dbInvite.i_id,
        code: dbInvite.i_code,
        userId: dbInvite.i_user_id,
        inviterId: normalize(dbInvite.i_inviter_id),
        workspaceId: normalize(dbInvite.i_workspace_id),
        accessList:
            dbInvite.i_access_list?.map(({ projectId, roleId }) => ({
                projectId: normalize(projectId),
                roleId,
            })) || undefined,
        validUntil: dbInvite.i_valid_until,
        claimedAt: normalize(dbInvite.i_claimed_at),
    };
}
