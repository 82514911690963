import { ETables, IDashboard, IAPIDashboard, IDBDashboard } from '@ab-task/types';
import { normalize, ID2GUID, GUID2ID } from './core';
import { t } from '@ab-task/internationalization';

export function dashboardDB2JS(dbDashboard: IDBDashboard): IDashboard {
    const configStr = normalize(dbDashboard.dsh_config);

    return {
        id: dbDashboard.dsh_id,
        workspaceId: dbDashboard.dsh_workspace_id,
        ownerId: dbDashboard.dsh_owner_id,
        type: dbDashboard.dsh_type,
        name: normalize(dbDashboard.dsh_name),
        config: typeof configStr === 'string' ? JSON.parse(configStr) : undefined,
        favorite: dbDashboard.dsh_favorite,
        order: dbDashboard.dsh_order,
        unreads: dbDashboard.dsh_unreads || 0,
        hasMentions: dbDashboard.dsh_has_mentions === true,
    };
}

export function dashboardDB2API(dbDashboard: IDBDashboard): IAPIDashboard {
    return {
        __typename: 'Dashboard',
        id: ID2GUID(ETables.dashboards, dbDashboard.dsh_id),
        workspaceId: ID2GUID(ETables.workspaces, dbDashboard.dsh_workspace_id),
        ownerId: ID2GUID(ETables.users, dbDashboard.dsh_owner_id),
        name: dbDashboard.dsh_name,
        type: dbDashboard.dsh_type,
        config: dbDashboard.dsh_config,
        favorite: dbDashboard.dsh_favorite,
        order: dbDashboard.dsh_order,
        unreads: dbDashboard.dsh_unreads || 0,
        hasMentions: dbDashboard.dsh_has_mentions === true,
    };
}

export function dashboardAPI2JS(apiDashboard: IAPIDashboard): IDashboard {
    const configStr = normalize(apiDashboard.config);

    return {
        id: GUID2ID(apiDashboard.id)[1],
        key: normalize(apiDashboard.key),
        workspaceId: GUID2ID(apiDashboard.workspaceId)[1],
        ownerId: GUID2ID(apiDashboard.ownerId)[1],
        name: normalize(apiDashboard.name),
        type: apiDashboard.type,
        config: typeof configStr === 'string' ? JSON.parse(configStr) : undefined,
        favorite: normalize(apiDashboard.favorite),
        order: apiDashboard.order,
        unreads: apiDashboard.unreads || 0,
        hasMentions: apiDashboard.hasMentions,
        expanded: normalize(apiDashboard.expanded),
    };
}

export function getDashboardName(dashboard?: IDashboard) {
    return dashboard?.name || t('Untitled Dashboard');
}
