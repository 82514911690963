import {
    ETables,
    IDBMessage,
    IMessage,
    IAPIMessage,
    TDBID,
    TMarkName,
    TNodeName,
    IAPIMessageUpdateBroadcast,
} from '@ab-task/types';
import { normalize, ID2GUID, GUID2ID } from './core';
import { CoreError } from '@ab-task/errors';
import { Node, Schema } from 'prosemirror-model';

export function messageDB2JS(dbMessage: IDBMessage): IMessage {
    return {
        id: dbMessage.m_id,
        content: normalize(dbMessage.m_content),
        version: dbMessage.m_version,
        isFirst: dbMessage.m_first,
        isLast: dbMessage.m_first,
        creatorId: dbMessage.m_created_by,
        authorIds: dbMessage.m_author_ids.split(',').map(idStr => Number(idStr)),
        createdAt: dbMessage.m_created_at,
        updatedAt: dbMessage.m_updated_at,
        workspaceId: normalize(dbMessage.m_workspace_id),
        groupId: normalize(dbMessage.m_group_id),
        topicId: normalize(dbMessage.m_topic_id),
        documentId: normalize(dbMessage.m_document_id),
        projectId: normalize(dbMessage.m_project_id),
        milestoneId: normalize(dbMessage.m_milestone_id),
        epicId: normalize(dbMessage.m_epic_id),
        taskId: normalize(dbMessage.m_task_id),
        watches: dbMessage.m_watches
            ? dbMessage.m_watches.map(([watchedAt, userId]) => ({
                  watchedAt: new Date(watchedAt),
                  userId,
              }))
            : [],
        reactions: dbMessage.m_reactions
            ? dbMessage.m_reactions.map(([reactedAt, emojiId, userId]) => ({
                  userId,
                  emojiId,
                  reactedAt: new Date(reactedAt),
              }))
            : [],
    };
}

export function messageDB2API(dbMessage: IDBMessage): IAPIMessage {
    return {
        __typename: 'Message',
        id: ID2GUID(ETables.messages, dbMessage.m_id),
        creatorId: ID2GUID(ETables.users, dbMessage.m_created_by),
        authorIds: dbMessage.m_author_ids.split(',').map(id => ID2GUID(ETables.users, Number(id))),
        content: dbMessage.m_content,
        version: dbMessage.m_version,
        isFirst: dbMessage.m_first,
        isLast: dbMessage.m_last,
        createdAt: dbMessage.m_created_at.toISOString(),
        updatedAt: dbMessage.m_updated_at.toISOString(),
        watches: dbMessage.m_watches
            ? dbMessage.m_watches.map(([watchedAt, userId]) => ({
                  __typename: 'MessageWatch',
                  userId: ID2GUID('users', userId),
                  watchedAt: new Date(watchedAt).toISOString(),
              }))
            : [],
        reactions: dbMessage.m_reactions
            ? dbMessage.m_reactions.map(([reactedAt, emojiId, userId]) => ({
                  __typename: 'MessageReaction',
                  userId: ID2GUID('users', userId),
                  emojiId: ID2GUID('emojis', emojiId),
                  reactedAt: new Date(reactedAt).toISOString(),
              }))
            : [],
    };
}

export function messageAPI2Broadcast(apiMessage: IAPIMessage): IAPIMessageUpdateBroadcast {
    const { __typename, ...rest } = apiMessage;

    return {
        ...rest,
        watches: apiMessage.watches
            ? apiMessage.watches.map(apiWatch => {
                  const { __typename, ...rest } = apiWatch;
                  return rest;
              })
            : apiMessage.watches,
        reactions: apiMessage.reactions
            ? apiMessage.reactions.map(apiReaction => {
                  const { __typename, ...rest } = apiReaction;
                  return rest;
              })
            : apiMessage.reactions,
    };
}

export function messageAPI2JS(apiMessage: IAPIMessage): IMessage {
    return {
        id: GUID2ID(apiMessage.id)[1],
        content: normalize(apiMessage.content),
        version: apiMessage.version,
        isFirst: apiMessage.isFirst,
        isLast: apiMessage.isLast,
        creatorId: GUID2ID(apiMessage.creatorId)[1],
        authorIds: apiMessage.authorIds.map(guid => GUID2ID(guid)[1]),
        createdAt: new Date(apiMessage.createdAt),
        updatedAt: new Date(apiMessage.updatedAt),
        watches: apiMessage.watches.map(apiWatch => ({
            userId: GUID2ID(apiWatch.userId)[1],
            watchedAt: new Date(apiWatch.watchedAt),
        })),
        reactions: apiMessage.reactions.map(apiReaction => ({
            userId: GUID2ID(apiReaction.userId)[1],
            emojiId: GUID2ID(apiReaction.emojiId)[1],
            reactedAt: new Date(apiReaction.reactedAt),
        })),
        isBig: normalize(apiMessage.isBig),
        isReadonly: normalize(apiMessage.isReadonly),
        attachmentS3Keys: normalize(apiMessage.attachmentS3Keys),
    };
}

export const message2ChannelGuid = (message: IMessage) => {
    const { workspaceId, epicId, groupId, projectId, taskId, topicId, documentId } = message;

    if (workspaceId) {
        return ID2GUID('workspaces', workspaceId);
    }
    if (epicId) {
        return ID2GUID('epics', epicId);
    }
    if (groupId) {
        return ID2GUID('groups', groupId);
    }
    if (projectId) {
        return ID2GUID('projects', projectId);
    }
    if (taskId) {
        return ID2GUID('tasks', taskId);
    }
    if (topicId) {
        return ID2GUID('topics', topicId);
    }
    if (documentId) {
        return ID2GUID('documents', documentId);
    }

    throw CoreError.TYPE_ADAPTOR_FAILED({ info: "ChannelId wasn't found in message" });
};

export default function message2ChannelId(message: IMessage): TDBID {
    if (message.workspaceId) {
        return [ETables.workspaces, message.workspaceId];
    }

    if (message.groupId) {
        return [ETables.groups, message.groupId];
    }

    if (message.topicId) {
        return [ETables.topics, message.topicId];
    }

    if (message.documentId) {
        return [ETables.topics, message.documentId];
    }

    if (message.projectId) {
        return [ETables.projects, message.projectId];
    }

    if (message.milestoneId) {
        return [ETables.milestones, message.milestoneId];
    }

    if (message.epicId) {
        return [ETables.epics, message.epicId];
    }

    if (message.taskId) {
        return [ETables.tasks, message.taskId];
    }

    throw CoreError.TYPE_ADAPTOR_FAILED({ info: "ChannelId wasn't found in message" });
}

export function messageContent2Doc(schema: Schema<TNodeName, TMarkName>, content?: string) {
    let doc: Node | undefined;
    if (typeof content === 'string') {
        try {
            const json = JSON.parse(content);
            doc = Node.fromJSON(schema, json);
        } catch (error: any) {
            console.error(error);
            doc = schema.node('doc', undefined, [
                schema.node('paragraph', undefined, [
                    schema.text('Failed to parse message content'),
                ]),
            ]);
        }
    }

    return doc;
}
