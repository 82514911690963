import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { ApolloLink, createHttpLink } from '@apollo/client';
import type { TApolloClientConfig } from '../types';
import { fromEnv } from '@aws-sdk/credential-provider-env';
import type { AwsCredentialIdentity, AwsCredentialIdentityProvider } from '@aws-sdk/types';
import { CoreError } from '@ab-task/errors';
import { createSubscriptionHandshakeLink } from './aws-appsync-subscription-link';
import { MS_PER_MINUTE } from '@ab-task/data';
import crossFetch from 'cross-fetch';

const IDENTITY_LIFESPAN_MS = MS_PER_MINUTE * 2.5;

const awsIdentity: { credsPromise?: Promise<AwsCredentialIdentity>; birthDate?: number } = {};

export function getAppSyncLink(config: TApolloClientConfig) {
    const { appSyncEndpoint, region, clientId } = config;

    let getCredentials: AwsCredentialIdentityProvider;
    let httpLink: ApolloLink;
    switch (config.environment) {
        case 'node': {
            getCredentials = fromEnv();
            httpLink = createHttpLink({ uri: appSyncEndpoint, fetch: crossFetch });
            break;
        }

        case 'browser': {
            const cognitoIdentityClient = new CognitoIdentityClient({
                region: config.region,
            });

            getCredentials = fromCognitoIdentityPool({
                client: cognitoIdentityClient,
                identityPoolId: config.identityPoolId,
                userIdentifier: clientId,
            });

            httpLink = createHttpLink({ uri: appSyncEndpoint, fetch });
            break;
        }

        default: {
            throw CoreError.CONFIGURATION_ERROR({
                info: `Unknown environment in apollo client config`,
            });
        }
    }

    const auth: AuthOptions = {
        type: 'AWS_IAM',
        credentials: async () => {
            if (
                !awsIdentity.credsPromise ||
                !awsIdentity.birthDate ||
                Date.now() - awsIdentity.birthDate > IDENTITY_LIFESPAN_MS
            ) {
                awsIdentity.birthDate = Date.now();
                awsIdentity.credsPromise = getCredentials();
            }

            const creds = await awsIdentity.credsPromise;

            return creds;
        },
    };

    return ApolloLink.from([
        createAuthLink({ url: appSyncEndpoint, region, auth }),
        createSubscriptionHandshakeLink({ url: appSyncEndpoint, region, auth }, httpLink),
    ]);
}
