import { TNodes } from '@ab-task/types';
import { NodeSpec } from 'prosemirror-model';

export const nodes: TNodes = {
    doc: {
        content: 'block+',
    },

    paragraph: {
        content: 'inline*',
        group: 'block',
        parseDOM: [{ tag: 'p' }],
        toDOM() {
            return ['p', 0];
        },
    },

    blockquote: {
        content: 'block+',
        group: 'block',
        defining: true,
        parseDOM: [{ tag: 'blockquote' }],
        toDOM() {
            return ['blockquote', 0];
        },
    },

    horizontal_rule: {
        group: 'block',
        parseDOM: [{ tag: 'hr' }],
        toDOM() {
            return ['hr'];
        },
    },

    ordered_list: {
        content: 'list_item+',
        group: 'block',
        attrs: { order: { default: 1 } },
        parseDOM: [
            {
                tag: 'ol',
                getAttrs(dom: HTMLElement) {
                    return { order: dom.hasAttribute('start') ? +dom.getAttribute('start')! : 1 };
                },
            },
        ],
        toDOM(node) {
            return node.attrs.order === 1 ? ['ol', 0] : ['ol', { start: node.attrs.order }, 0];
        },
    } as NodeSpec,

    bullet_list: {
        content: 'list_item+',
        group: 'block',
        parseDOM: [{ tag: 'ul' }],
        toDOM() {
            return ['ul', 0];
        },
    },

    list_item: {
        content: 'paragraph block*',
        parseDOM: [{ tag: 'li' }],
        toDOM() {
            return ['li', 0];
        },
        defining: true,
    },

    heading: {
        attrs: { level: { default: 1 } },
        content: 'inline*',
        group: 'block',
        defining: true,
        parseDOM: [
            { tag: 'h1', attrs: { level: 1 } },
            { tag: 'h2', attrs: { level: 2 } },
            { tag: 'h3', attrs: { level: 3 } },
            { tag: 'h4', attrs: { level: 4 } },
            { tag: 'h5', attrs: { level: 5 } },
            { tag: 'h6', attrs: { level: 6 } },
        ],
        toDOM(node) {
            return ['h' + node.attrs.level, 0];
        },
    },

    code_block: {
        content: 'text*',
        marks: '',
        group: 'block',
        code: true,
        defining: true,
        parseDOM: [{ tag: 'pre', preserveWhitespace: 'full' }],
        toDOM() {
            return ['pre', ['code', 0]];
        },
    },

    text: {
        group: 'inline',
    },

    // TODO: we have 'image' and 'attached_image'.
    image: {
        inline: true,
        attrs: {
            src: {},
            alt: { default: null },
            title: { default: null },
        },
        group: 'inline',
        draggable: true,
        parseDOM: [
            {
                tag: 'img[src]',
                getAttrs(dom) {
                    return {
                        src: (dom as HTMLElement).getAttribute('src'),
                        title: (dom as HTMLElement).getAttribute('title'),
                        alt: (dom as HTMLElement).getAttribute('alt'),
                    };
                },
            },
        ],
        toDOM(node) {
            let { src, alt, title } = node.attrs;
            return ['img', { src, alt, title, class: 'image' }];
        },
    },

    emoji: {
        inline: true,
        attrs: {
            src: {},
            alt: { default: null },
            title: { default: null },
        },
        group: 'inline',
        draggable: true,
        parseDOM: [
            {
                tag: 'img[src]',
                getAttrs(dom) {
                    return {
                        src: (dom as HTMLElement).getAttribute('src'),
                        title: (dom as HTMLElement).getAttribute('title'),
                        alt: (dom as HTMLElement).getAttribute('alt'),
                    };
                },
            },
        ],
        toDOM(node) {
            let { src, alt, title } = node.attrs;
            return ['img', { src, alt, title, class: 'emoji' }];
        },
    },

    pending_attached_image: {
        inline: true,
        attrs: {
            guid: {},
        },
        group: 'inline',
        draggable: true,
        parseDOM: [
            {
                tag: 'div.pending_attached_image',
                getAttrs(dom) {
                    return {
                        guid: (dom as HTMLElement).getAttribute('data-guid'),
                    };
                },
            },
        ],
        toDOM(node) {
            const { guid } = node.attrs;

            return [
                'div',
                {
                    'class': 'pending_attached_image',
                    'data-guid': guid,
                },
            ];
        },
    },

    attached_image: {
        inline: true,
        attrs: {
            full_src: {},
            thumb_src: {},
            alt: { default: null },
            title: { default: null },
            aspect: { default: 1 }, // width:height
        },
        group: 'inline',
        draggable: true,
        parseDOM: [
            {
                tag: 'attached_image',
                getAttrs(dom) {
                    const attachedImage = dom as HTMLElement;
                    const full_src = attachedImage.getAttribute('data-full-src');
                    const thumb_src = attachedImage.getAttribute('data-thumb-src');
                    const aspect = attachedImage.getAttribute('data-aspect');
                    const alt = attachedImage.getAttribute('data-alt');
                    const title = attachedImage.getAttribute('data-title');

                    return { full_src, thumb_src, alt, title, aspect };
                },
            },
        ],
        toDOM(node) {
            const { full_src, thumb_src, alt, title, aspect } = node.attrs;
            return [
                'attached_image',
                {
                    'class': 'attached_image',
                    'data-full-src': full_src,
                    'data-thumb-src': thumb_src,
                    'data-alt': alt,
                    'data-title': title,
                    'data-aspect': aspect,
                },
            ];
        },
    },

    hard_break: {
        inline: true,
        group: 'inline',
        selectable: false,
        parseDOM: [{ tag: 'br' }],
        toDOM() {
            return ['br'];
        },
    },

    attachment: {
        inline: true,
        attrs: {
            src: { default: null },
            filename: { default: 'file' },
        },
        group: 'inline',
        draggable: true,
        parseDOM: [
            {
                tag: 'a.attachment',
                getAttrs(dom) {
                    return {
                        src: (dom as HTMLElement).getAttribute('href'),
                        filename: (dom as HTMLElement).innerText,
                    };
                },
            },
        ],
        toDOM(node) {
            const { src, filename } = node.attrs;
            return ['a', { href: src, class: 'attachment' }, filename];
        },
    },

    mention: {
        inline: true,
        attrs: {
            // TODO: some inconsistency was introduced here. We don't want 'data' to be part of attibutes names, since it's saved to DB. We want 'data' only in DOM. Generally we want 'something' in attributes and 'data-something' in DOM.
            'data-guid': { default: null },
            'data-user-id': { default: null },
        },
        group: 'inline',
        draggable: true,
        parseDOM: [
            {
                tag: 'span.mention',
                getAttrs(dom) {
                    return {
                        'data-guid': (dom as HTMLElement).getAttribute('data-guid'),
                        'data-user-id': (dom as HTMLElement).getAttribute('data-user-id'),
                    };
                },
            },
        ],
        toDOM(node) {
            const { 'data-guid': guid, 'data-user-id': userId } = node.attrs;
            return [
                'mention',
                {
                    'data-guid': guid,
                    'data-user-id': userId,
                },
            ];
        },
    },
};
