import addErrorCode from './addErrorCode';
import { prettifyStackDecorator } from './utils';
import { TErrorCodes } from '@ab-task/types';

export type TBackendErrorCode = TErrorCodes<typeof BackendError>;
export class BackendError extends Error {
    public params?: Record<string, any>;

    private constructor(message: string, params?: Record<string, any>) {
        super();

        this.name = 'BackendError';
        this.message = message;
        this.params = params;
    }

    @prettifyStackDecorator
    @addErrorCode
    public static INTERNAL(params?: Record<string, any>) {
        return new BackendError('Internal error', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static NO_DATA(params?: Record<string, any>) {
        return new BackendError('Data can not be fetched', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static AUTH(params?: Record<string, any>) {
        return new BackendError('Auth', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static WRONG_PASSWORD(params?: Record<string, any>) {
        return new BackendError('Wrong password', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static NO_TOKEN(params?: Record<string, any>) {
        return new BackendError('No token provided', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static WRONG_SESSION(params?: Record<string, any>) {
        return new BackendError('Problem with session', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static SESSION_MISSING(params?: Record<string, any>) {
        return new BackendError('Session is missing', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static SESSION_WRONG_SECRET(params?: Record<string, any>) {
        return new BackendError('Session secret mismatch', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static USER_BANNED(params?: Record<string, any>) {
        return new BackendError('User was banned', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static USER_NO_PASSWORD(params?: Record<string, any>) {
        return new BackendError('User password was not set', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static USER_EXISTS(params?: Record<string, any>) {
        return new BackendError('User exists', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static USER_NOT_FOUND(params?: Record<string, any>) {
        return new BackendError('User not found', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static PERMISSIONS(params?: Record<string, any> | string) {
        return new BackendError(
            'Premission denied',
            typeof params === 'string' ? { info: params } : params
        );
    }

    @prettifyStackDecorator
    @addErrorCode
    public static STEPS_REJECTED(params?: Record<string, any>) {
        return new BackendError('Steps rejected', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static PAYLOAD_MALFORMED(params?: Record<string, any>) {
        return new BackendError('Malformed payload', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static HANDLER_UNDEFINED(fieldName: string, params?: Record<string, any>) {
        return new BackendError(`API handler for field name "${fieldName}" is undefined`, params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static PARSING(params?: Record<string, any>) {
        return new BackendError('Failed to parse data', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static CONFIGURATION_ERROR(params?: Record<string, any>) {
        return new BackendError('Backend was misconfigured', params);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static EXTERNAL_API_ERROR(params?: Record<string, any>) {
        return new BackendError('Failure in 3rd-party API', params);
    }
}
