/* eslint-disable */
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { TransactionFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchTransactionsQueryVariables = Types.Exact<{
    filter: Types.IAPITransactionsFilter;
    sort?: Types.InputMaybe<Array<Types.IAPITransactionsSort> | Types.IAPITransactionsSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchTransactionsQuery = {
    __typename: 'Query';
    transactions: {
        __typename: 'Transactions';
        allIds?: Array<number> | null;
        transactions: Array<{
            __typename: 'Transaction';
            id: string;
            userId: string;
            method: Types.TransactionMethod;
            receiptUrl: string;
            total: number;
            processedAt: string;
        }>;
    };
};

export const FetchTransactionsDocument = gql`
    query FetchTransactions(
        $filter: TransactionsFilter!
        $sort: [TransactionsSort!]
        $pagination: Pagination
    ) {
        transactions(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            transactions {
                ...TransactionFields
            }
        }
    }
    ${TransactionFieldsFragmentDoc}
`;

/**
 * __useFetchTransactionsQuery__
 *
 * To run a query within a React component, call `useFetchTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTransactionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchTransactionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        IAPIFetchTransactionsQuery,
        IAPIFetchTransactionsQueryVariables
    > &
        ({ variables: IAPIFetchTransactionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchTransactionsDocument, options);
    return Apollo.useQuery<IAPIFetchTransactionsQuery, IAPIFetchTransactionsQueryVariables>(
        FetchTransactionsDocument,
        options
    );
}
export function useFetchTransactionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchTransactionsQuery,
        IAPIFetchTransactionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchTransactionsQuery, IAPIFetchTransactionsQueryVariables>(
        FetchTransactionsDocument,
        options
    );
}
export function useFetchTransactionsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchTransactionsQuery,
        IAPIFetchTransactionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchTransactionsQuery, IAPIFetchTransactionsQueryVariables>(
        FetchTransactionsDocument,
        options
    );
}
export type FetchTransactionsQueryHookResult = ReturnType<typeof useFetchTransactionsQuery>;
export type FetchTransactionsLazyQueryHookResult = ReturnType<typeof useFetchTransactionsLazyQuery>;
export type FetchTransactionsSuspenseQueryHookResult = ReturnType<
    typeof useFetchTransactionsSuspenseQuery
>;
export type FetchTransactionsQueryResult = Apollo.QueryResult<
    IAPIFetchTransactionsQuery,
    IAPIFetchTransactionsQueryVariables
>;
