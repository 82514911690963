import { v4 as uuid } from 'uuid';

const stableGUIDs = new Map<number, string[]>();

export function getStableGUIDs(quantity: number = 1) {
    let guids = stableGUIDs.get(quantity);

    if (guids === undefined) {
        guids = [];

        for (let i = 0; i < quantity; i++) {
            guids.push(uuid());
        }

        stableGUIDs.set(quantity, guids);
    }

    return guids;
}
