/* eslint-disable */
import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { TopicFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchTopicQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchTopicQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Emoji' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Task' }
        | {
              __typename: 'Topic';
              id: string;
              workspaceId: string;
              projectId?: string | null;
              name: string;
              createdAt: string;
              updatedAt: string;
              messagedAt?: string | null;
              unreads: number;
              hasMentions: boolean;
              creatorAlignments?: {
                  __typename: 'CreatorAlignments';
                  left: Array<string>;
                  right: Array<string>;
              } | null;
          }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchTopicsQueryVariables = Types.Exact<{
    filter: Types.IAPITopicsFilter;
    sort?: Types.InputMaybe<Array<Types.IAPITopicsSort> | Types.IAPITopicsSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchTopicsQuery = {
    __typename: 'Query';
    topics: {
        __typename: 'Topics';
        allIds?: Array<number> | null;
        topics: Array<{
            __typename: 'Topic';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
        }>;
    };
};

export type IAPICreateTopicMutationVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    topic?: Types.InputMaybe<Types.IAPITopicInput>;
}>;

export type IAPICreateTopicMutation = {
    __typename: 'Mutation';
    createTopic: {
        __typename: 'TopicMutationResult';
        clientId: string;
        workspaceId: string;
        topic: {
            __typename: 'Topic';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
        };
    };
};

export type IAPIUpdateTopicMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
    topic?: Types.InputMaybe<Types.IAPITopicInput>;
}>;

export type IAPIUpdateTopicMutation = {
    __typename: 'Mutation';
    updateTopic: {
        __typename: 'TopicMutationResult';
        clientId: string;
        workspaceId: string;
        topic: {
            __typename: 'Topic';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
        };
    };
};

export type IAPIRemoveTopicMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveTopicMutation = {
    __typename: 'Mutation';
    removeTopic: {
        __typename: 'TopicMutationResult';
        clientId: string;
        workspaceId: string;
        topic: {
            __typename: 'Topic';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
        };
    };
};

export type IAPITopicCreatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPITopicCreatedSubscription = {
    __typename: 'Subscription';
    topicCreated?: {
        __typename: 'TopicMutationResult';
        clientId: string;
        topic: {
            __typename: 'Topic';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
        };
    } | null;
};

export type IAPITopicUpdatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPITopicUpdatedSubscription = {
    __typename: 'Subscription';
    topicUpdated?: {
        __typename: 'TopicMutationResult';
        clientId: string;
        topic: {
            __typename: 'Topic';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
        };
    } | null;
};

export type IAPITopicRemovedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPITopicRemovedSubscription = {
    __typename: 'Subscription';
    topicRemoved?: {
        __typename: 'TopicMutationResult';
        clientId: string;
        topic: {
            __typename: 'Topic';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
        };
    } | null;
};

export const FetchTopicDocument = gql`
    query FetchTopic($id: ID!) {
        node(id: $id) {
            ...TopicFields
        }
    }
    ${TopicFieldsFragmentDoc}
`;

/**
 * __useFetchTopicQuery__
 *
 * To run a query within a React component, call `useFetchTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTopicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchTopicQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchTopicQuery, IAPIFetchTopicQueryVariables> &
        ({ variables: IAPIFetchTopicQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchTopicDocument, options);
    return Apollo.useQuery<IAPIFetchTopicQuery, IAPIFetchTopicQueryVariables>(
        FetchTopicDocument,
        options
    );
}
export function useFetchTopicLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchTopicQuery, IAPIFetchTopicQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchTopicQuery, IAPIFetchTopicQueryVariables>(
        FetchTopicDocument,
        options
    );
}
export function useFetchTopicSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchTopicQuery, IAPIFetchTopicQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchTopicQuery, IAPIFetchTopicQueryVariables>(
        FetchTopicDocument,
        options
    );
}
export type FetchTopicQueryHookResult = ReturnType<typeof useFetchTopicQuery>;
export type FetchTopicLazyQueryHookResult = ReturnType<typeof useFetchTopicLazyQuery>;
export type FetchTopicSuspenseQueryHookResult = ReturnType<typeof useFetchTopicSuspenseQuery>;
export type FetchTopicQueryResult = Apollo.QueryResult<
    IAPIFetchTopicQuery,
    IAPIFetchTopicQueryVariables
>;
export const FetchTopicsDocument = gql`
    query FetchTopics($filter: TopicsFilter!, $sort: [TopicsSort!], $pagination: Pagination) {
        topics(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            topics {
                ...TopicFields
            }
        }
    }
    ${TopicFieldsFragmentDoc}
`;

/**
 * __useFetchTopicsQuery__
 *
 * To run a query within a React component, call `useFetchTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTopicsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchTopicsQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchTopicsQuery, IAPIFetchTopicsQueryVariables> &
        ({ variables: IAPIFetchTopicsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchTopicsDocument, options);
    return Apollo.useQuery<IAPIFetchTopicsQuery, IAPIFetchTopicsQueryVariables>(
        FetchTopicsDocument,
        options
    );
}
export function useFetchTopicsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchTopicsQuery, IAPIFetchTopicsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchTopicsQuery, IAPIFetchTopicsQueryVariables>(
        FetchTopicsDocument,
        options
    );
}
export function useFetchTopicsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchTopicsQuery,
        IAPIFetchTopicsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchTopicsQuery, IAPIFetchTopicsQueryVariables>(
        FetchTopicsDocument,
        options
    );
}
export type FetchTopicsQueryHookResult = ReturnType<typeof useFetchTopicsQuery>;
export type FetchTopicsLazyQueryHookResult = ReturnType<typeof useFetchTopicsLazyQuery>;
export type FetchTopicsSuspenseQueryHookResult = ReturnType<typeof useFetchTopicsSuspenseQuery>;
export type FetchTopicsQueryResult = Apollo.QueryResult<
    IAPIFetchTopicsQuery,
    IAPIFetchTopicsQueryVariables
>;
export const CreateTopicDocument = gql`
    mutation CreateTopic($workspaceId: ID!, $topic: TopicInput) {
        createTopic(workspaceId: $workspaceId, topic: $topic) {
            clientId
            workspaceId
            topic {
                ...TopicFields
            }
        }
    }
    ${TopicFieldsFragmentDoc}
`;
export type IAPICreateTopicMutationFn = Apollo.MutationFunction<
    IAPICreateTopicMutation,
    IAPICreateTopicMutationVariables
>;

/**
 * __useCreateTopicMutation__
 *
 * To run a mutation, you first call `useCreateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicMutation, { data, loading, error }] = useCreateTopicMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useCreateTopicMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateTopicMutation,
        IAPICreateTopicMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateTopicMutation, IAPICreateTopicMutationVariables>(
        CreateTopicDocument,
        options
    );
}
export type CreateTopicMutationHookResult = ReturnType<typeof useCreateTopicMutation>;
export type CreateTopicMutationResult = Apollo.MutationResult<IAPICreateTopicMutation>;
export type CreateTopicMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateTopicMutation,
    IAPICreateTopicMutationVariables
>;
export const UpdateTopicDocument = gql`
    mutation UpdateTopic($id: ID!, $topic: TopicInput) {
        updateTopic(id: $id, topic: $topic) {
            clientId
            workspaceId
            topic {
                ...TopicFields
            }
        }
    }
    ${TopicFieldsFragmentDoc}
`;
export type IAPIUpdateTopicMutationFn = Apollo.MutationFunction<
    IAPIUpdateTopicMutation,
    IAPIUpdateTopicMutationVariables
>;

/**
 * __useUpdateTopicMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMutation, { data, loading, error }] = useUpdateTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useUpdateTopicMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateTopicMutation,
        IAPIUpdateTopicMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateTopicMutation, IAPIUpdateTopicMutationVariables>(
        UpdateTopicDocument,
        options
    );
}
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = Apollo.MutationResult<IAPIUpdateTopicMutation>;
export type UpdateTopicMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateTopicMutation,
    IAPIUpdateTopicMutationVariables
>;
export const RemoveTopicDocument = gql`
    mutation RemoveTopic($id: ID!) {
        removeTopic(id: $id, action: DELETE) {
            clientId
            workspaceId
            topic {
                ...TopicFields
            }
        }
    }
    ${TopicFieldsFragmentDoc}
`;
export type IAPIRemoveTopicMutationFn = Apollo.MutationFunction<
    IAPIRemoveTopicMutation,
    IAPIRemoveTopicMutationVariables
>;

/**
 * __useRemoveTopicMutation__
 *
 * To run a mutation, you first call `useRemoveTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTopicMutation, { data, loading, error }] = useRemoveTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTopicMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveTopicMutation,
        IAPIRemoveTopicMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveTopicMutation, IAPIRemoveTopicMutationVariables>(
        RemoveTopicDocument,
        options
    );
}
export type RemoveTopicMutationHookResult = ReturnType<typeof useRemoveTopicMutation>;
export type RemoveTopicMutationResult = Apollo.MutationResult<IAPIRemoveTopicMutation>;
export type RemoveTopicMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveTopicMutation,
    IAPIRemoveTopicMutationVariables
>;
export const TopicCreatedDocument = gql`
    subscription TopicCreated($workspaceId: ID!) {
        topicCreated(workspaceId: $workspaceId) {
            clientId
            topic {
                ...TopicFields
            }
        }
    }
    ${TopicFieldsFragmentDoc}
`;

/**
 * __useTopicCreatedSubscription__
 *
 * To run a query within a React component, call `useTopicCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTopicCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicCreatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useTopicCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPITopicCreatedSubscription,
        IAPITopicCreatedSubscriptionVariables
    > &
        ({ variables: IAPITopicCreatedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(TopicCreatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPITopicCreatedSubscription,
            IAPITopicCreatedSubscriptionVariables
        >(TopicCreatedDocument, options),
        connected,
    };
}
export type TopicCreatedSubscriptionHookResult = ReturnType<typeof useTopicCreatedSubscription>;
export type TopicCreatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPITopicCreatedSubscription>;
export const TopicUpdatedDocument = gql`
    subscription TopicUpdated($workspaceId: ID!) {
        topicUpdated(workspaceId: $workspaceId) {
            clientId
            topic {
                ...TopicFields
            }
        }
    }
    ${TopicFieldsFragmentDoc}
`;

/**
 * __useTopicUpdatedSubscription__
 *
 * To run a query within a React component, call `useTopicUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTopicUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicUpdatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useTopicUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPITopicUpdatedSubscription,
        IAPITopicUpdatedSubscriptionVariables
    > &
        ({ variables: IAPITopicUpdatedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(TopicUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPITopicUpdatedSubscription,
            IAPITopicUpdatedSubscriptionVariables
        >(TopicUpdatedDocument, options),
        connected,
    };
}
export type TopicUpdatedSubscriptionHookResult = ReturnType<typeof useTopicUpdatedSubscription>;
export type TopicUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPITopicUpdatedSubscription>;
export const TopicRemovedDocument = gql`
    subscription TopicRemoved($workspaceId: ID!) {
        topicRemoved(workspaceId: $workspaceId) {
            clientId
            topic {
                ...TopicFields
            }
        }
    }
    ${TopicFieldsFragmentDoc}
`;

/**
 * __useTopicRemovedSubscription__
 *
 * To run a query within a React component, call `useTopicRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTopicRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicRemovedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useTopicRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPITopicRemovedSubscription,
        IAPITopicRemovedSubscriptionVariables
    > &
        ({ variables: IAPITopicRemovedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(TopicRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPITopicRemovedSubscription,
            IAPITopicRemovedSubscriptionVariables
        >(TopicRemovedDocument, options),
        connected,
    };
}
export type TopicRemovedSubscriptionHookResult = ReturnType<typeof useTopicRemovedSubscription>;
export type TopicRemovedSubscriptionResult =
    Apollo.SubscriptionResult<IAPITopicRemovedSubscription>;
