import { ETables, IDBEmoji, IEmoji, IAPIEmoji } from '@ab-task/types';
import { ID2GUID, GUID2ID } from './core';

export function emojiDB2JS(dbEmoji: IDBEmoji): IEmoji {
    return {
        id: dbEmoji.emj_id,
        imageBase64: dbEmoji.emj_image_b64,
        shortname: dbEmoji.emj_short_name,
        unicode: Buffer.from(dbEmoji.emj_unicode, 'hex').toString(),
    };
}

export function emojiDB2API(dbEmoji: IDBEmoji): IAPIEmoji {
    return {
        __typename: 'Emoji',
        id: ID2GUID(ETables.emojis, dbEmoji.emj_id),
        imageBase64: dbEmoji.emj_image_b64,
        shortname: dbEmoji.emj_short_name,
        unicode: Buffer.from(dbEmoji.emj_unicode, 'hex').toString(),
    };
}

export function emojiAPI2JS(apiEmoji: IAPIEmoji): IEmoji {
    return {
        id: GUID2ID(apiEmoji.id)[1],
        imageBase64: apiEmoji.imageBase64,
        shortname: apiEmoji.shortname,
        unicode: apiEmoji.unicode,
    };
}
