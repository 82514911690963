import { ELSKeys } from '@ab-task/data';
import { FrontendError } from '@ab-task/errors';
import {
    EPanelsCommand,
    IPanelsConfig,
    IWorkspaceContext,
    TWorkspaceContexts,
} from '@ab-task/types';
import { getCurrentWorkspaceContext } from './helpers/getCurrentWorkspaceContext';
import { updateCurrentWorkspaceContext } from './updateCurrentWorkspaceContext';
import { ensureAndGetCurrentWorkspaceId } from './helpers';

export function setPanelsConfig(command: EPanelsCommand, value: string = 'default') {
    const currentWorkspaceId = ensureAndGetCurrentWorkspaceId();
    const currentWorkspaceContext = getCurrentWorkspaceContext();
    const currentConfig: IPanelsConfig = currentWorkspaceContext?.panelsConfig || {
        left: 'default',
    };

    let savedPanelsConfigLeft = 'default';

    {
        // Try to get previous panels config value for left panel
        const lsWorkspaceContexts = localStorage.getItem(ELSKeys.WorkspaceContexts);

        if (lsWorkspaceContexts !== null) {
            const workspaceContexts = JSON.parse(lsWorkspaceContexts) as TWorkspaceContexts;
            const workspaceContext: IWorkspaceContext | undefined =
                workspaceContexts[currentWorkspaceId];

            if (workspaceContext?.panelsConfig?.left) {
                savedPanelsConfigLeft = workspaceContext.panelsConfig.left;
            }
        }
    }

    if (command === EPanelsCommand.ToggleLeft) {
        command = currentConfig.left === value ? EPanelsCommand.CloseLeft : EPanelsCommand.OpenLeft;
    }

    if (command === EPanelsCommand.ToggleRight) {
        command =
            currentConfig.right === value ? EPanelsCommand.CloseRight : EPanelsCommand.OpenRight;
    }

    let newPanelsConfig: IPanelsConfig | undefined;
    switch (command) {
        case EPanelsCommand.OpenLeft:
            newPanelsConfig = {
                left: value,
            };
            break;

        case EPanelsCommand.CloseLeft:
            if (currentConfig.left) {
                newPanelsConfig = {
                    right: currentConfig.right,
                };
            }
            break;

        case EPanelsCommand.OpenRight:
            newPanelsConfig = {
                left: value.length > 0 ? undefined : savedPanelsConfigLeft,
                right: value,
            };
            break;

        case EPanelsCommand.CloseRight:
            if (currentConfig.right) {
                newPanelsConfig = {
                    left: savedPanelsConfigLeft,
                };
            }
            break;

        default:
            throw FrontendError.GENERAL('Unknown panels config command');
    }

    if (newPanelsConfig) {
        updateCurrentWorkspaceContext({ panelsConfig: newPanelsConfig });
    }
}
