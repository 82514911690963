import {
    IAPIMutation,
    IAPIPagination,
    IAPIQuery,
    IAPISubscription,
    Maybe,
    SortDirection,
} from '../graphql.gen';

export type TAPIFieldName =
    | keyof Omit<IAPIQuery, '__typename'>
    | keyof Omit<IAPIMutation, '__typename'>
    | keyof Omit<IAPISubscription, '__typename'>;

export type TAPISort = Record<string, Maybe<SortDirection>>;

export type TAPIPagination = IAPIPagination;

export interface IOperationInfo {
    operationType?: 'query' | 'mutation' | 'subscription';
    operationNames: string[];
    fieldNames: string[];
}
