import defaultTranslation from './translations/en.json';
import type * as React from 'react';
import { v4 as uuid } from 'uuid';
import {
    firstLetterToUpperCase,
    getArrayNodesReplaced,
    getPlaceholdersFromStr,
} from '@ab-task/utils';
import { BackendError, FrontendError } from '@ab-task/errors';

interface IParams {
    [key: string]: string | number | [string | number, string];
}

type TTranslationKeys = keyof typeof defaultTranslation;

interface ITranslations {
    [translationKey: string]: string | Array<string | string[]>;
}

// local "state"
let translations: ITranslations = {};
export let locale: string;
export let timeZone: string;

switchLocale('en-US');
switchTimezone('UTC');
// ----

// locale
export async function switchLocale(lc: string) {
    locale = lc;

    const lang = /^([a-z]+)-/.exec(locale);

    if (lang === null) {
        throw FrontendError.LANGUAGE_NOT_FOUND(locale);
    }

    switch (lang[1]) {
        case 'en':
            translations = defaultTranslation;
            break;

        default:
            translations = defaultTranslation;
    }
}

// translations
export function tj(translationKey: string, params?: IParams) {
    if (translations[translationKey] !== undefined) {
        return getTranslation(translations, translationKey, params);
    }

    if (defaultTranslation[translationKey as TTranslationKeys] !== undefined) {
        // fallback to EN
        reportMissingTranslation(
            `Missing translation key "${translationKey}" in locale "${locale}"`
        );

        return [getTranslation(defaultTranslation, translationKey, params)];
    }

    reportMissingTranslation(`Missing translation key "${translationKey}"`);

    return [`[${translationKey}]`];
}

export function t(translationKey: string, params?: IParams) {
    return tj(translationKey, params).flat(3).join('');
}

export function td(translationKey: string, params?: IParams) {
    return t(translationKey, params) + '.';
}

export function T(translationKey: string, params?: IParams) {
    return firstLetterToUpperCase(t(translationKey, params));
}

export function Td(translationKey: string, params?: IParams) {
    return T(translationKey, params) + '.';
}

export function TT(translationKey: string, params?: IParams) {
    return t(translationKey, params).toUpperCase();
}

export function TTd(translationKey: string, params?: IParams) {
    return TT(translationKey, params) + '.';
}

// datetime
export function switchTimezone(tz: string) {
    timeZone = tz;
}

export function d(dt: string | number | Date, options?: Intl.DateTimeFormatOptions) {
    const dateTime = new Date(dt);

    options = {
        ...options,
        timeZone,
    };

    return new Intl.DateTimeFormat(locale, options).format(dateTime);
}

// Only for browsers
export function getTimeZones() {
    const intl = Intl as { supportedValuesOf?: (key: string) => string[] };
    // TODO: this check is not needed on nodev18
    if (!intl.supportedValuesOf) {
        throw BackendError.INTERNAL({ info: 'Intl.supportedValuesOf not supported' });
    }
    return intl.supportedValuesOf('timeZone');
}

// utils
function getTranslation(translationObj: ITranslations, translationKey: string, params?: IParams) {
    const cardinalPluralCategories = translationObj._cardinal_pluralCategories_ as string[];
    const ordinalPluralCategories = translationObj._ordinal_pluralCategories_ as string[];

    let translationStr: string;
    if (typeof translationObj[translationKey] === 'object') {
        if (params !== undefined && params.plural !== undefined) {
            if (params.plural === 1) {
                translationStr = translationObj[translationKey][1] as string;
            } else {
                const rule = new Intl.PluralRules(locale, { type: 'cardinal' }).select(
                    Number(params.plural)
                );

                const categories = cardinalPluralCategories;

                if (translationObj[translationKey][2] === undefined) {
                    console.warn(
                        getErrorMessage(
                            `Cardinal translations in lookup key "${translationKey}" not found`
                        )
                    );
                    translationStr = translationObj[translationKey][1] as string;
                } else {
                    const variants = translationObj[translationKey][2] as string[];

                    const index = categories.findIndex(categoryName => categoryName === rule);

                    if (index === -1) {
                        console.warn(
                            getErrorMessage(
                                `Plural categories of type "cardinal" don't contain Intl selected rule "${rule}"`
                            )
                        );

                        translationStr = translationObj[translationKey][1] as string;
                    } else {
                        if (variants[index] === undefined) {
                            console.warn(
                                getErrorMessage(
                                    `Translation for rule "${rule}"(cardinal) in key "${translationKey}" not provided`
                                )
                            );
                            translationStr = translationObj[translationKey][1] as string;
                        } else {
                            translationStr = String(variants[index]);
                        }
                    }
                }
            }
        } else {
            translationStr = translationObj[translationKey][0] as string;
        }
    } else {
        translationStr = translationObj[translationKey] as string;
    }

    let result: React.ReactNode[] = [translationStr];

    // placeholders for lookup values
    const lookupKeys = getPlaceholdersFromStr(translationStr, /\[\[([^[\]]+)\]\]/g);
    for (const lookupKey of lookupKeys) {
        const keys = lookupKey.split(',').map(v => v.trim());

        // simple lookup
        if (keys.length === 1) {
            if (keys[0] === 'br') {
                result = getArrayNodesReplaced(result, `[[${lookupKey}]]`, () => (
                    <br key={uuid()} />
                ));
                continue;
            }

            const key = keys[0] as TTranslationKeys;

            if (translationObj[key] === undefined) {
                console.warn(getErrorMessage(`Lookup key "${key}" not found`));
                return result;
            }

            result = getArrayNodesReplaced(
                result,
                `[[${lookupKey}]]`,
                getTranslation(translationObj, key, params)
            );

            continue;
        }

        // lookup with params
        if (keys.length > 1) {
            const key = keys[1] as TTranslationKeys;

            if (translationObj[key] === undefined) {
                console.warn(getErrorMessage(`Lookup key "${key}" not found`));
                return result;
            }

            if (typeof translationObj[key] !== 'object') {
                result = getArrayNodesReplaced(
                    result,
                    `[[${lookupKey}]]`,
                    getTranslation(translationObj, key, params)
                );

                continue;
            }

            if (params === undefined) {
                console.warn(getErrorMessage(`Params required in translation but not given`));
                return result;
            }

            let value = params[keys[0] as keyof typeof params];

            if (value === undefined) {
                console.warn(
                    getErrorMessage(`Param "${keys[0]}" is set in lookup, but was not provided`)
                );
                return result;
            }

            if (typeof value === 'object') {
                value = value[0];
            }

            let type: 'cardinal' | 'ordinal';
            let categories: string[];
            let variants: string[];

            if (keys[2] === undefined) {
                type = 'cardinal';
                categories = cardinalPluralCategories;

                if (translationObj[key][2] === undefined) {
                    console.warn(
                        getErrorMessage(`Cardinal translations in lookup key "${key}" not found`)
                    );
                    return result;
                }

                variants = translationObj[key][2] as string[];
            } else {
                type = 'ordinal';
                categories = ordinalPluralCategories;

                if (translationObj[key][3] === undefined) {
                    console.warn(
                        getErrorMessage(`Ordinal translations in lookup key "${key}" not found`)
                    );
                    return result;
                }

                variants = translationObj[key][3] as string[];
            }

            const rule = new Intl.PluralRules(locale, { type }).select(Number(value));

            const index = categories.findIndex(categoryName => categoryName === rule);
            if (index === -1) {
                console.warn(
                    getErrorMessage(
                        `Plural categories of type "${type}" don't contain Intl selected rule "${rule}"`
                    )
                );
                return result;
            }

            if (variants[index] === undefined) {
                console.warn(
                    getErrorMessage(
                        `Translation for rule "${rule}"(${type}) in lookup key "${key}" not provided`
                    )
                );
                return result;
            }

            result = getArrayNodesReplaced(result, `[[${lookupKey}]]`, String(variants[index]));
        }
    }

    // placeholders for external values
    const externalKeys = getPlaceholdersFromStr(translationStr, /{{([^{}]+)}}/g);
    for (const externalKey of externalKeys) {
        if (params === undefined || params[externalKey] === undefined) {
            console.warn(
                getErrorMessage(`Param ${externalKey} required in translation but not given`)
            );
            return result;
        }

        const value = params[externalKey];

        if (typeof value === 'object') {
            const replace = (
                <span key={uuid()} className={value[1]}>
                    {value[0]}
                </span>
            );

            result = getArrayNodesReplaced(result, `{{${externalKey}}}`, replace);
        } else {
            result = getArrayNodesReplaced(result, `{{${externalKey}}}`, String(value));
        }
    }

    return result;

    // utils
    function getErrorMessage(message: string) {
        return `[${locale}:${translationKey}] ${message}`;
    }
}

function reportMissingTranslation(warnMsg: string) {
    console.warn(warnMsg);
}
