import { ETables, IAPISuspension, IDBSuspensionJson, ISuspension } from '@ab-task/types';
import { GUID2ID, ID2GUID, IDPair2GUID } from '.';

export function suspensionDBJson2JS(dbSuspension: IDBSuspensionJson): ISuspension {
    return {
        initiatorId: dbSuspension.ssp_initiator_id,
        suspendedAt: new Date(dbSuspension.ssp_suspended_at),
    };
}

export function suspensionDBJson2API(dbSuspension: IDBSuspensionJson): IAPISuspension {
    return {
        __typename: 'Suspension',
        id: IDPair2GUID(
            ETables.suspensions,
            dbSuspension.ssp_user_id,
            dbSuspension.ssp_initiator_id
        ),
        initiatorId: ID2GUID(ETables.users, dbSuspension.ssp_initiator_id),
        suspendedAt: new Date(dbSuspension.ssp_suspended_at).toISOString(),
    };
}

export function suspensionAPI2JS(apiSuspension: IAPISuspension): ISuspension {
    return {
        initiatorId: GUID2ID(apiSuspension.initiatorId)[1],
        suspendedAt: new Date(apiSuspension.suspendedAt),
    };
}

export function suspensionJS2API(userId: number, suspension: ISuspension): IAPISuspension {
    return {
        __typename: 'Suspension',
        id: IDPair2GUID(ETables.suspensions, userId, suspension.initiatorId),
        initiatorId: ID2GUID(ETables.users, suspension.initiatorId),
        suspendedAt: suspension.suspendedAt.toISOString(),
    };
}
