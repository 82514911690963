/*
 * Types for reactive vars (local state managed by Apollo Client)
 */

import { DashboardType } from '../graphql.gen';

export interface IWorkspaceContext {
    dashboardType: DashboardType;
    panelsConfig: IPanelsConfig;
}

export type TWorkspaceContexts = Record<number, IWorkspaceContext>;

export interface IPanelsConfig {
    left?: string;
    right?: string;
}

export interface INotification {
    id: string;
    type: ENotificationType;
    title: string;
    details?: string[];
    createdAt: number;
}

export enum EConnectionState {
    Offline = 'Offline',
    Online = 'Online',
}

export enum ESocketState {
    Closed = 'Closed',
    Open = 'Open',
}

export enum ESubscriptionsState {
    Inactive = 'Inactive',
    Pending = 'Pending',
    Ready = 'Ready',
}

export enum ENotificationType {
    Success = 'Success',
    Warning = 'Warning',
    Error = 'Error',
}

export enum EPanelsCommand {
    OpenRight = 'OPEN_RIGHT',
    ToggleRight = 'TOGGLE_RIGHT',
    CloseRight = 'CLOSE_RIGHT',
    OpenLeft = 'OPEN_LEFT',
    ToggleLeft = 'TOGGLE_LEFT',
    CloseLeft = 'CLOSE_LEFT',
}
