/* eslint-disable */
import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { AttachmentFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchAttachmentsQueryVariables = Types.Exact<{
    filter: Types.IAPIMessagesFilter;
}>;

export type IAPIFetchAttachmentsQuery = {
    __typename: 'Query';
    attachments: Array<{
        __typename: 'Attachment';
        messageId: string;
        filename: string;
        contentType: string;
        s3Key: string;
        createdAt: string;
        progress?: number | null;
    }>;
};

export type IAPICreateAttachmentMutationVariables = Types.Exact<{
    attachment: Types.IAPIAttachmentCreateInput;
}>;

export type IAPICreateAttachmentMutation = {
    __typename: 'Mutation';
    createAttachment: {
        __typename: 'AttachmentMutationResult';
        channelId: string;
        uploadURL?: string | null;
        attachment: {
            __typename: 'Attachment';
            messageId: string;
            filename: string;
            contentType: string;
            s3Key: string;
            createdAt: string;
            progress?: number | null;
        };
    };
};

export type IAPIUpdateAttachmentMutationVariables = Types.Exact<{
    s3Key: Types.Scalars['String']['input'];
    progress?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type IAPIUpdateAttachmentMutation = {
    __typename: 'Mutation';
    updateAttachment: {
        __typename: 'AttachmentMutationResult';
        channelId: string;
        progress?: number | null;
        attachment: {
            __typename: 'Attachment';
            messageId: string;
            filename: string;
            contentType: string;
            s3Key: string;
            createdAt: string;
            progress?: number | null;
        };
    };
};

export type IAPIRemoveAttachmentMutationVariables = Types.Exact<{
    s3Key: Types.Scalars['String']['input'];
}>;

export type IAPIRemoveAttachmentMutation = {
    __typename: 'Mutation';
    removeAttachment: {
        __typename: 'AttachmentMutationResult';
        channelId: string;
        attachment: {
            __typename: 'Attachment';
            messageId: string;
            filename: string;
            contentType: string;
            s3Key: string;
            createdAt: string;
            progress?: number | null;
        };
    };
};

export type IAPIAttachmentUpdatedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPIAttachmentUpdatedSubscription = {
    __typename: 'Subscription';
    attachmentUpdated?: {
        __typename: 'AttachmentMutationResult';
        channelId: string;
        progress?: number | null;
        attachment: {
            __typename: 'Attachment';
            messageId: string;
            filename: string;
            contentType: string;
            s3Key: string;
            createdAt: string;
            progress?: number | null;
        };
    } | null;
};

export type IAPIAttachmentRemovedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPIAttachmentRemovedSubscription = {
    __typename: 'Subscription';
    attachmentRemoved?: {
        __typename: 'AttachmentMutationResult';
        channelId: string;
        attachment: {
            __typename: 'Attachment';
            messageId: string;
            filename: string;
            contentType: string;
            s3Key: string;
            createdAt: string;
            progress?: number | null;
        };
    } | null;
};

export const FetchAttachmentsDocument = gql`
    query FetchAttachments($filter: MessagesFilter!) {
        attachments(filter: $filter) {
            ...AttachmentFields
        }
    }
    ${AttachmentFieldsFragmentDoc}
`;

/**
 * __useFetchAttachmentsQuery__
 *
 * To run a query within a React component, call `useFetchAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAttachmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchAttachmentsQuery(
    baseOptions: Apollo.QueryHookOptions<
        IAPIFetchAttachmentsQuery,
        IAPIFetchAttachmentsQueryVariables
    > &
        ({ variables: IAPIFetchAttachmentsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchAttachmentsDocument, options);
    return Apollo.useQuery<IAPIFetchAttachmentsQuery, IAPIFetchAttachmentsQueryVariables>(
        FetchAttachmentsDocument,
        options
    );
}
export function useFetchAttachmentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchAttachmentsQuery,
        IAPIFetchAttachmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchAttachmentsQuery, IAPIFetchAttachmentsQueryVariables>(
        FetchAttachmentsDocument,
        options
    );
}
export function useFetchAttachmentsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchAttachmentsQuery,
        IAPIFetchAttachmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchAttachmentsQuery, IAPIFetchAttachmentsQueryVariables>(
        FetchAttachmentsDocument,
        options
    );
}
export type FetchAttachmentsQueryHookResult = ReturnType<typeof useFetchAttachmentsQuery>;
export type FetchAttachmentsLazyQueryHookResult = ReturnType<typeof useFetchAttachmentsLazyQuery>;
export type FetchAttachmentsSuspenseQueryHookResult = ReturnType<
    typeof useFetchAttachmentsSuspenseQuery
>;
export type FetchAttachmentsQueryResult = Apollo.QueryResult<
    IAPIFetchAttachmentsQuery,
    IAPIFetchAttachmentsQueryVariables
>;
export const CreateAttachmentDocument = gql`
    mutation CreateAttachment($attachment: AttachmentCreateInput!) {
        createAttachment(attachment: $attachment) {
            channelId
            uploadURL
            attachment {
                ...AttachmentFields
            }
        }
    }
    ${AttachmentFieldsFragmentDoc}
`;
export type IAPICreateAttachmentMutationFn = Apollo.MutationFunction<
    IAPICreateAttachmentMutation,
    IAPICreateAttachmentMutationVariables
>;

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      attachment: // value for 'attachment'
 *   },
 * });
 */
export function useCreateAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateAttachmentMutation,
        IAPICreateAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateAttachmentMutation, IAPICreateAttachmentMutationVariables>(
        CreateAttachmentDocument,
        options
    );
}
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<IAPICreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateAttachmentMutation,
    IAPICreateAttachmentMutationVariables
>;
export const UpdateAttachmentDocument = gql`
    mutation UpdateAttachment($s3Key: String!, $progress: Int) {
        updateAttachment(s3Key: $s3Key, progress: $progress) {
            channelId
            progress
            attachment {
                ...AttachmentFields
            }
        }
    }
    ${AttachmentFieldsFragmentDoc}
`;
export type IAPIUpdateAttachmentMutationFn = Apollo.MutationFunction<
    IAPIUpdateAttachmentMutation,
    IAPIUpdateAttachmentMutationVariables
>;

/**
 * __useUpdateAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentMutation, { data, loading, error }] = useUpdateAttachmentMutation({
 *   variables: {
 *      s3Key: // value for 's3Key'
 *      progress: // value for 'progress'
 *   },
 * });
 */
export function useUpdateAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateAttachmentMutation,
        IAPIUpdateAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateAttachmentMutation, IAPIUpdateAttachmentMutationVariables>(
        UpdateAttachmentDocument,
        options
    );
}
export type UpdateAttachmentMutationHookResult = ReturnType<typeof useUpdateAttachmentMutation>;
export type UpdateAttachmentMutationResult = Apollo.MutationResult<IAPIUpdateAttachmentMutation>;
export type UpdateAttachmentMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateAttachmentMutation,
    IAPIUpdateAttachmentMutationVariables
>;
export const RemoveAttachmentDocument = gql`
    mutation RemoveAttachment($s3Key: String!) {
        removeAttachment(s3Key: $s3Key) {
            channelId
            attachment {
                ...AttachmentFields
            }
        }
    }
    ${AttachmentFieldsFragmentDoc}
`;
export type IAPIRemoveAttachmentMutationFn = Apollo.MutationFunction<
    IAPIRemoveAttachmentMutation,
    IAPIRemoveAttachmentMutationVariables
>;

/**
 * __useRemoveAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAttachmentMutation, { data, loading, error }] = useRemoveAttachmentMutation({
 *   variables: {
 *      s3Key: // value for 's3Key'
 *   },
 * });
 */
export function useRemoveAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveAttachmentMutation,
        IAPIRemoveAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveAttachmentMutation, IAPIRemoveAttachmentMutationVariables>(
        RemoveAttachmentDocument,
        options
    );
}
export type RemoveAttachmentMutationHookResult = ReturnType<typeof useRemoveAttachmentMutation>;
export type RemoveAttachmentMutationResult = Apollo.MutationResult<IAPIRemoveAttachmentMutation>;
export type RemoveAttachmentMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveAttachmentMutation,
    IAPIRemoveAttachmentMutationVariables
>;
export const AttachmentUpdatedDocument = gql`
    subscription AttachmentUpdated($channelId: ID!) {
        attachmentUpdated(channelId: $channelId) {
            channelId
            progress
            attachment {
                ...AttachmentFields
            }
        }
    }
    ${AttachmentFieldsFragmentDoc}
`;

/**
 * __useAttachmentUpdatedSubscription__
 *
 * To run a query within a React component, call `useAttachmentUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentUpdatedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useAttachmentUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIAttachmentUpdatedSubscription,
        IAPIAttachmentUpdatedSubscriptionVariables
    > &
        (
            | { variables: IAPIAttachmentUpdatedSubscriptionVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(AttachmentUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIAttachmentUpdatedSubscription,
            IAPIAttachmentUpdatedSubscriptionVariables
        >(AttachmentUpdatedDocument, options),
        connected,
    };
}
export type AttachmentUpdatedSubscriptionHookResult = ReturnType<
    typeof useAttachmentUpdatedSubscription
>;
export type AttachmentUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIAttachmentUpdatedSubscription>;
export const AttachmentRemovedDocument = gql`
    subscription AttachmentRemoved($channelId: ID!) {
        attachmentRemoved(channelId: $channelId) {
            channelId
            attachment {
                ...AttachmentFields
            }
        }
    }
    ${AttachmentFieldsFragmentDoc}
`;

/**
 * __useAttachmentRemovedSubscription__
 *
 * To run a query within a React component, call `useAttachmentRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentRemovedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useAttachmentRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIAttachmentRemovedSubscription,
        IAPIAttachmentRemovedSubscriptionVariables
    > &
        (
            | { variables: IAPIAttachmentRemovedSubscriptionVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(AttachmentRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIAttachmentRemovedSubscription,
            IAPIAttachmentRemovedSubscriptionVariables
        >(AttachmentRemovedDocument, options),
        connected,
    };
}
export type AttachmentRemovedSubscriptionHookResult = ReturnType<
    typeof useAttachmentRemovedSubscription
>;
export type AttachmentRemovedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIAttachmentRemovedSubscription>;
