/* eslint-disable */
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc, RoleFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIInitialContextQueryVariables = Types.Exact<{
    withSubscription: Types.Scalars['Boolean']['input'];
}>;

export type IAPIInitialContextQuery = {
    __typename: 'Query';
    identity?: {
        __typename: 'User';
        id: string;
        invitedToWorkspaceIds: Array<string>;
        email: string;
        firstname?: string | null;
        lastname?: string | null;
        displayName: string;
        calculatedName: string;
        timezone: string;
        status: Types.UserStatus;
        avatarS3Key?: string | null;
        paddleSubscriptionId?: number | null;
        createdAt: string;
        updatedAt: string;
        paddleSubscription?: {
            __typename: 'PaddleSubscription';
            id: number;
            updateUrl: string;
            cancelUrl: string;
            card?: {
                __typename: 'Card';
                type: string;
                lastFourDigits: string;
                expiryDate: string;
            } | null;
        } | null;
        workspaceData: Array<{
            __typename: 'WorkspaceData';
            workspaceId: string;
            suspension?: {
                __typename: 'Suspension';
                id: string;
                initiatorId: string;
                suspendedAt: string;
            } | null;
            accessList: Array<{
                __typename: 'AccessListItem';
                projectId?: string | null;
                roleId: string;
            }>;
        }>;
    } | null;
    roles: Array<{ __typename: 'Role'; id: string; name: Types.UserRole; policy: string }>;
};

export type IAPIIdentityQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IAPIIdentityQuery = {
    __typename: 'Query';
    identity?: {
        __typename: 'User';
        id: string;
        invitedToWorkspaceIds: Array<string>;
        email: string;
        firstname?: string | null;
        lastname?: string | null;
        displayName: string;
        calculatedName: string;
        timezone: string;
        status: Types.UserStatus;
        avatarS3Key?: string | null;
        paddleSubscriptionId?: number | null;
        createdAt: string;
        updatedAt: string;
        workspaceData: Array<{
            __typename: 'WorkspaceData';
            workspaceId: string;
            suspension?: {
                __typename: 'Suspension';
                id: string;
                initiatorId: string;
                suspendedAt: string;
            } | null;
            accessList: Array<{
                __typename: 'AccessListItem';
                projectId?: string | null;
                roleId: string;
            }>;
        }>;
    } | null;
};

export type IAPISendConfirmationCodeMutationVariables = Types.Exact<{
    input: Types.IAPIConfirmationCodeInput;
}>;

export type IAPISendConfirmationCodeMutation = {
    __typename: 'Mutation';
    sendConfirmationCode: boolean;
};

export type IAPIGoogleSignInMutationVariables = Types.Exact<{
    credential: Types.Scalars['String']['input'];
}>;

export type IAPIGoogleSignInMutation = { __typename: 'Mutation'; googleSignIn: string };

export type IAPIApplyInviteMutationVariables = Types.Exact<{
    email: Types.Scalars['AWSEmail']['input'];
    confirmationCode: Types.Scalars['String']['input'];
    password?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IAPIApplyInviteMutation = { __typename: 'Mutation'; applyInvite: boolean };

export type IAPILoginMutationVariables = Types.Exact<{
    email: Types.Scalars['AWSEmail']['input'];
    password: Types.Scalars['String']['input'];
}>;

export type IAPILoginMutation = { __typename: 'Mutation'; login: string };

export type IAPILogoutMutationVariables = Types.Exact<{
    allDevices?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type IAPILogoutMutation = { __typename: 'Mutation'; logout: boolean };

export const InitialContextDocument = gql`
    query InitialContext($withSubscription: Boolean!) {
        identity(withSubscription: $withSubscription) {
            ...UserFields
            paddleSubscription @include(if: $withSubscription) {
                id
                card {
                    type
                    lastFourDigits
                    expiryDate
                }
                updateUrl
                cancelUrl
            }
        }
        roles {
            ...RoleFields
        }
    }
    ${UserFieldsFragmentDoc}
    ${RoleFieldsFragmentDoc}
`;

/**
 * __useInitialContextQuery__
 *
 * To run a query within a React component, call `useInitialContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialContextQuery({
 *   variables: {
 *      withSubscription: // value for 'withSubscription'
 *   },
 * });
 */
export function useInitialContextQuery(
    baseOptions: Apollo.QueryHookOptions<
        IAPIInitialContextQuery,
        IAPIInitialContextQueryVariables
    > &
        ({ variables: IAPIInitialContextQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(InitialContextDocument, options);
    return Apollo.useQuery<IAPIInitialContextQuery, IAPIInitialContextQueryVariables>(
        InitialContextDocument,
        options
    );
}
export function useInitialContextLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIInitialContextQuery,
        IAPIInitialContextQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIInitialContextQuery, IAPIInitialContextQueryVariables>(
        InitialContextDocument,
        options
    );
}
export function useInitialContextSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIInitialContextQuery,
        IAPIInitialContextQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIInitialContextQuery, IAPIInitialContextQueryVariables>(
        InitialContextDocument,
        options
    );
}
export type InitialContextQueryHookResult = ReturnType<typeof useInitialContextQuery>;
export type InitialContextLazyQueryHookResult = ReturnType<typeof useInitialContextLazyQuery>;
export type InitialContextSuspenseQueryHookResult = ReturnType<
    typeof useInitialContextSuspenseQuery
>;
export type InitialContextQueryResult = Apollo.QueryResult<
    IAPIInitialContextQuery,
    IAPIInitialContextQueryVariables
>;
export const IdentityDocument = gql`
    query Identity {
        identity(withSubscription: false) {
            ...UserFields
        }
    }
    ${UserFieldsFragmentDoc}
`;

/**
 * __useIdentityQuery__
 *
 * To run a query within a React component, call `useIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityQuery(
    baseOptions?: Apollo.QueryHookOptions<IAPIIdentityQuery, IAPIIdentityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(IdentityDocument, options);
    return Apollo.useQuery<IAPIIdentityQuery, IAPIIdentityQueryVariables>(
        IdentityDocument,
        options
    );
}
export function useIdentityLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIIdentityQuery, IAPIIdentityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIIdentityQuery, IAPIIdentityQueryVariables>(
        IdentityDocument,
        options
    );
}
export function useIdentitySuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIIdentityQuery, IAPIIdentityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIIdentityQuery, IAPIIdentityQueryVariables>(
        IdentityDocument,
        options
    );
}
export type IdentityQueryHookResult = ReturnType<typeof useIdentityQuery>;
export type IdentityLazyQueryHookResult = ReturnType<typeof useIdentityLazyQuery>;
export type IdentitySuspenseQueryHookResult = ReturnType<typeof useIdentitySuspenseQuery>;
export type IdentityQueryResult = Apollo.QueryResult<IAPIIdentityQuery, IAPIIdentityQueryVariables>;
export const SendConfirmationCodeDocument = gql`
    mutation SendConfirmationCode($input: ConfirmationCodeInput!) {
        sendConfirmationCode(input: $input)
    }
`;
export type IAPISendConfirmationCodeMutationFn = Apollo.MutationFunction<
    IAPISendConfirmationCodeMutation,
    IAPISendConfirmationCodeMutationVariables
>;

/**
 * __useSendConfirmationCodeMutation__
 *
 * To run a mutation, you first call `useSendConfirmationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmationCodeMutation, { data, loading, error }] = useSendConfirmationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendConfirmationCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPISendConfirmationCodeMutation,
        IAPISendConfirmationCodeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPISendConfirmationCodeMutation,
        IAPISendConfirmationCodeMutationVariables
    >(SendConfirmationCodeDocument, options);
}
export type SendConfirmationCodeMutationHookResult = ReturnType<
    typeof useSendConfirmationCodeMutation
>;
export type SendConfirmationCodeMutationResult =
    Apollo.MutationResult<IAPISendConfirmationCodeMutation>;
export type SendConfirmationCodeMutationOptions = Apollo.BaseMutationOptions<
    IAPISendConfirmationCodeMutation,
    IAPISendConfirmationCodeMutationVariables
>;
export const GoogleSignInDocument = gql`
    mutation GoogleSignIn($credential: String!) {
        googleSignIn(credential: $credential)
    }
`;
export type IAPIGoogleSignInMutationFn = Apollo.MutationFunction<
    IAPIGoogleSignInMutation,
    IAPIGoogleSignInMutationVariables
>;

/**
 * __useGoogleSignInMutation__
 *
 * To run a mutation, you first call `useGoogleSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSignInMutation, { data, loading, error }] = useGoogleSignInMutation({
 *   variables: {
 *      credential: // value for 'credential'
 *   },
 * });
 */
export function useGoogleSignInMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIGoogleSignInMutation,
        IAPIGoogleSignInMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIGoogleSignInMutation, IAPIGoogleSignInMutationVariables>(
        GoogleSignInDocument,
        options
    );
}
export type GoogleSignInMutationHookResult = ReturnType<typeof useGoogleSignInMutation>;
export type GoogleSignInMutationResult = Apollo.MutationResult<IAPIGoogleSignInMutation>;
export type GoogleSignInMutationOptions = Apollo.BaseMutationOptions<
    IAPIGoogleSignInMutation,
    IAPIGoogleSignInMutationVariables
>;
export const ApplyInviteDocument = gql`
    mutation ApplyInvite($email: AWSEmail!, $confirmationCode: String!, $password: String) {
        applyInvite(email: $email, confirmationCode: $confirmationCode, password: $password)
    }
`;
export type IAPIApplyInviteMutationFn = Apollo.MutationFunction<
    IAPIApplyInviteMutation,
    IAPIApplyInviteMutationVariables
>;

/**
 * __useApplyInviteMutation__
 *
 * To run a mutation, you first call `useApplyInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyInviteMutation, { data, loading, error }] = useApplyInviteMutation({
 *   variables: {
 *      email: // value for 'email'
 *      confirmationCode: // value for 'confirmationCode'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useApplyInviteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIApplyInviteMutation,
        IAPIApplyInviteMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIApplyInviteMutation, IAPIApplyInviteMutationVariables>(
        ApplyInviteDocument,
        options
    );
}
export type ApplyInviteMutationHookResult = ReturnType<typeof useApplyInviteMutation>;
export type ApplyInviteMutationResult = Apollo.MutationResult<IAPIApplyInviteMutation>;
export type ApplyInviteMutationOptions = Apollo.BaseMutationOptions<
    IAPIApplyInviteMutation,
    IAPIApplyInviteMutationVariables
>;
export const LoginDocument = gql`
    mutation Login($email: AWSEmail!, $password: String!) {
        login(email: $email, password: $password)
    }
`;
export type IAPILoginMutationFn = Apollo.MutationFunction<
    IAPILoginMutation,
    IAPILoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<IAPILoginMutation, IAPILoginMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPILoginMutation, IAPILoginMutationVariables>(
        LoginDocument,
        options
    );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<IAPILoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
    IAPILoginMutation,
    IAPILoginMutationVariables
>;
export const LogoutDocument = gql`
    mutation Logout($allDevices: Boolean) {
        logout(allDevices: $allDevices)
    }
`;
export type IAPILogoutMutationFn = Apollo.MutationFunction<
    IAPILogoutMutation,
    IAPILogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      allDevices: // value for 'allDevices'
 *   },
 * });
 */
export function useLogoutMutation(
    baseOptions?: Apollo.MutationHookOptions<IAPILogoutMutation, IAPILogoutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPILogoutMutation, IAPILogoutMutationVariables>(
        LogoutDocument,
        options
    );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<IAPILogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
    IAPILogoutMutation,
    IAPILogoutMutationVariables
>;
