import { IDBEpic, IEpic, ETables, IAPIEpic } from '@ab-task/types';
import { normalize, ID2GUID, GUID2ID } from './core';

export function epicDB2JS(dbEpic: IDBEpic): IEpic {
    return {
        id: dbEpic.e_id,
        workspaceId: dbEpic.workspaceId,
        projectId: dbEpic.e_project_id,
        milestoneId: normalize(dbEpic.e_milestone_id),
        name: dbEpic.e_name,
        createdAt: dbEpic.e_created_at,
        updatedAt: dbEpic.e_updated_at,
    };
}

export function epicDB2API(dbEpic: IDBEpic): IAPIEpic {
    return {
        __typename: 'Epic',
        id: ID2GUID(ETables.epics, dbEpic.e_id),
        workspaceId: ID2GUID(ETables.workspaces, dbEpic.workspaceId),
        projectId: ID2GUID(ETables.projects, dbEpic.e_project_id),
        milestoneId: dbEpic.e_milestone_id ? ID2GUID(ETables.epics, dbEpic.e_milestone_id) : null,
        name: dbEpic.e_name,
        createdAt: dbEpic.e_created_at.toISOString(),
        updatedAt: dbEpic.e_updated_at.toISOString(),
    };
}

export function epicAPI2JS(apiEpic: IAPIEpic): IEpic {
    return {
        id: GUID2ID(apiEpic.id)[1],
        workspaceId: GUID2ID(apiEpic.workspaceId)[1],
        projectId: GUID2ID(apiEpic.projectId)[1],
        milestoneId: apiEpic.milestoneId ? GUID2ID(apiEpic.milestoneId)[1] : undefined,
        name: apiEpic.name,
        createdAt: new Date(apiEpic.createdAt),
        updatedAt: new Date(apiEpic.updatedAt),
    };
}
