import { BackendError } from '@ab-task/errors';

// parses string with numbers separated with commas
export function parseNumbersString(s: string) {
    const data = s.split(',');

    return data.map((s, i) => {
        const number = Number(s);

        if (isNaN(number)) {
            throw BackendError.PARSING({ info: `data[${i}] was not a number` });
        }

        return number;
    });
}

export const parseAttachmentS3key = (s3Key: string) => {
    const regexp =
        /^([1-9][0-9]*)\/message_attachments\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/;
    const match = s3Key.match(regexp);
    if (!match) {
        return null;
    }

    return {
        workspaceId: parseInt(match[1]),
        attachmentGuid: match[2],
    };
};
