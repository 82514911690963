import { ETables, TID } from '@ab-task/types';
import { rvExpandedDashboardIds } from './variables';
import { getModelGUID } from '@ab-task/utils';

export function toggleDashboardExpandedField(dashboardId: TID) {
    const guid = getModelGUID(dashboardId, ETables.dashboards);
    const prevExpandedDashboardIds = rvExpandedDashboardIds();

    if (prevExpandedDashboardIds.includes(guid)) {
        rvExpandedDashboardIds(prevExpandedDashboardIds.filter(id => id !== guid));
    } else {
        rvExpandedDashboardIds([...prevExpandedDashboardIds, guid]);
    }
}
