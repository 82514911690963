import { TMarks } from '@ab-task/types';

export const marks: TMarks = {
    link: {
        attrs: {
            href: {},
            title: { default: null },
        },
        inclusive: false,
        parseDOM: [
            {
                tag: 'a[href]',
                getAttrs(dom) {
                    return {
                        href: (dom as HTMLElement).getAttribute('href'),
                        title: (dom as HTMLElement).getAttribute('title'),
                    };
                },
            },
        ],
        toDOM(node) {
            let { href, title } = node.attrs;
            return ['a', { href, title, class: 'link' }, 0];
        },
    },

    em: {
        parseDOM: [{ tag: 'i' }, { tag: 'em' }, { style: 'font-style=italic' }],
        toDOM() {
            return ['em', 0];
        },
    },

    strong: {
        parseDOM: [
            { tag: 'strong' },
            // This works around a Google Docs misbehavior where
            // pasted content will be inexplicably wrapped in `<b>`
            // tags with a font-weight normal.
            {
                tag: 'b',
                getAttrs: node => (node as HTMLElement).style.fontWeight !== 'normal' && null,
            },
            {
                style: 'font-weight',
                getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value as string) && null,
            },
        ],
        toDOM() {
            return ['strong', 0];
        },
    },

    code: {
        parseDOM: [{ tag: 'code' }],
        toDOM() {
            return ['code', 0];
        },
    },
};
