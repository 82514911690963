import { IWorkspaceContext } from '@ab-task/types';
import { rvWorkspaceContexts } from './variables';
import { ensureAndGetCurrentWorkspaceId } from './helpers';

export function updateCurrentWorkspaceContext(patch: Partial<IWorkspaceContext>, soft?: boolean) {
    const currentWorkspaceId = ensureAndGetCurrentWorkspaceId();
    const prevWorkspaceContexts = rvWorkspaceContexts();
    const prevContext = prevWorkspaceContexts[currentWorkspaceId];

    if (soft && prevContext) return;

    rvWorkspaceContexts({
        ...prevWorkspaceContexts,
        [currentWorkspaceId]: { ...prevContext, ...patch },
    });
}
