import { ApolloLink, Observable } from '@apollo/client';
import { TApolloClientConfig } from '../types';
import { getMainDefinition } from '@apollo/client/utilities';
import rootLogger from '../utils/logger';
import { rvConnectionState, rvSubscriptionsState } from '../cache/reactive-variables';
import { EConnectionState, ESubscriptionsState } from '@ab-task/types';
import { BYPASSED_MUTATIONS_LIST } from '@ab-task/data';

/*
    Run this line in browser dev console and reload the page to enable logging
    localStorage.setItem('debug', 'apollo:mutations-controller-link');
*/
const logger = rootLogger.extend('mutations-controller-link');

const SUBSCRIPTIONS_STATE_CHECK_INTERVAL = 100; // ms

/**
 * Pending mutations limit
 * If more mutations come, app is switched into offline state
 */
const MUTATIONS_QUEUE_LENGTH_LIMIT = 6;

let mutationsQueueLength = 0;

/**
 * This link ensures mutations are deferred until SubscriptionsState becomes READY
 * Its logic only works in the browser environment
 */
export function getMutationsControllerLink(config: TApolloClientConfig) {
    return new ApolloLink((operation, forward) => {
        const mainDefinition = getMainDefinition(operation.query);
        logger(`Got operation`, mainDefinition);

        if (
            config.environment === 'browser' &&
            mainDefinition.kind === 'OperationDefinition' &&
            mainDefinition.operation === 'mutation' &&
            typeof mainDefinition.name?.value === 'string' &&
            !BYPASSED_MUTATIONS_LIST.includes(mainDefinition.name?.value) &&
            !getIsSubscriptionsReady()
        ) {
            if (rvConnectionState() === EConnectionState.Offline) {
                logger(
                    `Mutation aborted. Queue length: ${mutationsQueueLength}. Subscriptions state: "${rvSubscriptionsState()}"`
                );
                return new Observable(observer => {
                    observer.error(new Error('Action interrupted: You seem to be offline.'));
                });
            }

            updateMutationsQueueLength(1);
            console.warn(
                `Put mutation in queue. Queue length: ${mutationsQueueLength}. Subscriptions state: "${rvSubscriptionsState()}"`
            );

            return new Observable(observer => {
                const checkStateAndForwardOperation = () => {
                    if (getIsSubscriptionsReady()) {
                        updateMutationsQueueLength(-1);
                        logger(`Forward mutation. Queue length is ${mutationsQueueLength}`);

                        forward(operation).subscribe({
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        });
                    } else {
                        setTimeout(
                            checkStateAndForwardOperation,
                            SUBSCRIPTIONS_STATE_CHECK_INTERVAL
                        );
                    }
                };

                logger(`Start the check-and-forward loop`);

                checkStateAndForwardOperation();
            });
        }

        return forward(operation);
    });
}

// utils
function getIsSubscriptionsReady() {
    const subscriptionState = rvSubscriptionsState();

    return (
        subscriptionState === ESubscriptionsState.Ready ||
        subscriptionState === ESubscriptionsState.Inactive
    );
}

function updateMutationsQueueLength(delta: number) {
    mutationsQueueLength += delta;

    const connectionState: EConnectionState =
        mutationsQueueLength < MUTATIONS_QUEUE_LENGTH_LIMIT
            ? EConnectionState.Online
            : EConnectionState.Offline;

    rvConnectionState(connectionState);
}
