import { getElement, getElements } from './load';

enum MenuState {
    OPENING = 'OPENING',
    OPEN = 'OPEN',
    CLOSING = 'CLOSING',
    CLOSED = 'CLOSED',
}

export class Menu {
    private state: MenuState = MenuState.CLOSED;
    private menu: Element;
    private menuButton: Element;
    private transition: NodeJS.Timeout | null = null;

    private removeTransition() {
        if (this.transition) {
            clearTimeout(this.transition);
            this.transition = null;
        }
    }

    private setTransition(action: () => void, timeout: number) {
        this.removeTransition();

        this.transition = setTimeout(() => {
            action();
            this.removeTransition();
        }, timeout);
    }

    private setState(state: MenuState) {
        this.state = state;
        if (state === MenuState.OPENING) {
            this.menu.classList.add('Opening');
            this.menu.classList.remove('Open');
            this.menu.classList.remove('Closing');
            this.menu.classList.remove('Closed');
        } else if (state === MenuState.OPEN) {
            this.menu.classList.remove('Opening');
            this.menu.classList.add('Open');
            this.menu.classList.remove('Closing');
            this.menu.classList.remove('Closed');
        } else if (state === MenuState.CLOSING) {
            this.menu.classList.remove('Opening');
            this.menu.classList.remove('Open');
            this.menu.classList.add('Closing');
            this.menu.classList.remove('Closed');
        } else if (state === MenuState.CLOSED) {
            this.menu.classList.remove('Opening');
            this.menu.classList.remove('Open');
            this.menu.classList.remove('Closing');
            this.menu.classList.add('Closed');
        }
    }

    private clickHandler() {
        console.log('state = ', this.state);
        if (this.state === MenuState.OPENING) {
            // This state lasts for a very short moment,
            // so we ignore click here.
        } else if (this.state === MenuState.OPEN) {
            this.setState(MenuState.CLOSING);

            this.setTransition(() => {
                this.setState(MenuState.CLOSED);
            }, 200);
        } else if (this.state === MenuState.CLOSING) {
            this.removeTransition();
            this.setState(MenuState.OPEN);
        } else if (this.state === MenuState.CLOSED) {
            this.setState(MenuState.OPENING);

            this.setTransition(() => {
                this.setState(MenuState.OPEN);
            }, 1);
        }
    }

    public constructor(
        menuSelector: string,
        menuButtonSelector: string,
        menuLinksSelector: string
    ) {
        this.menu = getElement(menuSelector);
        this.menuButton = getElement(menuButtonSelector);

        this.setState(MenuState.CLOSED);

        this.menuButton.addEventListener('click', this.clickHandler.bind(this));
        /*
        TODO: close menu when it's hidden because screen is wide.
        window.addEventListener('resize', () => {
        });
        */

        const links = getElements(menuLinksSelector);
        links.forEach(link => {
            link.addEventListener('click', () => {
                this.setState(MenuState.CLOSING);

                this.setTransition(() => {
                    this.setState(MenuState.CLOSED);
                }, 200);
            });
        });
    }
}
