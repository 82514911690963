export type TAction<T = string, P = undefined> = P extends undefined
    ? {
          type: T;
      }
    : {
          type: T;
          payload: P;
      };

export type TStatusIcon = 'default' | 'unreads' | 'direct-unreads';
