export enum ELSKeys {
    AppToken = 'app-token',
    ForcedLogout = 'forced-logout',
    LsSchemaVersion = 'ls-schema-version',
    CurrentWorkspaceId = 'current-workspace-id',
    WorkspaceContexts = 'workspace-contexts',
    ExpandedDashboardIds = 'expanded-dashboard-ids',
    TopicsDashboardFilter = 'topics-dashboard-filter',
    TopicsDashboardSort = 'topics-dashboard-sort',
    TopicsDashboardPage = 'topics-dashboard-page',
    TopicsDashboardId = 'topics-dashboard-id',
    DocumentsDashboardFilter = 'documents-dashboard-filter',
    DocumentsDashboardSort = 'documents-dashboard-sort',
    DocumentsDashboardPage = 'documents-dashboard-page',
    DocumentsDashboardId = 'documents-dashboard-id',
    ProjectsDashboardFilter = 'projects-dashboard-filter',
    ProjectsDashboardSort = 'projects-dashboard-sort',
    ProjectsDashboardPage = 'projects-dashboard-page',
    ProjectsDashboardId = 'projects-dashboard-id',
    ProjectsPage = 'projects-page',
    TasksPage = 'tasks-page',
    UsersDashboardFilter = 'users-dashboard-filter',
    UsersDashboardSort = 'users-dashboard-sort',
    UsersDashboardPage = 'users-dashboard-page',
    OpenedPaths = 'opened-paths',
}
