import { Schema } from 'prosemirror-model';
import { nodes } from './nodes';
import { marks } from './marks';
import { TMarkName, TNodeName } from '@ab-task/types';

const schema = new Schema<TNodeName, TMarkName>({
    nodes,
    marks,
});

export default schema;
