import { getElement } from './load';

async function delay(seconds: number) {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

class Loop<T> {
    private values: T[];
    private i: number; // Index of current value

    public constructor(values: T[]) {
        this.values = values;
        this.i = 0;
    }

    public getNext(): T {
        const value = this.values[this.i];
        this.i++;
        if (this.i >= this.values.length) {
            this.i = 0;
        }
        return value;
    }
}

export function runEditor() {
    const editableText = getElement('.Editable .Text') as HTMLElement;
    const cursor = getElement('.Editable .Cursor') as HTMLElement;
    const user = getElement('.Editable .User') as HTMLElement;
    const selection = getElement('.Editable .Selection') as HTMLElement;

    const animationDuration = 0.05; // Length of CSS transition in seconds
    const usernames = new Loop(['John', 'Jane', 'Bill']);
    const words = new Loop(['Talk', 'Document', 'Task']);

    async function startSelection(username: string) {
        user.innerText = username;

        [selection, user, cursor].forEach(el => {
            el.classList.remove('Hidden');
            el.classList.add('Visible');
        });

        await delay(animationDuration);
    }

    async function stopSelection() {
        [selection, user, cursor].forEach(el => {
            el.classList.add('Hidden');
            el.classList.remove('Visible');
        });
        await delay(animationDuration);
    }

    async function selectText(invertDirection: boolean, username: string, selectDuration: number) {
        selection.style.left = selection.style.right = invertDirection ? '100%' : '0%';
        if (invertDirection) {
            selection.style.left = '100%';
            selection.style.right = '0%';
            cursor.style.left = '100%';
            user.style.left = '100%';
        } else {
            selection.style.left = '0%';
            selection.style.right = '100%';
            cursor.style.left = '0%';
            user.style.left = '0%';
        }

        await delay(animationDuration);
        await startSelection(username);

        const step = animationDuration / selectDuration;
        for (let i = 0.0; ; i = Math.min(i + step, 1)) {
            const j = invertDirection ? 1 - i : i;

            if (invertDirection) {
                selection.style.left = `${j * 100}%`;
            } else {
                selection.style.right = `${(1 - j) * 100}%`;
            }
            cursor.style.left = `${j * 100}%`;
            user.style.left = `${j * 100}%`;
            await delay(animationDuration);
            if (i === 1) {
                break;
            }
        }
    }

    async function removeWord() {
        editableText.innerText = '';
        selection.classList.add('Hidden');
        selection.classList.remove('Visible');
    }

    async function typeWord(word: string, minDelay: number, maxDelay: number) {
        user.style.left = cursor.style.left = '100%';
        await delay(animationDuration);

        for (const letter of word) {
            editableText.innerText += letter;
            await delay(Math.random() * (maxDelay - minDelay) + minDelay);
        }
    }

    (async () => {
        while (true) {
            await delay(1.5);
            await selectText(Math.random() > 0.5, usernames.getNext(), 0.5);
            await delay(0.2);
            await removeWord();
            await delay(0.1);
            await typeWord(words.getNext(), 0.1, 0.3);
            await delay(0.5);
            await stopSelection();
        }
    })();
}
