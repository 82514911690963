export default function addErrorCode(target: any, name: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;
    descriptor.value = function () {
        const error = method(...arguments);
        if (!error.params) {
            error.params = {};
        }
        error.params.errorCode = name;
        return error;
    };
}
