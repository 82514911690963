import { TBackendErrorCode } from '@ab-task/errors';

export const IGNORED_ERRORS_LIST: TBackendErrorCode[] = [];

export const BYPASSED_ERRORS_LIST: TBackendErrorCode[] = [
    'USER_EXISTS',
    'WRONG_PASSWORD',
    'USER_BANNED',
    'USER_NOT_FOUND',
    'USER_NO_PASSWORD',
    'STEPS_REJECTED',
];

export const BYPASSED_MUTATIONS_LIST = ['RemoveWorkspace'];
