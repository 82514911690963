/**
 * Finds element with document.querySelector or throws.
 */
export function getElement(selector: string, parentElement?: Element | null) {
    const element = (parentElement || document).querySelector(selector);

    if (!element) {
        throw new Error(selector + ' not found!');
    }

    return element;
}

/**
 * Finds all elements with document.querySelectorAll or throws.
 */
export function getElements(selector: string, parentElement?: Element | null) {
    const elements = (parentElement || document).querySelectorAll(selector);

    if (elements.length === 0) {
        throw new Error(selector + ' not found!');
    }

    return elements;
}
