import { NOTIFICATION_LIFESPAN_MS } from '@ab-task/data';
import rootLogger from '../logger';
import { rvNotifications } from './variables';

const logger = rootLogger.extend('purge-stale-notifications');

export function purgeStaleNotifications() {
    const prevNotifications = rvNotifications();

    logger({ prevNotifications });

    if (prevNotifications.length > 0) {
        const newNotifications = prevNotifications.filter(
            ({ createdAt }) => Date.now() - createdAt < NOTIFICATION_LIFESPAN_MS
        );

        logger({ newNotifications });

        rvNotifications(newNotifications);
    }
}
