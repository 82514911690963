import { setContext } from '@apollo/client/link/context';
import { TApolloClientConfig } from '../types';
import { WORKSPACE_ID_HEADER } from '@ab-task/data';

export function getWorkspaceIdLink(config: TApolloClientConfig) {
    switch (config.environment) {
        case 'node': {
            const workspaceId = config.workspaceId;

            return setContext((request, { headers }) => {
                return {
                    headers: {
                        ...headers,
                        [WORKSPACE_ID_HEADER]: workspaceId,
                    },
                };
            });
        }

        case 'browser': {
            return setContext((request, { headers }) => {
                const workspaceId = localStorage.getItem('current-workspace-id');

                if (workspaceId !== null) {
                    return {
                        headers: {
                            ...headers,
                            [WORKSPACE_ID_HEADER]: workspaceId,
                        },
                    };
                }

                return {};
            });
        }
    }
}
