import { IDBDocument, IDocument, ETables, IAPIDocument, IAPIDocumentInput } from '@ab-task/types';
import { ID2GUID, ifValue, GUID2ID, ifValueNormalized, normalize } from './core';

export function documentDB2JS(dbDocument: IDBDocument): IDocument {
    return {
        id: dbDocument.d_id,
        workspaceId: dbDocument.d_workspace_id,
        name: dbDocument.d_name,
        createdAt: dbDocument.d_created_at,
        updatedAt: dbDocument.d_updated_at,
        messagedAt: normalize(dbDocument.d_messaged_at),
        unreads: dbDocument.d_unreads,
        hasMentions: dbDocument.d_has_mentions,
    };
}

export function documentDB2API(dbDocument: IDBDocument): IAPIDocument {
    return {
        __typename: 'Document',
        id: ID2GUID(ETables.documents, dbDocument.d_id),
        workspaceId: ID2GUID(ETables.workspaces, dbDocument.d_workspace_id),
        projectId: ifValue(dbDocument.d_project_id, v => ID2GUID(ETables.projects, v)),
        name: dbDocument.d_name,
        createdAt: dbDocument.d_created_at.toISOString(),
        updatedAt: dbDocument.d_updated_at.toISOString(),
        messagedAt: dbDocument.d_messaged_at ? dbDocument.d_messaged_at.toISOString() : null,
        unreads: dbDocument.d_unreads,
        hasMentions: dbDocument.d_has_mentions,
    };
}

export function documentAPI2JS(apiDocument: IAPIDocument): IDocument {
    return {
        id: GUID2ID(apiDocument.id)[1],
        workspaceId: GUID2ID(apiDocument.workspaceId)[1],
        projectId: ifValueNormalized(apiDocument.projectId, v => (v ? GUID2ID(v)[1] : undefined)),
        name: apiDocument.name,
        createdAt: new Date(apiDocument.createdAt),
        updatedAt: new Date(apiDocument.updatedAt),
        messagedAt: apiDocument.messagedAt ? new Date(apiDocument.messagedAt) : undefined,
        unreads: apiDocument.unreads,
        hasMentions: apiDocument.hasMentions,
    };
}

export function documentJS2APIInput(document: IDocument): IAPIDocumentInput {
    return {
        name: document.name,
        projectId: document.projectId ? ID2GUID(ETables.documents, document.projectId) : undefined,
    };
}
