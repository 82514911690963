import { setContext } from '@apollo/client/link/context';
import { TApolloClientConfig } from '../types';
import { CLIENT_ID_HEADER } from '@ab-task/data';

export function getClientIdLink(config: TApolloClientConfig) {
    const { clientId } = config;

    return setContext((request, { headers }) => {
        return {
            headers: {
                ...headers,
                [CLIENT_ID_HEADER]: clientId,
            },
        };
    });
}
