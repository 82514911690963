/* eslint-disable */
import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { WorkspaceFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchWorkspaceQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchWorkspaceQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Emoji' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Task' }
        | { __typename: 'Topic' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | {
              __typename: 'Workspace';
              id: string;
              name: string;
              motto?: string | null;
              ownerId: string;
              createdAt: string;
              updatedAt: string;
          }
        | null;
};

export type IAPIFetchWorkspacesQueryVariables = Types.Exact<{
    filter: Types.IAPIWorkspacesFilter;
    withUnreadCounters: Types.Scalars['Boolean']['input'];
}>;

export type IAPIFetchWorkspacesQuery = {
    __typename: 'Query';
    workspaces: Array<{
        __typename: 'Workspace';
        id: string;
        name: string;
        motto?: string | null;
        ownerId: string;
        createdAt: string;
        updatedAt: string;
        unreadCounters?: Array<{
            __typename: 'UnreadCounter';
            type: Types.DashboardType;
            unreads: number;
            hasMentions: boolean;
        }> | null;
    }>;
};

export type IAPICreateWorkspaceMutationVariables = Types.Exact<{
    workspace: Types.IAPIWorkspaceInput;
}>;

export type IAPICreateWorkspaceMutation = {
    __typename: 'Mutation';
    updateWorkspace: {
        __typename: 'Workspace';
        id: string;
        name: string;
        motto?: string | null;
        ownerId: string;
        createdAt: string;
        updatedAt: string;
    };
};

export type IAPIRemoveWorkspaceMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveWorkspaceMutation = {
    __typename: 'Mutation';
    removeWorkspace: {
        __typename: 'Workspace';
        id: string;
        name: string;
        motto?: string | null;
        ownerId: string;
        createdAt: string;
        updatedAt: string;
    };
};

export type IAPIUpdateWorkspaceMutationVariables = Types.Exact<{
    workspace: Types.IAPIWorkspaceInput;
}>;

export type IAPIUpdateWorkspaceMutation = {
    __typename: 'Mutation';
    updateWorkspace: {
        __typename: 'Workspace';
        id: string;
        name: string;
        motto?: string | null;
        ownerId: string;
        createdAt: string;
        updatedAt: string;
    };
};

export type IAPIWorkspaceRemovedSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type IAPIWorkspaceRemovedSubscription = {
    __typename: 'Subscription';
    workspaceRemoved?: {
        __typename: 'Workspace';
        id: string;
        name: string;
        motto?: string | null;
        ownerId: string;
        createdAt: string;
        updatedAt: string;
    } | null;
};

export type IAPIWorkspaceUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type IAPIWorkspaceUpdatedSubscription = {
    __typename: 'Subscription';
    workspaceUpdated?: {
        __typename: 'Workspace';
        id: string;
        name: string;
        motto?: string | null;
        ownerId: string;
        createdAt: string;
        updatedAt: string;
    } | null;
};

export const FetchWorkspaceDocument = gql`
    query FetchWorkspace($id: ID!) {
        node(id: $id) {
            ...WorkspaceFields
        }
    }
    ${WorkspaceFieldsFragmentDoc}
`;

/**
 * __useFetchWorkspaceQuery__
 *
 * To run a query within a React component, call `useFetchWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchWorkspaceQuery(
    baseOptions: Apollo.QueryHookOptions<
        IAPIFetchWorkspaceQuery,
        IAPIFetchWorkspaceQueryVariables
    > &
        ({ variables: IAPIFetchWorkspaceQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchWorkspaceDocument, options);
    return Apollo.useQuery<IAPIFetchWorkspaceQuery, IAPIFetchWorkspaceQueryVariables>(
        FetchWorkspaceDocument,
        options
    );
}
export function useFetchWorkspaceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchWorkspaceQuery,
        IAPIFetchWorkspaceQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchWorkspaceQuery, IAPIFetchWorkspaceQueryVariables>(
        FetchWorkspaceDocument,
        options
    );
}
export function useFetchWorkspaceSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchWorkspaceQuery,
        IAPIFetchWorkspaceQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchWorkspaceQuery, IAPIFetchWorkspaceQueryVariables>(
        FetchWorkspaceDocument,
        options
    );
}
export type FetchWorkspaceQueryHookResult = ReturnType<typeof useFetchWorkspaceQuery>;
export type FetchWorkspaceLazyQueryHookResult = ReturnType<typeof useFetchWorkspaceLazyQuery>;
export type FetchWorkspaceSuspenseQueryHookResult = ReturnType<
    typeof useFetchWorkspaceSuspenseQuery
>;
export type FetchWorkspaceQueryResult = Apollo.QueryResult<
    IAPIFetchWorkspaceQuery,
    IAPIFetchWorkspaceQueryVariables
>;
export const FetchWorkspacesDocument = gql`
    query FetchWorkspaces($filter: WorkspacesFilter!, $withUnreadCounters: Boolean!) {
        workspaces(filter: $filter) {
            ...WorkspaceFields
            unreadCounters @include(if: $withUnreadCounters) {
                type
                unreads
                hasMentions
            }
        }
    }
    ${WorkspaceFieldsFragmentDoc}
`;

/**
 * __useFetchWorkspacesQuery__
 *
 * To run a query within a React component, call `useFetchWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWorkspacesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUnreadCounters: // value for 'withUnreadCounters'
 *   },
 * });
 */
export function useFetchWorkspacesQuery(
    baseOptions: Apollo.QueryHookOptions<
        IAPIFetchWorkspacesQuery,
        IAPIFetchWorkspacesQueryVariables
    > &
        ({ variables: IAPIFetchWorkspacesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchWorkspacesDocument, options);
    return Apollo.useQuery<IAPIFetchWorkspacesQuery, IAPIFetchWorkspacesQueryVariables>(
        FetchWorkspacesDocument,
        options
    );
}
export function useFetchWorkspacesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchWorkspacesQuery,
        IAPIFetchWorkspacesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchWorkspacesQuery, IAPIFetchWorkspacesQueryVariables>(
        FetchWorkspacesDocument,
        options
    );
}
export function useFetchWorkspacesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchWorkspacesQuery,
        IAPIFetchWorkspacesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchWorkspacesQuery, IAPIFetchWorkspacesQueryVariables>(
        FetchWorkspacesDocument,
        options
    );
}
export type FetchWorkspacesQueryHookResult = ReturnType<typeof useFetchWorkspacesQuery>;
export type FetchWorkspacesLazyQueryHookResult = ReturnType<typeof useFetchWorkspacesLazyQuery>;
export type FetchWorkspacesSuspenseQueryHookResult = ReturnType<
    typeof useFetchWorkspacesSuspenseQuery
>;
export type FetchWorkspacesQueryResult = Apollo.QueryResult<
    IAPIFetchWorkspacesQuery,
    IAPIFetchWorkspacesQueryVariables
>;
export const CreateWorkspaceDocument = gql`
    mutation CreateWorkspace($workspace: WorkspaceInput!) {
        updateWorkspace(workspace: $workspace) {
            ...WorkspaceFields
        }
    }
    ${WorkspaceFieldsFragmentDoc}
`;
export type IAPICreateWorkspaceMutationFn = Apollo.MutationFunction<
    IAPICreateWorkspaceMutation,
    IAPICreateWorkspaceMutationVariables
>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      workspace: // value for 'workspace'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateWorkspaceMutation,
        IAPICreateWorkspaceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateWorkspaceMutation, IAPICreateWorkspaceMutationVariables>(
        CreateWorkspaceDocument,
        options
    );
}
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<IAPICreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateWorkspaceMutation,
    IAPICreateWorkspaceMutationVariables
>;
export const RemoveWorkspaceDocument = gql`
    mutation RemoveWorkspace($id: ID!) {
        removeWorkspace(id: $id, action: DELETE) {
            ...WorkspaceFields
        }
    }
    ${WorkspaceFieldsFragmentDoc}
`;
export type IAPIRemoveWorkspaceMutationFn = Apollo.MutationFunction<
    IAPIRemoveWorkspaceMutation,
    IAPIRemoveWorkspaceMutationVariables
>;

/**
 * __useRemoveWorkspaceMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceMutation, { data, loading, error }] = useRemoveWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWorkspaceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveWorkspaceMutation,
        IAPIRemoveWorkspaceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveWorkspaceMutation, IAPIRemoveWorkspaceMutationVariables>(
        RemoveWorkspaceDocument,
        options
    );
}
export type RemoveWorkspaceMutationHookResult = ReturnType<typeof useRemoveWorkspaceMutation>;
export type RemoveWorkspaceMutationResult = Apollo.MutationResult<IAPIRemoveWorkspaceMutation>;
export type RemoveWorkspaceMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveWorkspaceMutation,
    IAPIRemoveWorkspaceMutationVariables
>;
export const UpdateWorkspaceDocument = gql`
    mutation UpdateWorkspace($workspace: WorkspaceInput!) {
        updateWorkspace(workspace: $workspace) {
            ...WorkspaceFields
        }
    }
    ${WorkspaceFieldsFragmentDoc}
`;
export type IAPIUpdateWorkspaceMutationFn = Apollo.MutationFunction<
    IAPIUpdateWorkspaceMutation,
    IAPIUpdateWorkspaceMutationVariables
>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      workspace: // value for 'workspace'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateWorkspaceMutation,
        IAPIUpdateWorkspaceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateWorkspaceMutation, IAPIUpdateWorkspaceMutationVariables>(
        UpdateWorkspaceDocument,
        options
    );
}
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<IAPIUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateWorkspaceMutation,
    IAPIUpdateWorkspaceMutationVariables
>;
export const WorkspaceRemovedDocument = gql`
    subscription WorkspaceRemoved {
        workspaceRemoved {
            ...WorkspaceFields
        }
    }
    ${WorkspaceFieldsFragmentDoc}
`;

/**
 * __useWorkspaceRemovedSubscription__
 *
 * To run a query within a React component, call `useWorkspaceRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceRemovedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceRemovedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<
        IAPIWorkspaceRemovedSubscription,
        IAPIWorkspaceRemovedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(WorkspaceRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIWorkspaceRemovedSubscription,
            IAPIWorkspaceRemovedSubscriptionVariables
        >(WorkspaceRemovedDocument, options),
        connected,
    };
}
export type WorkspaceRemovedSubscriptionHookResult = ReturnType<
    typeof useWorkspaceRemovedSubscription
>;
export type WorkspaceRemovedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIWorkspaceRemovedSubscription>;
export const WorkspaceUpdatedDocument = gql`
    subscription WorkspaceUpdated {
        workspaceUpdated {
            ...WorkspaceFields
        }
    }
    ${WorkspaceFieldsFragmentDoc}
`;

/**
 * __useWorkspaceUpdatedSubscription__
 *
 * To run a query within a React component, call `useWorkspaceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceUpdatedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<
        IAPIWorkspaceUpdatedSubscription,
        IAPIWorkspaceUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(WorkspaceUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIWorkspaceUpdatedSubscription,
            IAPIWorkspaceUpdatedSubscriptionVariables
        >(WorkspaceUpdatedDocument, options),
        connected,
    };
}
export type WorkspaceUpdatedSubscriptionHookResult = ReturnType<
    typeof useWorkspaceUpdatedSubscription
>;
export type WorkspaceUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIWorkspaceUpdatedSubscription>;
