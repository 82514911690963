import { v4 as uuid } from 'uuid';
import { INotification } from '@ab-task/types';
import { union } from 'lodash';
import rootLogger from '../logger';
import { rvNotifications } from './variables';

const logger = rootLogger.extend('push-notification');

export function pushNotification(payload: Omit<INotification, 'id' | 'createdAt'>) {
    const prevNotifications = rvNotifications();

    const newNotification: INotification = {
        id: uuid(),
        createdAt: Date.now(),
        ...payload,
    };

    let updated = false;
    let newNotifications: INotification[] = [];
    if (prevNotifications.length > 0) {
        newNotifications = prevNotifications.reduce<INotification[]>(
            (accumulator, notification, index, arr) => {
                if (
                    notification.type === newNotification.type &&
                    notification.title === newNotification.title
                ) {
                    updated = true;

                    const newDetails = union(
                        notification.details || [],
                        newNotification.details || []
                    );

                    accumulator.push({
                        ...notification,
                        createdAt: Date.now(),
                        details: newDetails.length > 0 ? newDetails : undefined,
                    });
                } else {
                    accumulator.push(notification);
                }

                if (index === arr.length - 1 && !updated) {
                    updated = true;
                    accumulator.push(newNotification);
                }

                return accumulator;
            },
            []
        );
    }

    if (!updated) {
        newNotifications.push(newNotification);
    }

    logger({ newNotification, prevNotifications, newNotifications });

    rvNotifications(newNotifications);
}
