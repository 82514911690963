import { IDBSession, ISession } from '@ab-task/types';

export function sessionDB2JS(dbSession: IDBSession): ISession {
    return {
        id: dbSession.s_id,
        userId: dbSession.s_user_id,
        secret: dbSession.s_secret,
        createdAt: dbSession.s_created_at,
    };
}
