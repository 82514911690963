/*
 * Original code lives here: https://github.com/awslabs/aws-mobile-appsync-sdk-js
 * Last time compared 2023-08-28
 */

import { ApolloLink } from '@apollo/client/core';
import { createHttpLink } from '@apollo/client/link/http';
import { getMainDefinition } from '@apollo/client/utilities';
import type { OperationDefinitionNode } from 'graphql';

import {
    AppSyncRealTimeSubscriptionHandshakeLink,
    CONTROL_EVENTS_KEY,
} from './realtime-subscription-handshake-link';
import { AppSyncRealTimeSubscriptionConfig } from './types';

function createSubscriptionHandshakeLink(
    infoOrUrl: AppSyncRealTimeSubscriptionConfig,
    theResultsFetcherLink?: ApolloLink
) {
    let resultsFetcherLink: ApolloLink, subscriptionLinks: ApolloLink;

    const { url } = infoOrUrl;
    resultsFetcherLink = theResultsFetcherLink || createHttpLink({ uri: url, fetch });
    subscriptionLinks = new AppSyncRealTimeSubscriptionHandshakeLink(infoOrUrl);

    return ApolloLink.split(
        operation => {
            const { query } = operation;
            const { kind, operation: graphqlOperation } = getMainDefinition(
                query
            ) as OperationDefinitionNode;
            const isSubscription =
                kind === 'OperationDefinition' && graphqlOperation === 'subscription';

            return isSubscription;
        },
        subscriptionLinks,
        resultsFetcherLink
    );
}

export { CONTROL_EVENTS_KEY, createSubscriptionHandshakeLink };
