import { TErrorCodes } from '@ab-task/types';
import addErrorCode from './addErrorCode';

export type TCoreErrorCode = TErrorCodes<typeof CoreError>;

export class CoreError extends Error {
    public params?: Record<string, any>;

    private constructor(message: string, params?: Record<string, any>) {
        super();

        this.name = 'CoreError';
        this.message = message;
        this.params = params;
    }

    @addErrorCode
    public static TYPE_ADAPTOR_FAILED(params?: Record<string, any>) {
        return new CoreError(`Can't convert types`, params);
    }

    @addErrorCode
    public static CONFIGURATION_ERROR(params?: Record<string, any>) {
        return new CoreError(`Core module is misconfigured`, params);
    }
}
