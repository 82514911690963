import { onError } from '@apollo/client/link/error';
import { TApolloClientConfig, TGQLError, TNetworkError } from '../types';
import { getOperationInfo } from '../utils/getOperationInfo';
import { IGNORED_ERRORS_LIST, BYPASSED_ERRORS_LIST } from '@ab-task/data';
import rootLogger from '../utils/logger';
import { ApolloLink, Operation } from '@apollo/client';
import { t } from '@ab-task/internationalization';
import { pushNotification } from '../cache/reactive-variables';
import { ENotificationType } from '@ab-task/types';

/*
    Run this line in browser dev console and reload the page to enable logging
    localStorage.setItem('debug', 'apollo:error-link');
*/

const logger = rootLogger.extend('error-link');

export function getErrorLink(config: TApolloClientConfig) {
    switch (config.environment) {
        case 'node': {
            // Dummy link
            return new ApolloLink((operation, forward) => forward(operation));
        }

        case 'browser': {
            return onError(errorResponse => {
                logger('onError called with errorResponse', errorResponse);
                const { operation } = errorResponse;

                {
                    const graphQLErrors = errorResponse.graphQLErrors as TGQLError[] | undefined;

                    if (graphQLErrors && graphQLErrors.length > 0) {
                        for (let gqlError of graphQLErrors) {
                            if (BYPASSED_ERRORS_LIST.includes(gqlError?.errorInfo?.errorCode))
                                continue;

                            gqlError.handled = true;

                            if (IGNORED_ERRORS_LIST.includes(gqlError?.errorInfo?.errorCode))
                                continue;

                            notifyAboutError(operation);
                            logger('[GraphQL Error]', gqlError);
                        }
                    }
                }

                {
                    const networkErrors = (errorResponse?.networkError as TNetworkError | undefined)
                        ?.errors;

                    if (networkErrors && networkErrors.length > 0) {
                        const info = getOperationInfo(operation.query);

                        for (let networkError of networkErrors) {
                            networkError.handled = true;

                            if (
                                info.operationType === 'subscription' &&
                                networkError.message === 'Connection closed'
                            ) {
                                /*
                                 * We don't want to report error for subscriptions because we'll re-mount subscriptions as soon as socket connection is re-established.
                                 * Our retry logic takes care of this and error notifications for the user are pointless.
                                 */

                                continue;
                            }

                            notifyAboutError(operation);
                            logger('[Network Error]', networkError);
                        }
                    }
                }
            });
        }
    }
}

// utils
function notifyAboutError(operation: Operation) {
    const info = getOperationInfo(operation.query);
    const { operationType, operationNames } = info;

    let action: string;
    switch (operationType) {
        case 'query':
            action = 'loading data';
            break;
        case 'mutation':
            action = 'updating data';
            break;
        case 'subscription':
            action = 'setting up live updates';
            break;
        default:
            action = 'processing your request';
    }

    pushNotification({
        type: ENotificationType.Error,
        title: t('error:action-failed', { action }),
        details: [t('error:operation-failed', { operation: operationNames.join(', ') })],
    });
}
