import * as React from 'react';
import { DocumentNode, SubscriptionHookOptions } from '@apollo/client';
import rootLogger from './logger';
import { getOperationInfo } from './getOperationInfo';

const logger = rootLogger.extend('track-subscriptions');

export function useTrackSubscription(
    subscription: DocumentNode,
    options: SubscriptionHookOptions<any, any>
) {
    const [connected, setConnected] = React.useState(false);

    options.context = {
        ...options.context,
        onConnected: () => setConnected(true),
    };

    {
        const originalOnComplete = options.onComplete;

        options.onComplete = () => {
            const info = getOperationInfo(subscription);
            logger(`Subscription completed: ${info.operationNames.join(', ')}`);

            setConnected(false);

            originalOnComplete?.();
        };
    }

    {
        const originalOnError = options.onError;

        options.onError = error => {
            const info = getOperationInfo(subscription);
            logger(`Subscription errored out: ${info.operationNames.join(', ')}`);

            setConnected(false);

            originalOnError?.(error);
        };
    }

    return connected;
}
