import { getApolloClient, GoogleSignInDocument } from '@ab-task/apollo';

import { v4 as uuid } from 'uuid';

export const CLIENT_ID = uuid();

//@ts-ignore
globalThis.signUpWithGoogle = async ({ credential }) => {
    const apolloClient = await getApolloClient({
        environment: 'browser',
        clientId: CLIENT_ID,
        region: process.env.REACT_APP_AWS_REGION!,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
        appSyncEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT!,
    });

    const {
        data: { googleSignIn: appToken },
    } = await apolloClient.mutate({
        mutation: GoogleSignInDocument,
        variables: { credential },
    });

    const encodedAppToken = encodeURIComponent(appToken);
    window.location.href = `${process.env.FRONTEND_URL!}?app-token=${encodedAppToken}`;
};

const script = document.createElement('script');
script.src = 'https://accounts.google.com/gsi/client';
script.async = true;

document.body.appendChild(script);
