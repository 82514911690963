import { ETables, IDBGroup, IGroup, IAPIGroup } from '@ab-task/types';
import { normalize, ID2GUID, GUID2ID } from './core';
import { creatorAlignmentsAPI2JS, creatorAlignmentsJS2API } from './creatorAlignments';

export function groupDB2JS(dbGroup: IDBGroup): IGroup {
    const memberIds = dbGroup.g_member_ids ? dbGroup.g_member_ids.split(',').map(Number) : [];

    return {
        id: dbGroup.g_id,
        workspaceId: dbGroup.g_workspace_id,
        name: dbGroup.g_name,
        description: normalize(dbGroup.g_description),
        memberIds,
        createdAt: dbGroup.g_created_at,
        updatedAt: dbGroup.g_updated_at,
        creatorAlignments: normalize(dbGroup.g_creator_alignments),
        messagedAt: normalize(dbGroup.g_messaged_at),
        unreads: dbGroup.g_unreads,
        hasMentions: dbGroup.g_has_mentions,
    };
}

export function groupDB2API(dbGroup: IDBGroup): IAPIGroup {
    const memberIds = dbGroup.g_member_ids
        ? dbGroup.g_member_ids.split(',').map(id => ID2GUID(ETables.users, Number(id)))
        : [];

    return {
        __typename: 'Group',
        id: ID2GUID(ETables.groups, dbGroup.g_id),
        workspaceId: ID2GUID(ETables.workspaces, dbGroup.g_workspace_id),
        name: dbGroup.g_name,
        description: dbGroup.g_description,
        memberIds,
        createdAt: dbGroup.g_created_at.toISOString(),
        updatedAt: dbGroup.g_updated_at.toISOString(),
        creatorAlignments: dbGroup.g_creator_alignments
            ? creatorAlignmentsJS2API(dbGroup.g_creator_alignments)
            : null,
        messagedAt: dbGroup.g_messaged_at ? dbGroup.g_messaged_at.toISOString() : null,
        unreads: dbGroup.g_unreads,
        hasMentions: dbGroup.g_has_mentions,
    };
}

export function groupAPI2JS(apiGroup: IAPIGroup): IGroup {
    const memberIds = apiGroup.memberIds ? apiGroup.memberIds.map(id => GUID2ID(id)[1]) : [];

    return {
        id: GUID2ID(apiGroup.id)[1],
        workspaceId: GUID2ID(apiGroup.workspaceId)[1],
        name: apiGroup.name,
        description: normalize(apiGroup.description),
        memberIds,
        createdAt: new Date(apiGroup.createdAt),
        updatedAt: new Date(apiGroup.updatedAt),
        creatorAlignments: apiGroup.creatorAlignments
            ? creatorAlignmentsAPI2JS(apiGroup.creatorAlignments)
            : undefined,
        messagedAt: apiGroup.messagedAt ? new Date(apiGroup.messagedAt) : undefined,
        unreads: apiGroup.unreads,
        hasMentions: apiGroup.hasMentions,
    };
}
