import {
    IDBWorkspace,
    IWorkspace,
    ETables,
    IAPIWorkspace,
    IAPIWorkspaceInput,
    IAPIUnreadCounter,
    TUnreadCounters,
} from '@ab-task/types';
import { ID2GUID, GUID2ID, normalize } from './core';

export function workspaceDB2JS(dbWorkspace: IDBWorkspace): IWorkspace {
    return {
        id: dbWorkspace.w_id,
        name: dbWorkspace.w_name || '',
        motto: normalize(dbWorkspace.w_motto),
        ownerId: dbWorkspace.w_owner_id,
        unreadCounters: unreadCountersARR2OBJ(dbWorkspace.w_unread_counters),
        createdAt: dbWorkspace.w_created_at,
        updatedAt: dbWorkspace.w_updated_at,
    };
}

export function workspaceDB2API(dbWorkspace: IDBWorkspace): IAPIWorkspace {
    return {
        __typename: 'Workspace',
        id: ID2GUID(ETables.workspaces, dbWorkspace.w_id),
        name: dbWorkspace.w_name || '',
        motto: dbWorkspace.w_motto,
        ownerId: ID2GUID(ETables.users, dbWorkspace.w_owner_id),
        unreadCounters: dbWorkspace.w_unread_counters?.map(({ type, unreads, hasMentions }) => ({
            __typename: 'UnreadCounter',
            type,
            unreads,
            hasMentions,
        })),
        createdAt: dbWorkspace.w_created_at.toISOString(),
        updatedAt: dbWorkspace.w_updated_at.toISOString(),
    };
}

export function workspaceAPI2JS(apiWorkspace: IAPIWorkspace): IWorkspace {
    return {
        id: GUID2ID(apiWorkspace.id)[1],
        name: apiWorkspace.name,
        motto: normalize(apiWorkspace.motto),
        ownerId: GUID2ID(apiWorkspace.ownerId)[1],
        unreadCounters: unreadCountersARR2OBJ(apiWorkspace.unreadCounters),
        createdAt: new Date(apiWorkspace.createdAt),
        updatedAt: new Date(apiWorkspace.updatedAt),
    };
}

export function workspaceJS2APIInput(workspace: IWorkspace): IAPIWorkspaceInput {
    return {
        id: ID2GUID('workspaces', workspace.id),
        name: workspace.name,
        motto: workspace.motto,
    };
}

export function unreadCountersJS2Flags(unreadCounters?: TUnreadCounters) {
    const hasUnreads = Boolean(unreadCounters?.total.unreads);

    const hasMentions =
        hasUnreads &&
        (Boolean(unreadCounters?.total.hasMentions) || Boolean(unreadCounters?.PEOPLE.unreads));

    return [hasUnreads, hasMentions];
}

// utils
function unreadCountersARR2OBJ(
    unreadCounters?: ReadonlyArray<Omit<IAPIUnreadCounter, '__typename'>> | null
): TUnreadCounters | undefined {
    if (!unreadCounters) return;

    const unreadCountersObj: Partial<TUnreadCounters> = {
        total: { unreads: 0, hasMentions: false },
    };

    for (const { type, unreads, hasMentions } of unreadCounters) {
        unreadCountersObj.total!.unreads += unreads;
        unreadCountersObj.total!.hasMentions = hasMentions;

        unreadCountersObj[type] = { unreads, hasMentions };
    }

    return unreadCountersObj as Required<TUnreadCounters>;
}
