import { ETables, IAPICreatorAlignments, ICreatorAlignments } from '@ab-task/types';
import { GUID2ID, ID2GUID } from './core';

export function creatorAlignmentsJS2API(
    creatorAlignments: ICreatorAlignments
): IAPICreatorAlignments {
    return {
        __typename: 'CreatorAlignments',
        left: creatorAlignments.left.map(userId => ID2GUID(ETables.users, userId)),
        right: creatorAlignments.right.map(userId => ID2GUID(ETables.users, userId)),
    };
}

export function creatorAlignmentsAPI2JS(
    apiCreatorAlignments: IAPICreatorAlignments
): ICreatorAlignments {
    return {
        left: apiCreatorAlignments.left.map(userGuid => GUID2ID(userGuid)[1]),
        right: apiCreatorAlignments.right.map(userGuid => GUID2ID(userGuid)[1]),
    };
}
