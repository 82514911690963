import { isError } from '@ab-task/utils';
import { ApolloError } from '@apollo/client';
import { TGQLError, TNetworkError } from '../types';

const isApolloError = (value: any): value is ApolloError =>
    isError(value) && value.name === 'ApolloError';

export const getWasErrorHandled = (error: any) => {
    if (!isApolloError(error)) return false;

    {
        const graphQLErrors = error.graphQLErrors as TGQLError[] | undefined;
        if (graphQLErrors?.find(error => !error.handled)) return false;
    }

    {
        const networkErrors = (error?.networkError as TNetworkError | undefined)?.errors;
        if (networkErrors?.find(error => !error.handled)) return false;
    }

    return true;
};
