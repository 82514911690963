/* eslint-disable */
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIBalanceQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IAPIBalanceQuery = { __typename: 'Query'; balance: number };

export const BalanceDocument = gql`
    query Balance {
        balance
    }
`;

/**
 * __useBalanceQuery__
 *
 * To run a query within a React component, call `useBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useBalanceQuery(
    baseOptions?: Apollo.QueryHookOptions<IAPIBalanceQuery, IAPIBalanceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(BalanceDocument, options);
    return Apollo.useQuery<IAPIBalanceQuery, IAPIBalanceQueryVariables>(BalanceDocument, options);
}
export function useBalanceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIBalanceQuery, IAPIBalanceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIBalanceQuery, IAPIBalanceQueryVariables>(
        BalanceDocument,
        options
    );
}
export function useBalanceSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIBalanceQuery, IAPIBalanceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIBalanceQuery, IAPIBalanceQueryVariables>(
        BalanceDocument,
        options
    );
}
export type BalanceQueryHookResult = ReturnType<typeof useBalanceQuery>;
export type BalanceLazyQueryHookResult = ReturnType<typeof useBalanceLazyQuery>;
export type BalanceSuspenseQueryHookResult = ReturnType<typeof useBalanceSuspenseQuery>;
export type BalanceQueryResult = Apollo.QueryResult<IAPIBalanceQuery, IAPIBalanceQueryVariables>;
