import {
    ETables,
    IDBBill,
    IBill,
    IAPIBill,
    IDBBillPayable,
    IBillPayable,
    IAPIBillPayable,
} from '@ab-task/types';
import { ID2GUID, GUID2ID, normalize } from './core';

/**
 * Note
 * We subtract 1 from month because SQL function MONTH() returns 1..12 as month number while
 * in JS months start from 0.
 *
 * We divide duration by 60 000 to convert milliseconds into minutes
 */

// DB -> JS
export function billDB2JS(dbBill: IDBBill): IBill {
    return {
        id: dbBill.b_id,
        workspaceId: dbBill.b_workspace_id,
        year: dbBill.b_year,
        month: dbBill.b_month - 1,
        duration: Math.floor(dbBill.b_duration / 60000),
        requestsCount: dbBill.b_requests_count,
        storageUsage: dbBill.b_storage_usage,
        storagePcplRequestsCount: dbBill.b_storage_pcpl_requests_count,
        storageOtherRequestsCount: dbBill.b_storage_other_requests_count,
        storageDataTransfer: dbBill.b_storage_data_transfer,
        total: dbBill.b_total,
        amountToCharge: normalize(dbBill.b_amount_to_charge),
    };
}

export function billPayableDB2JS(dbBillPayable: IDBBillPayable): IBillPayable {
    return {
        id: dbBillPayable.b_id,
        billIds: dbBillPayable.b_ids.split(',').map(Number),
        year: dbBillPayable.b_year,
        month: dbBillPayable.b_month - 1,
        duration: Math.floor(dbBillPayable.b_duration / 60000),
        requestsCount: dbBillPayable.b_requests_count,
        storageUsage: dbBillPayable.b_storage_usage,
        storagePcplRequestsCount: dbBillPayable.b_storage_pcpl_requests_count,
        storageOtherRequestsCount: dbBillPayable.b_storage_other_requests_count,
        storageDataTransfer: dbBillPayable.b_storage_data_transfer,
        total: dbBillPayable.b_total,
        amountToCharge: normalize(dbBillPayable.b_amount_to_charge),
    };
}

// DB -> API
export function billDB2API(dbBill: IDBBill): IAPIBill {
    return {
        __typename: 'Bill',
        id: ID2GUID(ETables.bills, dbBill.b_id),
        workspaceId: ID2GUID(ETables.workspaces, dbBill.b_workspace_id),
        year: dbBill.b_year,
        month: dbBill.b_month - 1,
        total: dbBill.b_total,
        duration: Math.floor(dbBill.b_duration / 60000),
        requestsCount: dbBill.b_requests_count,
        storageUsage: dbBill.b_storage_usage,
        storagePcplRequestsCount: dbBill.b_storage_pcpl_requests_count,
        storageOtherRequestsCount: dbBill.b_storage_other_requests_count,
        storageDataTransfer: dbBill.b_storage_data_transfer,
    };
}

export function billPayableDB2API(dbBillPayable: IDBBillPayable): IAPIBillPayable {
    return {
        __typename: 'BillPayable',
        id: ID2GUID(ETables.bills, dbBillPayable.b_id),
        billIds: dbBillPayable.b_ids.split(',').map(b_id => ID2GUID(ETables.bills, Number(b_id))),
        year: dbBillPayable.b_year,
        month: dbBillPayable.b_month - 1,
        total: dbBillPayable.b_total,
        duration: Math.floor(dbBillPayable.b_duration / 60000),
        requestsCount: dbBillPayable.b_requests_count,
        storageUsage: dbBillPayable.b_storage_usage,
        storagePcplRequestsCount: dbBillPayable.b_storage_pcpl_requests_count,
        storageOtherRequestsCount: dbBillPayable.b_storage_other_requests_count,
        storageDataTransfer: dbBillPayable.b_storage_data_transfer,
    };
}

// API -> JS
export function billAPI2JS(apiBill: IAPIBill): IBill {
    return {
        id: GUID2ID(apiBill.id)[1],
        workspaceId: GUID2ID(apiBill.workspaceId)[1],
        year: apiBill.year,
        month: apiBill.month,
        total: apiBill.total,
        duration: apiBill.duration,
        requestsCount: apiBill.requestsCount,
        storageUsage: apiBill.storageUsage,
        storagePcplRequestsCount: apiBill.storagePcplRequestsCount,
        storageOtherRequestsCount: apiBill.storageOtherRequestsCount,
        storageDataTransfer: apiBill.storageDataTransfer,
    };
}

export function billPayableAPI2JS(apiBillPayable: IAPIBillPayable): IBillPayable {
    return {
        id: GUID2ID(apiBillPayable.id)[1],
        billIds: apiBillPayable.billIds.map(id => GUID2ID(id)[1]),
        year: apiBillPayable.year,
        month: apiBillPayable.month,
        total: apiBillPayable.total,
        duration: apiBillPayable.duration,
        requestsCount: apiBillPayable.requestsCount,
        storageUsage: apiBillPayable.storageUsage,
        storagePcplRequestsCount: apiBillPayable.storagePcplRequestsCount,
        storageOtherRequestsCount: apiBillPayable.storageOtherRequestsCount,
        storageDataTransfer: apiBillPayable.storageDataTransfer,
    };
}
