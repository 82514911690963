import {
    IAPIEpicsFilter,
    IAPIGroupsFilter,
    IAPIProjectsFilter,
    IAPITasksFilter,
    IAPITopicsFilter,
    IAPIUsersFilter,
    IAPIWorkspacesFilter,
    IAPIIdFilter,
    IAPIStringFilter,
    IAPIIDsFilter,
    IAPINumberFilter,
} from '@ab-task/types';

export function countActiveValueFilters(filter?: IAPIEpicsFilter): number;
export function countActiveValueFilters(filter?: IAPIGroupsFilter): number;
export function countActiveValueFilters(filter?: IAPIProjectsFilter): number;
export function countActiveValueFilters(filter?: IAPITasksFilter): number;
export function countActiveValueFilters(filter?: IAPITopicsFilter): number;
export function countActiveValueFilters(filter?: IAPIUsersFilter): number;
export function countActiveValueFilters(filter?: IAPIWorkspacesFilter): number;

export function countActiveValueFilters(filter: any) {
    let n = 0;

    if (!filter) return n;

    const {
        creatorId,
        epicId,
        estimate,
        id,
        milestoneId,
        name,
        ownerId,
        priority,
        projectId,
        status,
        workspaceId,
        memberIds,
        email,
        unreads,
        displayName,
        invitedToWorkspaceIds,
        role,
        workspaceIds,
    } = filter;

    if (creatorId) {
        n += countIdValueFilters(creatorId);
    }
    if (epicId) {
        n += countIdValueFilters(epicId);
    }
    if (estimate) {
        n += countNumberValueFilters(estimate);
    }
    if (id) {
        n += countIdValueFilters(id);
    }
    if (milestoneId) {
        n += countIdValueFilters(milestoneId);
    }
    if (name) {
        n += countStringValueFilters(name);
    }
    if (ownerId) {
        n += countIdValueFilters(ownerId);
    }
    if (priority) {
        n += countNumberValueFilters(priority);
    }
    if (projectId) {
        n += countIdValueFilters(projectId);
    }
    if (status) {
        n += countStringValueFilters(status);
    }
    if (workspaceId) {
        n += countIdValueFilters(workspaceId);
    }
    if (memberIds) {
        n += countIdsValueFilters(memberIds);
    }
    if (email) {
        n += countStringValueFilters(email);
    }
    if (unreads) {
        n += countNumberValueFilters(unreads);
    }
    if (displayName) {
        n += countStringValueFilters(displayName);
    }
    if (invitedToWorkspaceIds) {
        n += countIdsValueFilters(invitedToWorkspaceIds);
    }
    if (role) {
        n += countStringValueFilters(role);
    }
    if (workspaceIds) {
        n += countIdsValueFilters(workspaceIds);
    }

    return n;
}

function countIdValueFilters(filter: IAPIIdFilter) {
    const { equal, exclude, include, isNull, notEqual } = filter;
    let n = 0;
    if (equal !== undefined) {
        n++;
    }
    if (exclude) {
        n += exclude.length;
    }
    if (include) {
        n += include.length;
    }
    if (isNull !== undefined) {
        n++;
    }
    if (notEqual !== undefined) {
        n++;
    }
    return n;
}

function countIdsValueFilters(filter: IAPIIDsFilter) {
    const { empty, includes, excludes, matchSet } = filter;
    let n = 0;
    if (empty !== undefined) {
        n++;
    }
    if (includes !== undefined) {
        n++;
    }
    if (excludes !== undefined) {
        n++;
    }
    if (matchSet) {
        n += matchSet.length;
    }
    return n;
}

function countStringValueFilters(filter: IAPIStringFilter) {
    const { equal, exclude, include, startsWith, isNull, like, notEqual } = filter;
    let n = 0;
    if (equal !== undefined) {
        n++;
    }
    if (exclude) {
        n += exclude.length;
    }
    if (include) {
        n += include.length;
    }

    if (startsWith !== undefined) {
        n++;
    }
    if (isNull !== undefined) {
        n++;
    }
    if (like !== undefined) {
        n++;
    }
    if (notEqual !== undefined) {
        n++;
    }
    return n;
}

function countNumberValueFilters(filter: IAPINumberFilter) {
    const { equal, exclude, include, isNull, lessThan, moreThan, notEqual } = filter;
    let n = 0;
    if (equal !== undefined) {
        n++;
    }
    if (exclude) {
        n += exclude.length;
    }
    if (include) {
        n += include.length;
    }
    if (isNull !== undefined) {
        n++;
    }
    if (lessThan !== undefined) {
        n++;
    }
    if (moreThan !== undefined) {
        n++;
    }
    if (notEqual !== undefined) {
        n++;
    }
    return n;
}
