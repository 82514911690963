import { Maybe, TAPISort } from '@ab-task/types';

export function stabilizeSort<T extends TAPISort>(sort?: Maybe<readonly T[]>): readonly T[] {
    if (!sort || sort.length < 1) {
        // TODO: fix TS error
        return [{ id: 'DESC' } as unknown as T];
    }

    const hasIdSort = sort.findIndex(({ id }) => id) > -1;

    if (hasIdSort) {
        return sort;
    }

    return [...sort, { id: 'DESC' } as unknown as T];
}
