import { KeySpecifier } from '@apollo/client/cache/inmemory/policies';
import { storeKeyNameFromField } from '@apollo/client/utilities';
import { FieldNode, OperationDefinitionNode, visit } from 'graphql';
import { DocumentNode, Kind } from 'graphql';
import { typePolicies } from '../cache/typePolicies';

export function getStoreKeys(query: DocumentNode, queryVariables?: Record<string, any>) {
    const storeKeys: string[] = [];

    visit(query, {
        OperationDefinition(node: OperationDefinitionNode) {
            if (node.operation === 'query') {
                visit(node, {
                    Field(fieldNode: FieldNode) {
                        const fieldName = fieldNode.name.value;
                        let fieldArguments: Record<string, any> = {};

                        fieldNode.arguments?.forEach(argument => {
                            const variableKey = argument.name.value;

                            switch (argument.value.kind) {
                                case Kind.VARIABLE: {
                                    /*
                                     * This Kind of VARIABLE corresponds to typical variables declaration for fields: exampleField(id: $id) {...}
                                     * So for this Kind we need to take value from queryVariables
                                     */

                                    const variableValue = queryVariables?.[variableKey];

                                    if (variableValue !== undefined) {
                                        fieldArguments[variableKey] = variableValue;
                                    }
                                    break;
                                }

                                case Kind.INT:
                                case Kind.FLOAT:
                                case Kind.STRING:
                                case Kind.BOOLEAN:
                                case Kind.ENUM: {
                                    /*
                                     * These types correspond to setting hard-coded value for a field like so exampleField(someVar: 5) {...}
                                     */

                                    fieldArguments[variableKey] = argument.value.value;
                                    break;
                                }

                                case Kind.NULL: {
                                    fieldArguments[variableKey] = null;
                                    break;
                                }

                                default: {
                                    console.warn(
                                        `Argument of type ${argument.value.kind} is not supported for store key generation`
                                    );
                                }
                            }
                        });

                        const typePolicy = typePolicies.Query?.fields?.[fieldName];

                        /*
                         * We need to take into account custom keyArgs, if there is one in the typePolicy. keyArgs affects how store cache key is calculated
                         */
                        let keyArgs: false | KeySpecifier | string | undefined;
                        if (typeof typePolicy !== 'function') {
                            if (typeof typePolicy?.keyArgs === 'function') {
                                keyArgs = typePolicy.keyArgs(
                                    Object.keys(fieldArguments).length > 0 ? fieldArguments : null,
                                    {
                                        typename: 'Query',
                                        fieldName,
                                        field: fieldNode,
                                        variables: queryVariables,
                                    }
                                );
                            } else {
                                keyArgs = typePolicy?.keyArgs;
                            }
                        }

                        let storeKey: string;
                        if (typeof keyArgs === 'string') {
                            storeKey = keyArgs;
                        } else if (Array.isArray(keyArgs)) {
                            storeKey = storeKeyNameFromField(
                                fieldNode,
                                extractKeyArgsValues(fieldArguments, keyArgs)
                            );
                        } else {
                            storeKey = storeKeyNameFromField(
                                fieldNode,
                                keyArgs === false ? undefined : fieldArguments
                            );
                        }

                        storeKeys.push(storeKey);

                        // Return false to stop visitor at the current level
                        return false;
                    },
                });
            }
        },
    });

    return storeKeys;
}

// utils
function extractKeyArgsValues(fieldArguments: Record<string, any>, keyArgs: KeySpecifier) {
    const result: Record<string, any> = {};

    for (let i = 0; i < keyArgs.length; i++) {
        const key = keyArgs[i];

        if (typeof key === 'string') {
            if (fieldArguments[key] !== undefined) {
                result[key] = fieldArguments[key];
            }
        } else {
            const parentKey = keyArgs[i - 1];

            if (typeof parentKey === 'string' && fieldArguments[parentKey] !== undefined) {
                result[parentKey] = extractKeyArgsValues(fieldArguments[parentKey], key);
            }
        }
    }

    return Object.keys(result).length > 0 ? result : undefined;
}
