import {
    Joiner,
    SortDirection,
    IAPITasksSort,
    IAPITopicsSort,
    IAPIDocumentsSort,
    IAPIProjectsSort,
    IAPIUsersSort,
    IAPITasksFilter,
    IAPITopicsFilter,
    IAPIDocumentsFilter,
    IAPIProjectsFilter,
    IAPIIdFilter,
    IAPIStringFilter,
    IAPIUsersFilter,
    IAPIIDsFilter,
    IAPINumberFilter,
} from '@ab-task/types';
import { isObject, isArray, clone, isBoolean, isString, every, isInteger } from 'lodash';

export function isSortDirection(data: any): data is SortDirection {
    return data === 'ASC' || data === 'DESC';
}

type TCheckers = Record<string, (x: any) => boolean>;

// Helper function for isIAPI<Model>Sort.
function isSort(data: any, checkers: TCheckers) {
    if (!isArray(data)) {
        return false;
    }

    // Each option must be present 0-1 times in sort array.
    // 'Checks' object contains validators for each sort option,
    // after option is checked, it's removed from this object
    // to make sure it's not present second time in the array
    const availableCheckers = clone(checkers);

    for (const sort of data) {
        // Sort option must be an object
        if (!isObject(sort)) {
            return false;
        }

        // Sort option must contain exactly one subobject
        const entries = Object.entries(sort);
        if (entries.length !== 1) {
            return false;
        }

        const [key, value] = entries[0];

        // If we don't have validator for this key,
        // than this key either was not expected to be in sort option
        // or was present before and not expected to be here again
        const checker = availableCheckers[key];
        if (!checker) {
            return false;
        }

        if (!checker(value)) {
            return false;
        }

        delete availableCheckers[key];
    }

    return true;
}

export function isIAPITasksSort(data: any): data is IAPITasksSort {
    const checkers: TCheckers = {
        id: isSortDirection,
        name: isSortDirection,
        status: isSortDirection,
        createdAt: isSortDirection,
        updatedAt: isSortDirection,
        unreads: isSortDirection,
        ownerDisplayName: isSortDirection,
        estimate: isSortDirection,
        messagedAt: isSortDirection,
        priority: isSortDirection,
    };

    return isSort(data, checkers);
}

export function isIAPITopicsSort(data: any): data is IAPITopicsSort {
    const checkers: TCheckers = {
        id: isSortDirection,
        name: isSortDirection,
        createdAt: isSortDirection,
        updatedAt: isSortDirection,
        unreads: isSortDirection,
        messagedAt: isSortDirection,
    };

    return isSort(data, checkers);
}

export function isIAPIDocumentsSort(data: any): data is IAPIDocumentsSort {
    const checkers: TCheckers = {
        id: isSortDirection,
        name: isSortDirection,
        createdAt: isSortDirection,
        updatedAt: isSortDirection,
        unreads: isSortDirection,
        messagedAt: isSortDirection,
    };

    return isSort(data, checkers);
}

export function isIAPIProjectsSort(data: any): data is IAPIProjectsSort {
    const checkers: TCheckers = {
        id: isSortDirection,
        workspaceId: isSortDirection,
        name: isSortDirection,
        createdAt: isSortDirection,
        updatedAt: isSortDirection,
        messagedAt: isSortDirection,
        unreads: isSortDirection,
    };

    return isSort(data, checkers);
}

export function isIAPIUsersSort(data: any): data is IAPIUsersSort {
    const checkers: TCheckers = {
        id: isSortDirection,
        email: isSortDirection,
        firstname: isSortDirection,
        lastname: isSortDirection,
        displayName: isSortDirection,
        timezone: isSortDirection,
        status: isSortDirection,
        createdAt: isSortDirection,
        updatedAt: isSortDirection,
        calculatedName: isSortDirection,
    };

    return isSort(data, checkers);
}

// Helper function for isIAPI<Model>Filter.
function isFilter(data: any, checkers: TCheckers) {
    // Mostly does what isSort does, but data is object
    if (!isObject(data)) {
        return false;
    }

    for (const [key, value] of Object.entries(data)) {
        const checker = checkers[key];
        if (!checker) {
            return false;
        }

        if (!checker(value)) {
            return false;
        }
    }

    return true;
}

export function isIAPITasksFilter(data: any): data is IAPITasksFilter {
    const checkers = {
        joiner: isJoiner,
        id: isIAPIIdFilter,
        workspaceId: isIAPIIdFilter,
        projectId: isIAPIIdFilter,
        milestoneId: isIAPIIdFilter,
        epicId: isIAPIIdFilter,
        name: isIAPIStringFilter,
        status: isIAPIStringFilter,
        creatorId: isIAPIIdFilter,
        ownerId: isIAPIIdFilter,
        priority: isIAPIStringFilter,
        unreads: isIAPINumberFilter,
    };

    return isFilter(data, checkers);
}

export function isIAPITopicsFilter(data: any): data is IAPITopicsFilter {
    const checkers = {
        joiner: isJoiner,
        id: isIAPIIdFilter,
        workspaceId: isIAPIIdFilter,
        projectId: isIAPIIdFilter,
        name: isIAPIStringFilter,
        unreads: isIAPINumberFilter,
    };

    return isFilter(data, checkers);
}

export function isIAPIDocumentsFilter(data: any): data is IAPIDocumentsFilter {
    const checkers = {
        joiner: isJoiner,
        id: isIAPIIdFilter,
        workspaceId: isIAPIIdFilter,
        projectId: isIAPIIdFilter,
        name: isIAPIStringFilter,
        unreads: isIAPINumberFilter,
    };

    return isFilter(data, checkers);
}

export function isIAPIProjectsFilter(data: any): data is IAPIProjectsFilter {
    const checkers = {
        joiner: isJoiner,
        id: isIAPIIdFilter,
        workspaceId: isIAPIIdFilter,
        name: isIAPIStringFilter,
        status: isIAPIStringFilter,
    };

    return isFilter(data, checkers);
}

export function isIAPIUsersFilter(data: any): data is IAPIUsersFilter {
    const checkers = {
        joiner: isJoiner,
        id: isIAPIIdFilter,
        workspaceIds: isIAPIIdsFilter,
        invitedToWorkspaceIds: isIAPIIdsFilter,
        email: isIAPIStringFilter,
        displayName: isIAPIStringFilter,
        role: isIAPIStringFilter,
    };

    return isFilter(data, checkers);
}

export function isJoiner(data: any): data is Joiner {
    return data === 'AND' || data === 'OR';
}

function isIdsArray(data: any) {
    if (!isArray(data)) {
        return false;
    }

    for (const element of data) {
        // TODO: add more precise validation for ID
        if (typeof element !== 'string') {
            return false;
        }
    }

    return true;
}

function isIntegerArray(data: any) {
    if (!isArray(data)) {
        return false;
    }

    for (const element of data) {
        if (!isInteger(element)) {
            return false;
        }
    }

    return true;
}

function isStringArray(data: any) {
    return every(data, isString);
}

export function isIAPIIdFilter(data: any): data is IAPIIdFilter {
    const checkers = {
        joiner: isJoiner,
        isNull: isBoolean,
        equal: isString,
        notEqual: isString,
        include: isIdsArray,
        exclude: isIdsArray,
    };

    return isFilter(data, checkers);
}

export function isIAPIIdsFilter(data: any): data is IAPIIDsFilter {
    const checkers = {
        joiner: isJoiner,
        matchSet: isStringArray,
        empty: isBoolean,
        includes: isString,
        excludes: isString,
    };

    return isFilter(data, checkers);
}

export function isIAPIStringFilter(data: any): data is IAPIStringFilter {
    const checkers = {
        joiner: isJoiner,
        isNull: isBoolean,
        like: isString,
        equal: isString,
        notEqual: isString,
        include: isIdsArray,
        exclude: isIdsArray,
    };

    return isFilter(data, checkers);
}

export function isIAPINumberFilter(data: any): data is IAPINumberFilter {
    const checkers = {
        joiner: isJoiner,
        equal: isInteger,
        exclude: isIntegerArray,
        include: isIntegerArray,
        isNull: isBoolean,
        lessThan: isInteger,
        moreThan: isInteger,
        notEqual: isInteger,
        sql: isString,
    };

    return isFilter(data, checkers);
}
