import { IOperationInfo } from '@ab-task/types';
import { DocumentNode, visit } from 'graphql';

const operationInfoCache = new Map<DocumentNode, IOperationInfo>();

export function getOperationInfo(document: DocumentNode): IOperationInfo {
    if (operationInfoCache.has(document)) {
        return operationInfoCache.get(document) as IOperationInfo;
    }

    let operationType: IOperationInfo['operationType'];
    const operationNames: IOperationInfo['operationNames'] = [];
    const fieldNames: IOperationInfo['fieldNames'] = [];

    visit(document, {
        OperationDefinition(node) {
            operationType = node.operation;

            if (node.name) {
                operationNames.push(node.name.value);
            }

            node.selectionSet.selections.forEach(selection => {
                if (selection.kind === 'Field' && selection.name) {
                    fieldNames.push(selection.name.value);
                }
            });
        },
    });

    const operationInfo: IOperationInfo = {
        operationType,
        operationNames,
        fieldNames,
    };

    operationInfoCache.set(document, operationInfo);

    return operationInfo;
}
