import * as React from 'react';
import { DocumentNode } from 'graphql';
import { isEqual } from 'lodash';
import { getStoreKeys } from './getStoreKey';
import { QueryHookOptions } from '@apollo/client';

export const activeQueryStoreKeys = new Set<string>();

export function useTrackQuery(query: DocumentNode, options: QueryHookOptions<any, any>) {
    const variables = options?.variables;

    const savedVariables = React.useRef(variables);

    const memoizedQueryVariables = React.useMemo(() => {
        if (!isEqual(variables, savedVariables.current)) {
            savedVariables.current = variables;
        }

        return savedVariables.current;
    }, [variables]);

    const storeKeys = React.useMemo(
        () => getStoreKeys(query, memoizedQueryVariables),
        [query, memoizedQueryVariables]
    );

    React.useEffect(() => {
        storeKeys.forEach(key => {
            activeQueryStoreKeys.add(key);
        });

        return () => {
            storeKeys.forEach(key => {
                activeQueryStoreKeys.delete(key);
            });
        };
    }, [storeKeys]);
}
