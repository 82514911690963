import { getElement, getElements } from './load';
import { getApolloClient, AddEmailFromLandingDocument } from '@ab-task/apollo';

import { v4 as uuid } from 'uuid';

const CLIENT_ID = uuid();

export function runCaptureEmail() {
    const forms = getElements('.JS--EmailCaptureForm');

    Array.from(forms).forEach(form => {
        form.addEventListener('submit', async event => {
            event.preventDefault();
            let emailInput: HTMLButtonElement | undefined;
            let submitButton: HTMLButtonElement | undefined;

            try {
                const { target: submittedForm } = event as { target: HTMLButtonElement | null };

                if (!submittedForm) {
                    throw new Error(`Expected submitted form element but got null`);
                }

                emailInput = getElement('input[name="email"]', submittedForm) as HTMLButtonElement;
                submitButton = getElement('button', submittedForm) as HTMLButtonElement;

                emailInput.disabled = true;
                submitButton.disabled = true;

                await captureEmail(emailInput.value);

                submittedForm.style.display = 'none';

                const thankYouElement = getElement(
                    'div.ThankYou',
                    submittedForm.parentElement
                ) as HTMLButtonElement;

                thankYouElement.style.display = 'flex';
            } catch (error) {
                console.error(error);
            } finally {
                emailInput && (emailInput.disabled = false);
                submitButton && (submitButton.disabled = false);
            }
        });
    });
}

async function captureEmail(email: string) {
    const apolloClient = await getApolloClient({
        environment: 'browser',
        clientId: CLIENT_ID,
        region: process.env.REACT_APP_AWS_REGION!,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
        appSyncEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT!,
    });

    await apolloClient.mutate({
        mutation: AddEmailFromLandingDocument,
        variables: { email },
    });
}
