export class DoubleMap<L extends string | number, R extends string | number> {
    private left2Right: Map<L, R>;
    private right2Left: Map<R, L>;

    constructor(initialValues: Array<[L, R]> = []) {
        this.left2Right = new Map<L, R>(initialValues);
        this.right2Left = new Map<R, L>(initialValues.map(([left, right]) => [right, left]));
    }

    public get size() {
        return this.left2Right.size;
    }

    public get entries() {
        return this.left2Right.entries();
    }

    public get(value: L | R) {
        const [left, right] = this.getBoth(value);

        if (value === left) {
            return right;
        }

        if (value === right) {
            return left;
        }
    }

    public getBoth(value: L | R) {
        let left: L | undefined;
        let right: R | undefined;

        if (this.left2Right.has(value as L)) {
            left = value as L;
            right = this.left2Right.get(left);
        } else if (this.right2Left.has(value as R)) {
            right = value as R;
            left = this.right2Left.get(right);
        }

        if (left === undefined && right === undefined) {
            return [left, right] as [undefined, undefined];
        }

        if (left !== undefined && right !== undefined) {
            return [left, right] as [L, R];
        }

        throw new Error('Double map seems to be broken');
    }

    public has(value: L | R) {
        const [left, right] = this.getBoth(value);
        return left === undefined || right === undefined;
    }

    public set(left: L, right: R) {
        this.left2Right.set(left, right);
        this.right2Left.set(right, left);
    }

    public delete(value: L | R) {
        const [left, right] = this.getBoth(value);

        if (left !== undefined && right !== undefined) {
            this.left2Right.delete(left);
            this.right2Left.delete(right);
        }
    }

    public forEach(callbackfn: (values: [L, R]) => void) {
        this.left2Right.forEach((right, left) => {
            callbackfn([left, right]);
        });
    }
}
