import { makeVar, ReactiveVar } from '@apollo/client';

export function makePersistentVar<T>(initialValue: T, lsKey: string): ReactiveVar<T> {
    let value = initialValue;

    if (typeof localStorage === 'undefined') {
        // Persistance is only available when localStorage exists (browser env)
        return makeVar<T>(initialValue);
    }

    {
        // Try to init from localStorage
        const previousValue = localStorage.getItem(lsKey);

        if (previousValue !== null) {
            try {
                const parsed = JSON.parse(previousValue);
                value = parsed;
            } catch {
                value = previousValue as unknown as T;
            }
        }
    }

    const rv = makeVar<T>(value);

    const handleNextChage = (newValue: T | undefined) => {
        try {
            if (newValue === undefined) {
                localStorage.removeItem(lsKey);
            } else {
                localStorage.setItem(
                    lsKey,
                    typeof newValue === 'string' ? newValue : JSON.stringify(newValue)
                );
            }
        } catch {}

        rv.onNextChange(handleNextChage);
    };

    rv.onNextChange(handleNextChage);

    return rv;
}
