export const MS_PER_SECOND = 1000;
export const MS_PER_MINUTE = MS_PER_SECOND * 60;
export const MS_PER_HOUR = MS_PER_MINUTE * 60;
export const MS_PER_DAY = MS_PER_HOUR * 24;
export const MS_PER_WEEK = MS_PER_DAY * 7;
export const MS_PER_MONTH = MS_PER_WEEK * 4;
export const MS_PER_YEAR = MS_PER_MONTH * 12;
export const SEC_PER_MINUTE = 60;
export const SEC_PER_HOUR = SEC_PER_MINUTE * 60;
export const MAX_TIMEOUT = MS_PER_DAY * 24;
