import {
    ETables,
    NestedKeyOf,
    isAclEntities,
    TAcl,
    IPolicy,
    IAccessListItemWithModels,
    IAclEntity,
    IDBProject,
    IDBRole,
    IAccessListItem,
    isAccessListWithModels,
    IRole,
    TWorkspaceDataMap,
} from '@ab-task/types';
import { get } from 'lodash';
import { ID2GUID, getModelID } from './core';
import { roleDB2JS } from './role';
import { projectDB2JS } from './project';

export function accessListItemDB2JS(
    dbItem: IDBRole & Partial<IDBProject>
): IAccessListItemWithModels {
    const item: IAccessListItemWithModels = {
        role: roleDB2JS(dbItem),
    };

    if (typeof dbItem.p_id === 'number') {
        item.project = projectDB2JS(dbItem as IDBProject);
    }

    return item;
}

export interface ICheckPermissionPayload {
    userId?: number;
    projectId?: number;
    accessList?: IAccessListItemWithModels[];
    acl?: TAcl;
    path?: NestedKeyOf<IPolicy>;
}

export function checkPermission(payload: ICheckPermissionPayload): boolean {
    const { userId, projectId, accessList, acl, path } = payload;

    if (!path) return false;

    const role =
        accessList?.find(({ project }) => project && project.id === projectId)?.role ||
        accessList?.find(({ project }) => !project)?.role;

    if (acl) {
        const pathArr = path.split('.');
        pathArr.shift();

        const isRoleAssumed = Boolean(role);
        const entity: IAclEntity = {
            userId,
            roleId: role?.id,
        };

        if (
            isRoleAssumed &&
            checkAclPermission(acl, pathArr.join('.') as NestedKeyOf<typeof acl>, entity)
        ) {
            return true;
        }

        if (acl.exclusiveAccess) return false;
    }

    return checkPolicyPermission(role?.policy, path);
}

export function getIsPermissionGrantedInAnyRole(
    accessList?: IAccessListItemWithModels[],
    path?: NestedKeyOf<IPolicy>
): boolean {
    if (!accessList || accessList.length === 0 || !path) return false;

    for (const { role } of accessList) {
        if (checkPolicyPermission(role.policy, path)) {
            return true;
        }
    }

    return false;
}

export function filterAccessList(
    accessList?: IAccessListItemWithModels[],
    path?: NestedKeyOf<IPolicy>
): IAccessListItemWithModels[] {
    if (!accessList || accessList.length === 0) return [];

    if (!path) return accessList;

    return accessList.filter(item => checkPolicyPermission(item.role.policy, path));
}

export function accessListItemsComparator(
    { project: a }: IAccessListItemWithModels,
    { project: b }: IAccessListItemWithModels
) {
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;
    return a.name.localeCompare(b.name);
}

export function getIsPermissionGrantedInDefaultRole(
    accessList?: IAccessListItem[] | IAccessListItemWithModels[],
    path?: NestedKeyOf<IPolicy>,
    roles?: IRole[]
): boolean {
    if (!accessList || accessList.length === 0 || !path) return false;

    if (isAccessListWithModels(accessList)) {
        const defaultRole = accessList.find(entity => !entity.project)?.role;
        return checkPolicyPermission(defaultRole?.policy, path);
    } else {
        if (!roles || roles.length === 0) return false;
        const defaultRoleId = accessList.find(entity => !entity.projectId)?.roleId;
        const defaultRole = roles.find(role => role.id === defaultRoleId);
        return checkPolicyPermission(defaultRole?.policy, path);
    }
}

export function getWorkspaceGuidsWithPermissionGrantedInDefaultRole(
    workspaceDataMap?: TWorkspaceDataMap,
    path?: NestedKeyOf<IPolicy> | null,
    roles?: IRole[]
) {
    let workspaceGuids: string[] = [];

    if (workspaceDataMap) {
        for (const [workspaceId, workspaceData] of Object.entries(workspaceDataMap)) {
            const { suspension, accessList } = workspaceData;

            if (
                !suspension &&
                (path === null || getIsPermissionGrantedInDefaultRole(accessList, path, roles))
            ) {
                workspaceGuids.push(ID2GUID(ETables.workspaces, Number(workspaceId)));
            }
        }
    }

    return workspaceGuids;
}

export function checkPolicyPermission(policy?: IPolicy, path?: NestedKeyOf<IPolicy>): boolean {
    if (!policy) return false;

    if (policy.isRoot) return true;

    if (typeof path !== 'string') return false;

    return get(policy, path) === true;
}

export function checkAclPermission(
    acl?: TAcl<'task'>,
    path?: NestedKeyOf<TAcl<'task'>>,
    entity?: IAclEntity
): boolean;

export function checkAclPermission(acl?: TAcl, path?: string, entity?: IAclEntity): boolean {
    if (!acl || !path || !entity) return false;

    const entities = get(acl, path);

    if (!isAclEntities(entities)) return false;

    const { roleIds, userIds } = entities;

    const roleId = getModelID(entity.roleId, ETables.roles);
    const userId = getModelID(entity.userId, ETables.users);

    return Boolean(
        (typeof roleId === 'number' && roleIds?.includes(roleId)) ||
            (typeof userId === 'number' && userIds?.includes(userId))
    );
}
