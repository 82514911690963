import { InMemoryCache } from '@apollo/client';
import { ETypeNames, TApolloClientConfig } from '../types';
import { invalidateQueries } from './invalidateQueries';
import { typePolicies } from './typePolicies';
//import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import typeDefs from './schema';

export { ETypeNames, typeDefs, invalidateQueries };

export async function getCache(config: TApolloClientConfig): Promise<InMemoryCache> {
    switch (config.environment) {
        case 'node': {
            return new InMemoryCache();
        }

        case 'browser': {
            const cache = new InMemoryCache({
                typePolicies,
            });

            /*const persistor = new CachePersistor({
                cache,
                storage: new LocalStorageWrapper(window.localStorage),
                persistenceMapper: async (data: any) => {
                    //console.log('filter your cached data and queries', data);

                    return data;
                },
            });

            await persistor.restore();*/

            return cache;
        }
    }
}
