import { TErrorCodes } from '@ab-task/types';
import addErrorCode from './addErrorCode';
import { prettifyStackDecorator } from './utils';

export type TServiceErrorCode = TErrorCodes<typeof ServiceError>;

export class ServiceError extends Error {
    public params?: Record<string, any>;

    private constructor(message: string, params?: Record<string, any>) {
        super();

        this.name = 'ServiceError';
        this.message = message;
        this.params = params;
    }

    @prettifyStackDecorator
    @addErrorCode
    public static MODEL_UNDEFINED(modelName: string) {
        return new ServiceError(`Model ${modelName} is undefined`);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static MODEL_HANDLER_UNDEFINED(handlerName: string) {
        return new ServiceError(`Model's handler ${handlerName} is undefined`);
    }

    @prettifyStackDecorator
    @addErrorCode
    public static CONFIGURATION_ERROR(info: string) {
        return new ServiceError(`Misconfiguration`, { info });
    }

    @prettifyStackDecorator
    @addErrorCode
    public static SESSION_SECRET_MISMATCH() {
        return new ServiceError('Session secret mismatch');
    }

    @prettifyStackDecorator
    @addErrorCode
    public static SESSION_MISSING() {
        return new ServiceError('Session missing');
    }

    @prettifyStackDecorator
    @addErrorCode
    public static USER_IS_BANNED() {
        return new ServiceError('User is banned');
    }

    @prettifyStackDecorator
    @addErrorCode
    public static GENERAL(params?: Record<string, any>) {
        return new ServiceError('General error');
    }
}
