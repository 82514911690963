export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
    [_ in K]?: never;
};
export type Incremental<T> =
    | T
    | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    AWSDateTime: { input: string; output: string };
    AWSEmail: { input: string; output: string };
    Long: { input: number; output: number };
};

export type IAPIAccessListInput = {
    accessList: Array<IAPIAccessListItemInput>;
    workspaceId: Scalars['ID']['input'];
};

export type IAPIAccessListItem = {
    __typename: 'AccessListItem';
    projectId?: Maybe<Scalars['ID']['output']>;
    roleId: Scalars['ID']['output'];
};

export type IAPIAccessListItemBroadcast = {
    projectId?: InputMaybe<Scalars['ID']['input']>;
    roleId: Scalars['ID']['input'];
};

export type IAPIAccessListItemInput = {
    projectId?: InputMaybe<Scalars['ID']['input']>;
    roleId: Scalars['ID']['input'];
};

export type IAPIAclEntities = {
    __typename: 'AclEntities';
    groupIdsCsv?: Maybe<Scalars['String']['output']>;
    roleIdsCsv?: Maybe<Scalars['String']['output']>;
    userIdsCsv?: Maybe<Scalars['String']['output']>;
};

export type IAPIAclEntitiesBroadcast = {
    groupIdsCsv?: InputMaybe<Scalars['String']['input']>;
    roleIdsCsv?: InputMaybe<Scalars['String']['input']>;
    userIdsCsv?: InputMaybe<Scalars['String']['input']>;
};

export type IAPIAttachment = {
    __typename: 'Attachment';
    contentType: Scalars['String']['output'];
    createdAt: Scalars['String']['output'];
    filename: Scalars['String']['output'];
    messageId: Scalars['ID']['output'];
    progress?: Maybe<Scalars['Int']['output']>;
    s3Key: Scalars['String']['output'];
};

export type IAPIAttachmentCreateInput = {
    contentType: Scalars['String']['input'];
    filename: Scalars['String']['input'];
    messageId: Scalars['ID']['input'];
    s3Key: Scalars['String']['input'];
};

export type IAPIAttachmentMutationResult = IAPIMutationResult & {
    __typename: 'AttachmentMutationResult';
    attachment: IAPIAttachment;
    channelId: Scalars['ID']['output'];
    clientId: Scalars['ID']['output'];
    progress?: Maybe<Scalars['Int']['output']>;
    uploadURL?: Maybe<Scalars['String']['output']>;
};

export type IAPIBill = IAPINode & {
    __typename: 'Bill';
    duration: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    month: Scalars['Int']['output'];
    requestsCount: Scalars['Int']['output'];
    storageDataTransfer: Scalars['Int']['output'];
    storageOtherRequestsCount: Scalars['Int']['output'];
    storagePcplRequestsCount: Scalars['Int']['output'];
    storageUsage: Scalars['Int']['output'];
    total: Scalars['Float']['output'];
    workspaceId: Scalars['ID']['output'];
    year: Scalars['Int']['output'];
};

export type IAPIBillPayable = IAPINode & {
    __typename: 'BillPayable';
    billIds: Array<Scalars['ID']['output']>;
    duration: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    month: Scalars['Int']['output'];
    requestsCount: Scalars['Int']['output'];
    storageDataTransfer: Scalars['Int']['output'];
    storageOtherRequestsCount: Scalars['Int']['output'];
    storagePcplRequestsCount: Scalars['Int']['output'];
    storageUsage: Scalars['Int']['output'];
    total: Scalars['Float']['output'];
    year: Scalars['Int']['output'];
};

export type IAPIBills = {
    __typename: 'Bills';
    allIds?: Maybe<Array<Scalars['Int']['output']>>;
    bills: Array<IAPIBill>;
};

export type IAPIBillsFilter = {
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    month?: InputMaybe<IAPINumberFilter>;
    total?: InputMaybe<IAPINumberFilter>;
    workspaceId?: InputMaybe<IAPIIdFilter>;
    year?: InputMaybe<IAPINumberFilter>;
};

export type IAPIBillsPayable = {
    __typename: 'BillsPayable';
    allIds?: Maybe<Array<Scalars['Int']['output']>>;
    billsPayable: Array<IAPIBillPayable>;
};

export type IAPIBillsPayableFilter = {
    joiner?: InputMaybe<Joiner>;
    month?: InputMaybe<IAPINumberFilter>;
    total?: InputMaybe<IAPINumberFilter>;
    year?: InputMaybe<IAPINumberFilter>;
};

export type IAPIBillsPayableSort = {
    month?: InputMaybe<SortDirection>;
    total?: InputMaybe<SortDirection>;
    year?: InputMaybe<SortDirection>;
};

export type IAPIBillsSort = {
    id?: InputMaybe<SortDirection>;
    month?: InputMaybe<SortDirection>;
    total?: InputMaybe<SortDirection>;
    year?: InputMaybe<SortDirection>;
};

export type IAPICard = {
    __typename: 'Card';
    expiryDate: Scalars['String']['output'];
    lastFourDigits: Scalars['String']['output'];
    type: Scalars['String']['output'];
};

export type IAPIConfirmationCodeInput = {
    accessList?: InputMaybe<Array<IAPIAccessListItemInput>>;
    email: Scalars['AWSEmail']['input'];
    reason: ConfirmationCodeRequestReason;
    workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type ConfirmationCodeRequestReason = 'INVITATION' | 'REGISTRATION' | 'RESET';

export type IAPICreatorAlignments = {
    __typename: 'CreatorAlignments';
    left: Array<Scalars['String']['output']>;
    right: Array<Scalars['String']['output']>;
};

export type IAPICreatorAlignmentsBroadcast = {
    left: Array<Scalars['String']['input']>;
    right: Array<Scalars['String']['input']>;
};

export type IAPIDashboard = IAPINode & {
    __typename: 'Dashboard';
    config?: Maybe<Scalars['String']['output']>;
    expanded?: Maybe<Scalars['Boolean']['output']>;
    favorite?: Maybe<Scalars['Boolean']['output']>;
    hasMentions: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    key?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    order: Scalars['Float']['output'];
    ownerId: Scalars['ID']['output'];
    type: DashboardType;
    unreads: Scalars['Int']['output'];
    workspaceId: Scalars['ID']['output'];
};

export type IAPIDashboardCreateInput = {
    config?: InputMaybe<Scalars['String']['input']>;
    favorite?: InputMaybe<Scalars['Boolean']['input']>;
    key?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    type: DashboardType;
};

export type IAPIDashboardMutationResult = IAPIMutationResult & {
    __typename: 'DashboardMutationResult';
    clientId: Scalars['ID']['output'];
    dashboard: IAPIDashboard;
    internal?: Maybe<Scalars['Boolean']['output']>;
    ownerId: Scalars['ID']['output'];
    workspaceId: Scalars['ID']['output'];
};

export type DashboardType = 'DOCUMENTS' | 'PEOPLE' | 'PROJECTS' | 'TASKS' | 'TOPICS';

export type IAPIDashboardUpdateInput = {
    config?: InputMaybe<Scalars['String']['input']>;
    favorite?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<Scalars['Float']['input']>;
};

export type IAPIDashboardsFilter = {
    favorite?: InputMaybe<IAPINumberFilter>;
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    type?: InputMaybe<IAPIStringFilter>;
    workspaceId?: InputMaybe<IAPIIdFilter>;
};

export type IAPIDashboardsSort = {
    favorite?: InputMaybe<SortDirection>;
    id?: InputMaybe<SortDirection>;
    name?: InputMaybe<SortDirection>;
    order?: InputMaybe<SortDirection>;
    type?: InputMaybe<SortDirection>;
};

export type IAPIDateFilter = {
    equal?: InputMaybe<Scalars['String']['input']>;
    isNull?: InputMaybe<Scalars['Boolean']['input']>;
    joiner?: InputMaybe<Joiner>;
    lessThan?: InputMaybe<Scalars['String']['input']>;
    moreThan?: InputMaybe<Scalars['String']['input']>;
    notEqual?: InputMaybe<Scalars['String']['input']>;
    sql?: InputMaybe<Scalars['String']['input']>;
};

export type IAPIDocument = IAPINode & {
    __typename: 'Document';
    createdAt: Scalars['String']['output'];
    hasMentions: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    messagedAt?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    projectId?: Maybe<Scalars['ID']['output']>;
    unreads: Scalars['Int']['output'];
    updatedAt: Scalars['String']['output'];
    workspaceId: Scalars['ID']['output'];
};

export type IAPIDocumentInput = {
    name?: InputMaybe<Scalars['String']['input']>;
    projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPIDocumentMutationResult = IAPIMutationResult & {
    __typename: 'DocumentMutationResult';
    clientId: Scalars['ID']['output'];
    document: IAPIDocument;
    workspaceId: Scalars['ID']['output'];
};

export type IAPIDocuments = {
    __typename: 'Documents';
    allIds?: Maybe<Array<Scalars['Int']['output']>>;
    documents: Array<IAPIDocument>;
};

export type IAPIDocumentsFilter = {
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    name?: InputMaybe<IAPIStringFilter>;
    projectID?: InputMaybe<IAPIIdFilter>;
    unreads?: InputMaybe<IAPINumberFilter>;
    workspaceId?: InputMaybe<IAPIIdFilter>;
};

export type IAPIDocumentsSort = {
    createdAt?: InputMaybe<SortDirection>;
    id?: InputMaybe<SortDirection>;
    messagedAt?: InputMaybe<SortDirection>;
    name?: InputMaybe<SortDirection>;
    unreads?: InputMaybe<SortDirection>;
    updatedAt?: InputMaybe<SortDirection>;
};

export type IAPIEmoji = IAPINode & {
    __typename: 'Emoji';
    id: Scalars['ID']['output'];
    imageBase64: Scalars['String']['output'];
    shortname: Scalars['String']['output'];
    unicode: Scalars['String']['output'];
};

export type IAPIEpic = IAPINode & {
    __typename: 'Epic';
    createdAt: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    milestoneId?: Maybe<Scalars['ID']['output']>;
    name: Scalars['String']['output'];
    projectId: Scalars['ID']['output'];
    updatedAt: Scalars['String']['output'];
    workspaceId: Scalars['ID']['output'];
};

export type IAPIEpicInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
    milestoneId?: InputMaybe<Scalars['ID']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPIEpicsFilter = {
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    milestoneId?: InputMaybe<IAPIIdFilter>;
    name?: InputMaybe<IAPIStringFilter>;
    projectId?: InputMaybe<IAPIIdFilter>;
    workspaceId?: InputMaybe<IAPIIdFilter>;
};

export type IAPIGroup = IAPINode & {
    __typename: 'Group';
    createdAt: Scalars['String']['output'];
    creatorAlignments?: Maybe<IAPICreatorAlignments>;
    description?: Maybe<Scalars['String']['output']>;
    hasMentions: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    memberIds?: Maybe<Array<Scalars['ID']['output']>>;
    messagedAt?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    unreads: Scalars['Int']['output'];
    updatedAt: Scalars['String']['output'];
    workspaceId: Scalars['ID']['output'];
};

export type IAPIGroupInput = {
    addMemberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    description?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    latestMessageCreatorId?: InputMaybe<Scalars['ID']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    removeMemberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPIGroups = {
    __typename: 'Groups';
    allIds?: Maybe<Array<Scalars['Int']['output']>>;
    groups: Array<IAPIGroup>;
};

export type IAPIGroupsFilter = {
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    memberIds?: InputMaybe<IAPIIDsFilter>;
    name?: InputMaybe<IAPIStringFilter>;
    unreads?: InputMaybe<IAPINumberFilter>;
    workspaceId?: InputMaybe<IAPIIdFilter>;
};

export type IAPIGroupsSort = {
    createdAt?: InputMaybe<SortDirection>;
    id?: InputMaybe<SortDirection>;
    messagedAt?: InputMaybe<SortDirection>;
    name?: InputMaybe<SortDirection>;
    unreads?: InputMaybe<SortDirection>;
    updatedAt?: InputMaybe<SortDirection>;
};

export type IAPIIdFilter = {
    equal?: InputMaybe<Scalars['ID']['input']>;
    exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
    include?: InputMaybe<Array<Scalars['ID']['input']>>;
    isNull?: InputMaybe<Scalars['Boolean']['input']>;
    joiner?: InputMaybe<Joiner>;
    notEqual?: InputMaybe<Scalars['ID']['input']>;
    sql?: InputMaybe<Scalars['String']['input']>;
};

export type IAPIIDsFilter = {
    empty?: InputMaybe<Scalars['Boolean']['input']>;
    excludes?: InputMaybe<Scalars['ID']['input']>;
    includes?: InputMaybe<Scalars['ID']['input']>;
    joiner?: InputMaybe<Joiner>;
    matchSet?: InputMaybe<Array<Scalars['ID']['input']>>;
    sql?: InputMaybe<Scalars['String']['input']>;
};

export type Joiner = 'AND' | 'OR';

export type IAPIMessage = IAPINode & {
    __typename: 'Message';
    attachmentS3Keys?: Maybe<Array<Scalars['String']['output']>>;
    authorIds: Array<Scalars['ID']['output']>;
    content?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['AWSDateTime']['output'];
    creatorId: Scalars['ID']['output'];
    id: Scalars['ID']['output'];
    isBig?: Maybe<Scalars['Boolean']['output']>;
    isFirst: Scalars['Boolean']['output'];
    isIncoming?: Maybe<Scalars['Boolean']['output']>;
    isLast: Scalars['Boolean']['output'];
    isReadonly?: Maybe<Scalars['Boolean']['output']>;
    reactions: Array<IAPIMessageReaction>;
    updatedAt: Scalars['AWSDateTime']['output'];
    version: Scalars['Int']['output'];
    watches: Array<IAPIMessageWatch>;
};

export type IAPIMessageMutationResult = IAPIMutationResult & {
    __typename: 'MessageMutationResult';
    channelId: Scalars['ID']['output'];
    clientId: Scalars['ID']['output'];
    message: IAPIMessage;
    steps: Array<Scalars['String']['output']>;
};

export type IAPIMessageReaction = {
    __typename: 'MessageReaction';
    emojiId: Scalars['ID']['output'];
    reactedAt: Scalars['String']['output'];
    userId: Scalars['ID']['output'];
};

export type IAPIMessageReactionBroadcast = {
    emojiId: Scalars['ID']['input'];
    reactedAt: Scalars['String']['input'];
    userId: Scalars['ID']['input'];
};

export type IAPIMessageUpdateBroadcast = {
    authorIds: Array<Scalars['ID']['input']>;
    content?: InputMaybe<Scalars['String']['input']>;
    createdAt: Scalars['AWSDateTime']['input'];
    creatorId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    isFirst: Scalars['Boolean']['input'];
    isLast: Scalars['Boolean']['input'];
    reactions: Array<IAPIMessageReactionBroadcast>;
    updatedAt: Scalars['AWSDateTime']['input'];
    version: Scalars['Int']['input'];
    watches: Array<IAPIMessageWatchBroadcast>;
};

export type IAPIMessageWatch = {
    __typename: 'MessageWatch';
    userId: Scalars['ID']['output'];
    watchedAt: Scalars['AWSDateTime']['output'];
};

export type IAPIMessageWatchBroadcast = {
    userId: Scalars['ID']['input'];
    watchedAt: Scalars['AWSDateTime']['input'];
};

export type IAPIMessagesFilter = {
    bidirectional?: InputMaybe<Scalars['Boolean']['input']>;
    channelId: Scalars['ID']['input'];
    cursor: Scalars['String']['input'];
    offset: Scalars['Int']['input'];
};

export type IAPIMutation = {
    __typename: 'Mutation';
    addEmailFromLanding: Scalars['Boolean']['output'];
    applyInvite: Scalars['Boolean']['output'];
    broadcastMessageUpdate: IAPIMessageMutationResult;
    broadcastSelectionUpdate: IAPISelectionUpdateBroadcastResult;
    broadcastTaskRemove: IAPITaskMutationResult;
    broadcastTaskUpdate: IAPITaskMutationResult;
    broadcastUserUpdate: IAPIUserUpdateBroadcastResult;
    bulkRemoveTasks: Scalars['Boolean']['output'];
    bulkUpdateTasks: Scalars['Boolean']['output'];
    createAttachment: IAPIAttachmentMutationResult;
    createDashboard: IAPIDashboardMutationResult;
    createDocument: IAPIDocumentMutationResult;
    createProject: IAPIProjectMutationResult;
    createReaction: IAPIMessageMutationResult;
    createTask: IAPITaskMutationResult;
    createTopic: IAPITopicMutationResult;
    googleSignIn: Scalars['String']['output'];
    login: Scalars['String']['output'];
    logout: Scalars['Boolean']['output'];
    mentionUser?: Maybe<Scalars['Boolean']['output']>;
    removeAttachment: IAPIAttachmentMutationResult;
    removeAvatar: IAPIRemoveAvatarResult;
    removeDashboard: IAPIDashboardMutationResult;
    removeDocument: IAPIDocumentMutationResult;
    removeEpic: IAPIEpic;
    removeMessage: IAPIMessageMutationResult;
    removeProject: IAPIProjectMutationResult;
    removeReaction: IAPIMessageMutationResult;
    removeTask: IAPITaskMutationResult;
    removeTaskLink?: Maybe<Scalars['Boolean']['output']>;
    removeTopic: IAPITopicMutationResult;
    removeWorkspace: IAPIWorkspace;
    sendConfirmationCode: Scalars['Boolean']['output'];
    setUserAccessList: IAPIUser;
    setUserSuspension: IAPIUser;
    unmentionUser?: Maybe<Scalars['Boolean']['output']>;
    updateAttachment: IAPIAttachmentMutationResult;
    updateAvatar: IAPIUpdateAvatarResult;
    updateDashboard: IAPIDashboardMutationResult;
    updateDocument: IAPIDocumentMutationResult;
    updateEpic: IAPIEpic;
    updateGroup: IAPIGroup;
    updateMessage: IAPIMessageMutationResult;
    updateProject: IAPIProjectMutationResult;
    updateTask: IAPITaskMutationResult;
    updateTaskLink?: Maybe<Scalars['Boolean']['output']>;
    updateTopic: IAPITopicMutationResult;
    updateUser: IAPIUser;
    updateUserInWorkspace: IAPIUpdateUserInWorkspaceResult;
    updateWorkspace: IAPIWorkspace;
    watchMessages: IAPIWatchMessageResult;
};

export type IAPIMutationAddEmailFromLandingArgs = {
    email: Scalars['AWSEmail']['input'];
};

export type IAPIMutationApplyInviteArgs = {
    confirmationCode: Scalars['String']['input'];
    email: Scalars['AWSEmail']['input'];
    password?: InputMaybe<Scalars['String']['input']>;
};

export type IAPIMutationBroadcastMessageUpdateArgs = {
    channelId: Scalars['ID']['input'];
    clientId: Scalars['ID']['input'];
    message: IAPIMessageUpdateBroadcast;
    steps: Array<Scalars['String']['input']>;
};

export type IAPIMutationBroadcastSelectionUpdateArgs = {
    channelId: Scalars['ID']['input'];
    clientId: Scalars['ID']['input'];
    selection: IAPISelectionUpdateBroadcast;
};

export type IAPIMutationBroadcastTaskRemoveArgs = {
    clientId: Scalars['ID']['input'];
    task: IAPITaskRemoveBroadcast;
};

export type IAPIMutationBroadcastTaskUpdateArgs = {
    clientId: Scalars['ID']['input'];
    task: IAPITaskUpdateBroadcast;
};

export type IAPIMutationBroadcastUserUpdateArgs = {
    user: IAPIUserUpdateBroadcast;
    workspaceId: Scalars['ID']['input'];
};

export type IAPIMutationBulkRemoveTasksArgs = {
    action: RemoveAction;
    ids: Array<Scalars['ID']['input']>;
};

export type IAPIMutationBulkUpdateTasksArgs = {
    ids: Array<Scalars['ID']['input']>;
    task?: InputMaybe<IAPITaskBulkInput>;
};

export type IAPIMutationCreateAttachmentArgs = {
    attachment: IAPIAttachmentCreateInput;
};

export type IAPIMutationCreateDashboardArgs = {
    dashboard: IAPIDashboardCreateInput;
    internal?: InputMaybe<Scalars['Boolean']['input']>;
    workspaceId: Scalars['ID']['input'];
};

export type IAPIMutationCreateDocumentArgs = {
    document?: InputMaybe<IAPIDocumentInput>;
    workspaceId: Scalars['ID']['input'];
};

export type IAPIMutationCreateProjectArgs = {
    project?: InputMaybe<IAPIProjectInput>;
    workspaceId: Scalars['ID']['input'];
};

export type IAPIMutationCreateReactionArgs = {
    emojiId: Scalars['ID']['input'];
    messageId: Scalars['ID']['input'];
};

export type IAPIMutationCreateTaskArgs = {
    task?: InputMaybe<IAPITaskInput>;
    workspaceId: Scalars['ID']['input'];
};

export type IAPIMutationCreateTopicArgs = {
    topic?: InputMaybe<IAPITopicInput>;
    workspaceId: Scalars['ID']['input'];
};

export type IAPIMutationGoogleSignInArgs = {
    credential: Scalars['String']['input'];
};

export type IAPIMutationLoginArgs = {
    email: Scalars['AWSEmail']['input'];
    password: Scalars['String']['input'];
};

export type IAPIMutationLogoutArgs = {
    allDevices?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IAPIMutationMentionUserArgs = {
    channelId: Scalars['ID']['input'];
    mentionGuid: Scalars['String']['input'];
    messageId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type IAPIMutationRemoveAttachmentArgs = {
    s3Key: Scalars['String']['input'];
};

export type IAPIMutationRemoveDashboardArgs = {
    action: RemoveAction;
    id: Scalars['ID']['input'];
};

export type IAPIMutationRemoveDocumentArgs = {
    action: RemoveAction;
    id: Scalars['ID']['input'];
};

export type IAPIMutationRemoveEpicArgs = {
    action: RemoveAction;
    id: Scalars['ID']['input'];
};

export type IAPIMutationRemoveMessageArgs = {
    messageId: Scalars['ID']['input'];
};

export type IAPIMutationRemoveProjectArgs = {
    action: RemoveAction;
    id: Scalars['ID']['input'];
};

export type IAPIMutationRemoveReactionArgs = {
    emojiId: Scalars['ID']['input'];
    messageId: Scalars['ID']['input'];
};

export type IAPIMutationRemoveTaskArgs = {
    action: RemoveAction;
    id: Scalars['ID']['input'];
};

export type IAPIMutationRemoveTaskLinkArgs = {
    fromId: Scalars['ID']['input'];
    toId: Scalars['ID']['input'];
};

export type IAPIMutationRemoveTopicArgs = {
    action: RemoveAction;
    id: Scalars['ID']['input'];
};

export type IAPIMutationRemoveWorkspaceArgs = {
    action: RemoveAction;
    id: Scalars['ID']['input'];
};

export type IAPIMutationSendConfirmationCodeArgs = {
    input: IAPIConfirmationCodeInput;
};

export type IAPIMutationSetUserAccessListArgs = {
    accessList: IAPIAccessListInput;
    userId: Scalars['ID']['input'];
};

export type IAPIMutationSetUserSuspensionArgs = {
    suspend: Scalars['Boolean']['input'];
    userId: Scalars['ID']['input'];
    workspaceId: Scalars['ID']['input'];
};

export type IAPIMutationUnmentionUserArgs = {
    channelId: Scalars['ID']['input'];
    mentionGuid: Scalars['String']['input'];
    messageId: Scalars['ID']['input'];
};

export type IAPIMutationUpdateAttachmentArgs = {
    progress?: InputMaybe<Scalars['Int']['input']>;
    s3Key: Scalars['String']['input'];
};

export type IAPIMutationUpdateAvatarArgs = {
    contentType: Scalars['String']['input'];
    s3Key: Scalars['String']['input'];
};

export type IAPIMutationUpdateDashboardArgs = {
    dashboard: IAPIDashboardUpdateInput;
    id: Scalars['ID']['input'];
};

export type IAPIMutationUpdateDocumentArgs = {
    document?: InputMaybe<IAPIDocumentInput>;
    id: Scalars['ID']['input'];
};

export type IAPIMutationUpdateEpicArgs = {
    epic: IAPIEpicInput;
};

export type IAPIMutationUpdateGroupArgs = {
    group: IAPIGroupInput;
};

export type IAPIMutationUpdateMessageArgs = {
    channelId: Scalars['ID']['input'];
    clientGuid: Scalars['ID']['input'];
    messageId?: InputMaybe<Scalars['ID']['input']>;
    steps: Array<Scalars['String']['input']>;
    version: Scalars['Int']['input'];
};

export type IAPIMutationUpdateProjectArgs = {
    id: Scalars['ID']['input'];
    project?: InputMaybe<IAPIProjectInput>;
};

export type IAPIMutationUpdateTaskArgs = {
    id: Scalars['ID']['input'];
    task?: InputMaybe<IAPITaskInput>;
};

export type IAPIMutationUpdateTaskLinkArgs = {
    fromId: Scalars['ID']['input'];
    linkType: TaskLinkType;
    toId: Scalars['ID']['input'];
};

export type IAPIMutationUpdateTopicArgs = {
    id: Scalars['ID']['input'];
    topic?: InputMaybe<IAPITopicInput>;
};

export type IAPIMutationUpdateUserArgs = {
    user: IAPIUserInput;
};

export type IAPIMutationUpdateUserInWorkspaceArgs = {
    add: Scalars['Boolean']['input'];
    userId: Scalars['ID']['input'];
    workspaceId: Scalars['ID']['input'];
};

export type IAPIMutationUpdateWorkspaceArgs = {
    workspace: IAPIWorkspaceInput;
};

export type IAPIMutationWatchMessagesArgs = {
    channelId: Scalars['ID']['input'];
    messageIds: Array<Scalars['ID']['input']>;
};

export type IAPIMutationResult = {
    clientId: Scalars['ID']['output'];
};

export type IAPINode = {
    id: Scalars['ID']['output'];
};

export type IAPINumberFilter = {
    equal?: InputMaybe<Scalars['Int']['input']>;
    exclude?: InputMaybe<Array<Scalars['Int']['input']>>;
    include?: InputMaybe<Array<Scalars['Int']['input']>>;
    isNull?: InputMaybe<Scalars['Boolean']['input']>;
    joiner?: InputMaybe<Joiner>;
    lessThan?: InputMaybe<Scalars['Int']['input']>;
    moreThan?: InputMaybe<Scalars['Int']['input']>;
    notEqual?: InputMaybe<Scalars['Int']['input']>;
    sql?: InputMaybe<Scalars['String']['input']>;
};

export type IAPIPaddleSubscription = {
    __typename: 'PaddleSubscription';
    cancelUrl: Scalars['String']['output'];
    card?: Maybe<IAPICard>;
    id: Scalars['Int']['output'];
    updateUrl: Scalars['String']['output'];
};

export type IAPIPagination = {
    limit: Scalars['Int']['input'];
    offset?: InputMaybe<Scalars['Int']['input']>;
    type?: InputMaybe<PaginationType>;
};

export type PaginationType = 'FIXED' | 'SCROLL';

export type IAPIPasswordUpdateInput = {
    newPassword: Scalars['String']['input'];
    oldPassword: Scalars['String']['input'];
};

export type IAPIProject = IAPINode & {
    __typename: 'Project';
    createdAt: Scalars['String']['output'];
    creatorAlignments?: Maybe<IAPICreatorAlignments>;
    creatorId: Scalars['ID']['output'];
    hasMentions: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    messagedAt?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    status: ProjectStatus;
    unreads: Scalars['Int']['output'];
    updatedAt: Scalars['String']['output'];
    workspaceId: Scalars['ID']['output'];
};

export type IAPIProjectInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
    latestMessageCreatorId?: InputMaybe<Scalars['ID']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPIProjectMutationResult = IAPIMutationResult & {
    __typename: 'ProjectMutationResult';
    clientId: Scalars['ID']['output'];
    project: IAPIProject;
    workspaceId: Scalars['ID']['output'];
};

export type ProjectStatus = 'ACTIVE' | 'COMPLETED';

export type IAPIProjects = {
    __typename: 'Projects';
    allIds?: Maybe<Array<Scalars['Int']['output']>>;
    projects: Array<IAPIProject>;
};

export type IAPIProjectsFilter = {
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    name?: InputMaybe<IAPIStringFilter>;
    status?: InputMaybe<IAPIStringFilter>;
    unreads?: InputMaybe<IAPINumberFilter>;
    workspaceId?: InputMaybe<IAPIIdFilter>;
};

export type IAPIProjectsSort = {
    createdAt?: InputMaybe<SortDirection>;
    id?: InputMaybe<SortDirection>;
    messagedAt?: InputMaybe<SortDirection>;
    name?: InputMaybe<SortDirection>;
    status?: InputMaybe<SortDirection>;
    unreads?: InputMaybe<SortDirection>;
    updatedAt?: InputMaybe<SortDirection>;
    workspaceId?: InputMaybe<SortDirection>;
};

export type IAPIQuery = {
    __typename: 'Query';
    attachments: Array<IAPIAttachment>;
    balance: Scalars['Float']['output'];
    bills: IAPIBills;
    billsPayable: IAPIBillsPayable;
    dashboards: Array<IAPIDashboard>;
    documents: IAPIDocuments;
    emojis: Array<IAPIEmoji>;
    epics: Array<IAPIEpic>;
    groups: IAPIGroups;
    identity?: Maybe<IAPIUser>;
    messages: Array<IAPIMessage>;
    node?: Maybe<IAPINode>;
    projects: IAPIProjects;
    recentEmojis: Array<IAPIEmoji>;
    roles: Array<IAPIRole>;
    tasks: IAPITasks;
    topics: IAPITopics;
    transactions: IAPITransactions;
    unreads: Array<IAPIUnread>;
    users: IAPIUsers;
    workspaces: Array<IAPIWorkspace>;
};

export type IAPIQueryAttachmentsArgs = {
    filter: IAPIMessagesFilter;
};

export type IAPIQueryBillsArgs = {
    filter: IAPIBillsFilter;
    pagination?: InputMaybe<IAPIPagination>;
    sort?: InputMaybe<Array<IAPIBillsSort>>;
};

export type IAPIQueryBillsPayableArgs = {
    filter: IAPIBillsPayableFilter;
    pagination?: InputMaybe<IAPIPagination>;
    sort?: InputMaybe<Array<IAPIBillsPayableSort>>;
};

export type IAPIQueryDashboardsArgs = {
    filter: IAPIDashboardsFilter;
    sort?: InputMaybe<Array<IAPIDashboardsSort>>;
};

export type IAPIQueryDocumentsArgs = {
    filter: IAPIDocumentsFilter;
    pagination?: InputMaybe<IAPIPagination>;
    sort?: InputMaybe<Array<IAPIDocumentsSort>>;
};

export type IAPIQueryEpicsArgs = {
    filter: IAPIEpicsFilter;
};

export type IAPIQueryGroupsArgs = {
    filter: IAPIGroupsFilter;
    pagination?: InputMaybe<IAPIPagination>;
    sort?: InputMaybe<Array<IAPIGroupsSort>>;
};

export type IAPIQueryIdentityArgs = {
    withSubscription: Scalars['Boolean']['input'];
};

export type IAPIQueryMessagesArgs = {
    filter: IAPIMessagesFilter;
};

export type IAPIQueryNodeArgs = {
    id: Scalars['ID']['input'];
};

export type IAPIQueryProjectsArgs = {
    filter: IAPIProjectsFilter;
    pagination?: InputMaybe<IAPIPagination>;
    sort?: InputMaybe<Array<IAPIProjectsSort>>;
};

export type IAPIQueryRecentEmojisArgs = {
    limit: Scalars['Int']['input'];
};

export type IAPIQueryRolesArgs = {
    filter?: InputMaybe<IAPIRolesFilter>;
};

export type IAPIQueryTasksArgs = {
    filter: IAPITasksFilter;
    pagination?: InputMaybe<IAPIPagination>;
    sort?: InputMaybe<Array<IAPITasksSort>>;
};

export type IAPIQueryTopicsArgs = {
    filter: IAPITopicsFilter;
    pagination?: InputMaybe<IAPIPagination>;
    sort?: InputMaybe<Array<IAPITopicsSort>>;
};

export type IAPIQueryTransactionsArgs = {
    filter: IAPITransactionsFilter;
    pagination?: InputMaybe<IAPIPagination>;
    sort?: InputMaybe<Array<IAPITransactionsSort>>;
};

export type IAPIQueryUnreadsArgs = {
    filter: IAPIUnreadsFilter;
};

export type IAPIQueryUsersArgs = {
    filter: IAPIUsersFilter;
    pagination?: InputMaybe<IAPIPagination>;
    sort?: InputMaybe<Array<IAPIUsersSort>>;
};

export type IAPIQueryWorkspacesArgs = {
    filter: IAPIWorkspacesFilter;
};

export type RemoveAction = 'ARCHIVE' | 'DELETE' | 'UNARCHIVE';

export type IAPIRemoveAvatarResult = {
    __typename: 'RemoveAvatarResult';
    s3Key?: Maybe<Scalars['String']['output']>;
};

export type IAPIRole = IAPINode & {
    __typename: 'Role';
    id: Scalars['ID']['output'];
    name: UserRole;
    policy: Scalars['String']['output'];
};

export type IAPIRolesFilter = {
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    name?: InputMaybe<IAPIStringFilter>;
};

export type IAPISelection = {
    __typename: 'Selection';
    anchor?: Maybe<Scalars['Int']['output']>;
    head?: Maybe<Scalars['Int']['output']>;
    messageId?: Maybe<Scalars['ID']['output']>;
    messageVersion?: Maybe<Scalars['Int']['output']>;
    time?: Maybe<Scalars['Long']['output']>;
    type?: Maybe<SelectionType>;
    userDisplayName: Scalars['String']['output'];
    userId: Scalars['ID']['output'];
};

export type SelectionType = 'ALL' | 'NODE' | 'TEXT';

export type IAPISelectionUpdateBroadcast = {
    anchor?: InputMaybe<Scalars['Int']['input']>;
    head?: InputMaybe<Scalars['Int']['input']>;
    messageId?: InputMaybe<Scalars['ID']['input']>;
    messageVersion?: InputMaybe<Scalars['Int']['input']>;
    time?: InputMaybe<Scalars['Long']['input']>;
    type?: InputMaybe<SelectionType>;
    userDisplayName: Scalars['String']['input'];
    userId: Scalars['ID']['input'];
};

export type IAPISelectionUpdateBroadcastResult = {
    __typename: 'SelectionUpdateBroadcastResult';
    channelId: Scalars['ID']['output'];
    clientId: Scalars['ID']['output'];
    selection: IAPISelection;
};

export type SortDirection = 'ASC' | 'DESC';

export type IAPIStringFilter = {
    equal?: InputMaybe<Scalars['String']['input']>;
    exclude?: InputMaybe<Array<Scalars['String']['input']>>;
    include?: InputMaybe<Array<Scalars['String']['input']>>;
    isNull?: InputMaybe<Scalars['Boolean']['input']>;
    joiner?: InputMaybe<Joiner>;
    like?: InputMaybe<Scalars['String']['input']>;
    notEqual?: InputMaybe<Scalars['String']['input']>;
    sql?: InputMaybe<Scalars['String']['input']>;
    startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type IAPISubscription = {
    __typename: 'Subscription';
    attachmentRemoved?: Maybe<IAPIAttachmentMutationResult>;
    attachmentUpdated?: Maybe<IAPIAttachmentMutationResult>;
    dashboardCreated?: Maybe<IAPIDashboardMutationResult>;
    dashboardRemoved?: Maybe<IAPIDashboardMutationResult>;
    dashboardUpdated?: Maybe<IAPIDashboardMutationResult>;
    documentCreated?: Maybe<IAPIDocumentMutationResult>;
    documentRemoved?: Maybe<IAPIDocumentMutationResult>;
    documentUpdated?: Maybe<IAPIDocumentMutationResult>;
    epicRemoved?: Maybe<IAPIEpic>;
    epicUpdated?: Maybe<IAPIEpic>;
    groupUpdated?: Maybe<IAPIGroup>;
    messageRemoved?: Maybe<IAPIMessageMutationResult>;
    messageUpdated?: Maybe<IAPIMessageMutationResult>;
    projectCreated?: Maybe<IAPIProjectMutationResult>;
    projectRemoved?: Maybe<IAPIProjectMutationResult>;
    projectUpdated?: Maybe<IAPIProjectMutationResult>;
    selectionUpdated?: Maybe<IAPISelectionUpdateBroadcastResult>;
    taskCreated?: Maybe<IAPITaskMutationResult>;
    taskRemoved?: Maybe<IAPITaskMutationResult>;
    taskUpdated?: Maybe<IAPITaskMutationResult>;
    topicCreated?: Maybe<IAPITopicMutationResult>;
    topicRemoved?: Maybe<IAPITopicMutationResult>;
    topicUpdated?: Maybe<IAPITopicMutationResult>;
    userUpdated?: Maybe<IAPIUserUpdateBroadcastResult>;
    workspaceRemoved?: Maybe<IAPIWorkspace>;
    workspaceUpdated?: Maybe<IAPIWorkspace>;
};

export type IAPISubscriptionAttachmentRemovedArgs = {
    channelId: Scalars['ID']['input'];
};

export type IAPISubscriptionAttachmentUpdatedArgs = {
    channelId: Scalars['ID']['input'];
};

export type IAPISubscriptionDashboardCreatedArgs = {
    ownerId: Scalars['ID']['input'];
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionDashboardRemovedArgs = {
    ownerId: Scalars['ID']['input'];
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionDashboardUpdatedArgs = {
    ownerId: Scalars['ID']['input'];
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionDocumentCreatedArgs = {
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionDocumentRemovedArgs = {
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionDocumentUpdatedArgs = {
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionEpicRemovedArgs = {
    workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPISubscriptionEpicUpdatedArgs = {
    workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPISubscriptionGroupUpdatedArgs = {
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionMessageRemovedArgs = {
    channelId: Scalars['ID']['input'];
};

export type IAPISubscriptionMessageUpdatedArgs = {
    channelId: Scalars['ID']['input'];
};

export type IAPISubscriptionProjectCreatedArgs = {
    workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPISubscriptionProjectRemovedArgs = {
    workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPISubscriptionProjectUpdatedArgs = {
    workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPISubscriptionSelectionUpdatedArgs = {
    channelId: Scalars['ID']['input'];
};

export type IAPISubscriptionTaskCreatedArgs = {
    filter: IAPISubscriptionFilter;
};

export type IAPISubscriptionTaskRemovedArgs = {
    filter: IAPISubscriptionFilter;
};

export type IAPISubscriptionTaskUpdatedArgs = {
    filter: IAPISubscriptionFilter;
};

export type IAPISubscriptionTopicCreatedArgs = {
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionTopicRemovedArgs = {
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionTopicUpdatedArgs = {
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionUserUpdatedArgs = {
    workspaceId: Scalars['ID']['input'];
};

export type IAPISubscriptionFilter = {
    excludedProjectIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    projectId?: InputMaybe<Scalars['ID']['input']>;
    roleId: Scalars['ID']['input'];
    subscribeToAll?: InputMaybe<Scalars['Boolean']['input']>;
    userId: Scalars['ID']['input'];
    workspaceId: Scalars['ID']['input'];
};

export type IAPISuspension = {
    __typename: 'Suspension';
    id: Scalars['ID']['output'];
    initiatorId: Scalars['ID']['output'];
    suspendedAt: Scalars['AWSDateTime']['output'];
};

export type IAPISuspensionBroadcast = {
    id: Scalars['ID']['input'];
    initiatorId: Scalars['ID']['input'];
    suspendedAt: Scalars['AWSDateTime']['input'];
};

export type IAPITask = IAPINode & {
    __typename: 'Task';
    acl?: Maybe<IAPITaskAcl>;
    createdAt: Scalars['String']['output'];
    creatorAlignments?: Maybe<IAPICreatorAlignments>;
    creatorId: Scalars['ID']['output'];
    epicId?: Maybe<Scalars['ID']['output']>;
    estimate?: Maybe<Scalars['Float']['output']>;
    hasMentions: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    links: Array<IAPITaskLink>;
    messagedAt?: Maybe<Scalars['String']['output']>;
    milestoneId?: Maybe<Scalars['ID']['output']>;
    name: Scalars['String']['output'];
    ownerId?: Maybe<Scalars['ID']['output']>;
    priority?: Maybe<TaskPriority>;
    projectId?: Maybe<Scalars['ID']['output']>;
    status: TaskStatus;
    unreads: Scalars['Int']['output'];
    updatedAt: Scalars['String']['output'];
    workspaceId: Scalars['ID']['output'];
};

export type IAPITaskAcl = {
    __typename: 'TaskAcl';
    canToggleAffiliateVisibility?: Maybe<IAPIAclEntities>;
    canUpdateLinks?: Maybe<IAPIAclEntities>;
    canUpdateOwner?: Maybe<IAPIAclEntities>;
    create?: Maybe<IAPIAclEntities>;
    exclusiveAccess?: Maybe<Scalars['Boolean']['output']>;
    remove?: Maybe<IAPIAclEntities>;
    update?: Maybe<IAPIAclEntities>;
    view?: Maybe<IAPIAclEntities>;
};

export type IAPITaskAclBroadcast = {
    canToggleAffiliateVisibility?: InputMaybe<IAPIAclEntitiesBroadcast>;
    canUpdateLinks?: InputMaybe<IAPIAclEntitiesBroadcast>;
    canUpdateOwner?: InputMaybe<IAPIAclEntitiesBroadcast>;
    create?: InputMaybe<IAPIAclEntitiesBroadcast>;
    exclusiveAccess?: InputMaybe<Scalars['Boolean']['input']>;
    remove?: InputMaybe<IAPIAclEntitiesBroadcast>;
    update?: InputMaybe<IAPIAclEntitiesBroadcast>;
    view?: InputMaybe<IAPIAclEntitiesBroadcast>;
};

export type IAPITaskBulkInput = {
    ownerId?: InputMaybe<Scalars['ID']['input']>;
    priority?: InputMaybe<TaskPriority>;
    projectId?: InputMaybe<Scalars['ID']['input']>;
    status?: InputMaybe<TaskStatus>;
};

export type IAPITaskInput = {
    estimate?: InputMaybe<Scalars['Float']['input']>;
    latestMessageCreatorId?: InputMaybe<Scalars['ID']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    ownerId?: InputMaybe<Scalars['ID']['input']>;
    priority?: InputMaybe<TaskPriority>;
    projectId?: InputMaybe<Scalars['ID']['input']>;
    status?: InputMaybe<TaskStatus>;
};

export type IAPITaskLink = {
    __typename: 'TaskLink';
    fromId: Scalars['ID']['output'];
    id: Scalars['ID']['output'];
    linkType: TaskLinkType;
    name: Scalars['String']['output'];
    status: TaskStatus;
    toId: Scalars['ID']['output'];
};

export type IAPITaskLinkBroadcast = {
    fromId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    linkType: TaskLinkType;
    name: Scalars['String']['input'];
    status: TaskStatus;
    toId: Scalars['ID']['input'];
};

export type IAPITaskLinkFrom = {
    __typename: 'TaskLinkFrom';
    fromId: Scalars['ID']['output'];
    linkType: TaskLinkType;
};

export type TaskLinkType = 'BLOCKS' | 'CAUSES' | 'RELATES';

export type IAPITaskMutationResult = IAPIMutationResult & {
    __typename: 'TaskMutationResult';
    clientId: Scalars['ID']['output'];
    task: IAPITask;
};

export type TaskPriority = 'CRITICAL' | 'HIGH' | 'LOW' | 'MEDIUM';

export type IAPITaskRemoveBroadcast = {
    acl?: InputMaybe<IAPITaskAclBroadcast>;
    createdAt: Scalars['String']['input'];
    creatorAlignments?: InputMaybe<IAPICreatorAlignmentsBroadcast>;
    creatorId: Scalars['ID']['input'];
    epicId?: InputMaybe<Scalars['ID']['input']>;
    estimate?: InputMaybe<Scalars['Float']['input']>;
    hasMentions: Scalars['Boolean']['input'];
    id: Scalars['ID']['input'];
    links: Array<IAPITaskLinkBroadcast>;
    messagedAt?: InputMaybe<Scalars['String']['input']>;
    milestoneId?: InputMaybe<Scalars['ID']['input']>;
    name: Scalars['String']['input'];
    ownerId?: InputMaybe<Scalars['ID']['input']>;
    priority?: InputMaybe<TaskPriority>;
    projectId?: InputMaybe<Scalars['ID']['input']>;
    status: TaskStatus;
    unreads: Scalars['Int']['input'];
    updatedAt: Scalars['String']['input'];
    workspaceId: Scalars['ID']['input'];
};

export type TaskStatus = 'CANCELLED' | 'DONE' | 'IN_PROGRESS' | 'IN_REVIEW' | 'TRIAGE' | 'UP_NEXT';

export type IAPITaskUpdateBroadcast = {
    acl?: InputMaybe<IAPITaskAclBroadcast>;
    createdAt: Scalars['String']['input'];
    creatorAlignments?: InputMaybe<IAPICreatorAlignmentsBroadcast>;
    creatorId: Scalars['ID']['input'];
    epicId?: InputMaybe<Scalars['ID']['input']>;
    estimate?: InputMaybe<Scalars['Float']['input']>;
    hasMentions: Scalars['Boolean']['input'];
    id: Scalars['ID']['input'];
    links: Array<IAPITaskLinkBroadcast>;
    messagedAt?: InputMaybe<Scalars['String']['input']>;
    milestoneId?: InputMaybe<Scalars['ID']['input']>;
    name: Scalars['String']['input'];
    ownerId?: InputMaybe<Scalars['ID']['input']>;
    priority?: InputMaybe<TaskPriority>;
    projectId?: InputMaybe<Scalars['ID']['input']>;
    status: TaskStatus;
    unreads: Scalars['Int']['input'];
    updatedAt: Scalars['String']['input'];
    workspaceId: Scalars['ID']['input'];
};

export type IAPITasks = {
    __typename: 'Tasks';
    allIds?: Maybe<Array<Scalars['Int']['output']>>;
    tasks: Array<IAPITask>;
};

export type IAPITasksFilter = {
    creatorId?: InputMaybe<IAPIIdFilter>;
    epicId?: InputMaybe<IAPIIdFilter>;
    estimate?: InputMaybe<IAPINumberFilter>;
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    milestoneId?: InputMaybe<IAPIIdFilter>;
    name?: InputMaybe<IAPIStringFilter>;
    ownerId?: InputMaybe<IAPIIdFilter>;
    priority?: InputMaybe<IAPIStringFilter>;
    projectId?: InputMaybe<IAPIIdFilter>;
    status?: InputMaybe<IAPIStringFilter>;
    unreads?: InputMaybe<IAPINumberFilter>;
    workspaceId?: InputMaybe<IAPIIdFilter>;
};

export type IAPITasksSort = {
    createdAt?: InputMaybe<SortDirection>;
    estimate?: InputMaybe<SortDirection>;
    id?: InputMaybe<SortDirection>;
    messagedAt?: InputMaybe<SortDirection>;
    name?: InputMaybe<SortDirection>;
    ownerDisplayName?: InputMaybe<SortDirection>;
    priority?: InputMaybe<SortDirection>;
    status?: InputMaybe<SortDirection>;
    unreads?: InputMaybe<SortDirection>;
    updatedAt?: InputMaybe<SortDirection>;
};

export type IAPITopic = IAPINode & {
    __typename: 'Topic';
    createdAt: Scalars['String']['output'];
    creatorAlignments?: Maybe<IAPICreatorAlignments>;
    hasMentions: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    messagedAt?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    projectId?: Maybe<Scalars['ID']['output']>;
    unreads: Scalars['Int']['output'];
    updatedAt: Scalars['String']['output'];
    workspaceId: Scalars['ID']['output'];
};

export type IAPITopicInput = {
    latestMessageCreatorId?: InputMaybe<Scalars['ID']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type IAPITopicMutationResult = IAPIMutationResult & {
    __typename: 'TopicMutationResult';
    clientId: Scalars['ID']['output'];
    topic: IAPITopic;
    workspaceId: Scalars['ID']['output'];
};

export type IAPITopics = {
    __typename: 'Topics';
    allIds?: Maybe<Array<Scalars['Int']['output']>>;
    topics: Array<IAPITopic>;
};

export type IAPITopicsFilter = {
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    name?: InputMaybe<IAPIStringFilter>;
    projectId?: InputMaybe<IAPIIdFilter>;
    unreads?: InputMaybe<IAPINumberFilter>;
    workspaceId?: InputMaybe<IAPIIdFilter>;
};

export type IAPITopicsSort = {
    createdAt?: InputMaybe<SortDirection>;
    id?: InputMaybe<SortDirection>;
    messagedAt?: InputMaybe<SortDirection>;
    name?: InputMaybe<SortDirection>;
    unreads?: InputMaybe<SortDirection>;
    updatedAt?: InputMaybe<SortDirection>;
};

export type IAPITransaction = IAPINode & {
    __typename: 'Transaction';
    id: Scalars['ID']['output'];
    method: TransactionMethod;
    processedAt: Scalars['AWSDateTime']['output'];
    receiptUrl: Scalars['String']['output'];
    total: Scalars['Float']['output'];
    userId: Scalars['ID']['output'];
};

export type TransactionMethod = 'BALANCE' | 'CARD' | 'PAYPAL';

export type IAPITransactions = {
    __typename: 'Transactions';
    allIds?: Maybe<Array<Scalars['Int']['output']>>;
    transactions: Array<IAPITransaction>;
};

export type IAPITransactionsFilter = {
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    total?: InputMaybe<IAPINumberFilter>;
    userId?: InputMaybe<IAPIIdFilter>;
};

export type IAPITransactionsSort = {
    id?: InputMaybe<SortDirection>;
    processedAt?: InputMaybe<SortDirection>;
    total?: InputMaybe<SortDirection>;
};

export type IAPIUnread = IAPINode & {
    __typename: 'Unread';
    id: Scalars['ID']['output'];
    messageCreatedAt: Scalars['AWSDateTime']['output'];
    messageId: Scalars['ID']['output'];
    messageUpdatedAt: Scalars['AWSDateTime']['output'];
    wasMentionedSinceWatched: Scalars['Boolean']['output'];
};

export type IAPIUnreadCounter = {
    __typename: 'UnreadCounter';
    hasMentions: Scalars['Boolean']['output'];
    type: DashboardType;
    unreads: Scalars['Int']['output'];
};

export type IAPIUnreadsFilter = {
    channelId: Scalars['ID']['input'];
};

export type IAPIUpdateAvatarResult = {
    __typename: 'UpdateAvatarResult';
    uploadURL: Scalars['String']['output'];
};

export type IAPIUpdateUserInWorkspaceResult = {
    __typename: 'UpdateUserInWorkspaceResult';
    added?: Maybe<Scalars['Boolean']['output']>;
    userId?: Maybe<Scalars['ID']['output']>;
    workspaceId?: Maybe<Scalars['ID']['output']>;
};

export type IAPIUser = IAPINode & {
    __typename: 'User';
    avatarS3Key?: Maybe<Scalars['String']['output']>;
    calculatedName: Scalars['String']['output'];
    createdAt: Scalars['AWSDateTime']['output'];
    displayName: Scalars['String']['output'];
    email: Scalars['AWSEmail']['output'];
    firstname?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    invitedToWorkspaceIds: Array<Scalars['ID']['output']>;
    lastname?: Maybe<Scalars['String']['output']>;
    paddleSubscription?: Maybe<IAPIPaddleSubscription>;
    paddleSubscriptionId?: Maybe<Scalars['Int']['output']>;
    status: UserStatus;
    timezone: Scalars['String']['output'];
    updatedAt: Scalars['AWSDateTime']['output'];
    workspaceData: Array<IAPIWorkspaceData>;
};

export type IAPIUserInput = {
    avatarS3Key?: InputMaybe<Scalars['String']['input']>;
    displayName?: InputMaybe<Scalars['String']['input']>;
    firstname?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['ID']['input'];
    lastname?: InputMaybe<Scalars['String']['input']>;
    password?: InputMaybe<IAPIPasswordUpdateInput>;
    timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UserRole = 'ADMIN' | 'AFFILIATE' | 'MEMBER' | 'OWNER';

export type UserStatus = 'BANNED' | 'INITIAL' | 'INVITED' | 'REGISTERED';

export type IAPIUserUpdateBroadcast = {
    avatarS3Key?: InputMaybe<Scalars['String']['input']>;
    calculatedName: Scalars['String']['input'];
    createdAt: Scalars['AWSDateTime']['input'];
    displayName: Scalars['String']['input'];
    email: Scalars['AWSEmail']['input'];
    firstname?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['ID']['input'];
    invitedToWorkspaceIds: Array<Scalars['ID']['input']>;
    lastname?: InputMaybe<Scalars['String']['input']>;
    paddleSubscriptionId?: InputMaybe<Scalars['Int']['input']>;
    status: UserStatus;
    timezone: Scalars['String']['input'];
    updatedAt: Scalars['AWSDateTime']['input'];
    workspaceData: Array<IAPIWorkspaceDataBroadcast>;
};

export type IAPIUserUpdateBroadcastResult = {
    __typename: 'UserUpdateBroadcastResult';
    user: IAPIUser;
    workspaceId: Scalars['ID']['output'];
};

export type IAPIUsers = {
    __typename: 'Users';
    allIds?: Maybe<Array<Scalars['Int']['output']>>;
    users: Array<IAPIUser>;
};

export type IAPIUsersFilter = {
    displayName?: InputMaybe<IAPIStringFilter>;
    email?: InputMaybe<IAPIStringFilter>;
    id?: InputMaybe<IAPIIdFilter>;
    invitedToWorkspaceIds?: InputMaybe<IAPIIDsFilter>;
    joiner?: InputMaybe<Joiner>;
    role?: InputMaybe<IAPIStringFilter>;
    workspaceIds?: InputMaybe<IAPIIDsFilter>;
};

export type IAPIUsersSort = {
    calculatedName?: InputMaybe<SortDirection>;
    createdAt?: InputMaybe<SortDirection>;
    displayName?: InputMaybe<SortDirection>;
    email?: InputMaybe<SortDirection>;
    firstname?: InputMaybe<SortDirection>;
    id?: InputMaybe<SortDirection>;
    lastname?: InputMaybe<SortDirection>;
    status?: InputMaybe<SortDirection>;
    timezone?: InputMaybe<SortDirection>;
    updatedAt?: InputMaybe<SortDirection>;
};

export type IAPIWatchMessageResult = {
    __typename: 'WatchMessageResult';
    hasMentions: Scalars['Boolean']['output'];
    unreads: Scalars['Int']['output'];
};

export type IAPIWorkspace = IAPINode & {
    __typename: 'Workspace';
    createdAt: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    motto?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    ownerId: Scalars['ID']['output'];
    unreadCounters?: Maybe<Array<IAPIUnreadCounter>>;
    updatedAt: Scalars['String']['output'];
};

export type IAPIWorkspaceData = {
    __typename: 'WorkspaceData';
    accessList: Array<IAPIAccessListItem>;
    suspension?: Maybe<IAPISuspension>;
    workspaceId: Scalars['ID']['output'];
};

export type IAPIWorkspaceDataBroadcast = {
    accessList: Array<IAPIAccessListItemBroadcast>;
    suspension?: InputMaybe<IAPISuspensionBroadcast>;
    workspaceId: Scalars['ID']['input'];
};

export type IAPIWorkspaceInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
    motto?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type IAPIWorkspacesFilter = {
    id?: InputMaybe<IAPIIdFilter>;
    joiner?: InputMaybe<Joiner>;
    name?: InputMaybe<IAPIStringFilter>;
};
