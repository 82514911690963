import { setContext } from '@apollo/client/link/context';
import { TApolloClientConfig } from '../types';
import { APP_TOKEN_HEADER, ELSKeys } from '@ab-task/data';
import { ApolloLink, Observable } from '@apollo/client';

export function getAuthLink(config: TApolloClientConfig) {
    switch (config.environment) {
        case 'node': {
            const appToken = config.appToken;

            return setContext((operation, { headers }) => {
                return {
                    headers: {
                        ...headers,
                        [APP_TOKEN_HEADER]: appToken,
                    },
                };
            });
        }

        case 'browser': {
            const authLink = setContext((operation, { headers }) => {
                /*
                // Debug graphql requests
                
                if (operation.operationName === 'FetchTasks') {
                    console.warn('Sending FetchTasks', operation.variables);
                }
                */

                const appToken = localStorage.getItem(ELSKeys.AppToken);

                if (appToken !== null) {
                    return {
                        headers: {
                            ...headers,
                            [APP_TOKEN_HEADER]: appToken,
                        },
                    };
                }

                return {};
            });

            const abortLink = new ApolloLink((operation, forward) => {
                const { headers } = operation.getContext();

                if (
                    (!headers || !headers[APP_TOKEN_HEADER]) &&
                    operation.operationName === 'FetchWorkspaces'
                ) {
                    return new Observable(observer => {
                        observer.error(
                            new Error(`Query aborted due to missing "${APP_TOKEN_HEADER}" header`)
                        );
                    });
                }

                return forward(operation);
            });

            return ApolloLink.from([authLink, abortLink]);
        }
    }
}
