import { gql } from '@apollo/client';

const schema = gql`
    extend type Dashboard {
        expanded: Boolean
    }

    extend type Message {
        isIncoming: Boolean
        isBig: Boolean
        isReadonly: Boolean
        attachmentS3Keys: [String!]
    }

    extend type Attachment {
        progress: Int
    }
`;

export default schema;
