import type DB from '@ab-task/database';
import { APIGatewayProxyEvent } from 'aws-lambda';
import type bunyan from 'bunyan';
import { IObject, IRole, ISession, IUser, UserRole } from '..';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { TPaddleEvent } from '../paddle';
import { IFilledArray, StaticFunctionNames } from '../utilities';

export interface IAppSyncEvent<TFieldName extends string, TArgs = IObject>
    extends APIGatewayProxyEvent {
    fieldName: TFieldName;
    parentTypeName: string;
    variables: IObject<string>;
    selectionSetList: string[];
    selectionSetGraphQL: string;
    arguments: TArgs;
    identity: IObject;
}

// https://docs.aws.amazon.com/AmazonS3/latest/userguide/notification-content-structure.html
export interface ICashierEvent extends APIGatewayProxyEvent {
    awslogs?: { data: string };
    Records?: Array<{
        eventName: string;
        s3?: {
            bucket: { name: string };
            object: { key: string; size: number };
        };
    }>;
}

export interface ICronEvent<TJobName extends string> {
    job: TJobName;
}

export type TAPIAppSyncResponse = IObject | boolean | number | string | null;

// Reference https://docs.aws.amazon.com/apigateway/latest/developerguide/api-gateway-integration-settings-integration-response.html
export type TAPIGatewayProxyResponse = {
    statusCode: '200' | '500';
    headers?: Record<string, string>;
    body?: string;
    isBase64Encoded: boolean;
};

export interface IBaseContext {
    db: DB;
    logger: bunyan;
    apolloClient?: ApolloClient<NormalizedCacheObject>;
    remoteClientId: string;
}

export interface IAPIBaseContext extends IBaseContext {
    selectionSetList?: string[];
    headers: IObject<string>;
    availableRoles: TAvailableRoles;
}

export interface IAPIUserContext extends IAPIBaseContext {
    user: IUser;
    session: ISession;
}

export type TAvailableRoles = Record<UserRole, IRole>;

export type TAPIContext = IAPIBaseContext | IAPIUserContext;

export function isIAPIUserContext(context: TAPIContext): context is IAPIUserContext {
    return (
        (context as IAPIUserContext).user !== undefined &&
        (context as IAPIUserContext).session !== undefined
    );
}

export interface IModelAdaptor {
    (model: any): Record<string, any> | Promise<Record<string, any>> | Promise<boolean> | boolean;
}
export interface IMLHandlerSettings {
    mustExist?: boolean;
    adaptors?: IModelAdaptor[];
}

export type TMLModelsQueryResult<T> = [number[], Array<T>];
export type TMLModelsQueryFilledResult<T> = [number[], IFilledArray<T>];

export interface IAPIOpenHandler<P = IObject, R = TAPIAppSyncResponse> {
    isOpen: true;
    (payload: P, context: IAPIBaseContext): Promise<R>;
}

export interface IAPIPaddleHandler<P extends TPaddleEvent> {
    (payload: P, context: Omit<IAPIUserContext, 'session'>): Promise<void>;
}

export interface IJobHandler {
    (context: IBaseContext): Promise<void>;
}

export interface IAPIProtectedHandler<P = IObject, R = TAPIAppSyncResponse> {
    (payload: P, context: IAPIUserContext): Promise<R>;
}

export type TAPIHandler = IAPIOpenHandler | IAPIProtectedHandler;

export function isAPIOpenHandler(handler: TAPIHandler): handler is IAPIOpenHandler {
    return (handler as IAPIOpenHandler).isOpen === true;
}

export type TDBPrimitive = null | string | number | boolean | Date | Object;

export interface ICWReport {
    messageType: 'DATA_MESSAGE';
    owner: string;
    logGroup: string;
    logStream: string;
    subscriptionFilters: string[];
    logEvents: ICWEvent[];
    time: string;
}

export interface ICWEvent {
    id: string;
    timestamp: number;
    message: string;
}

export type TErrorCodes<T> = Exclude<StaticFunctionNames<T>, 'captureStackTrace'>;
