import { IWorkspaceContext } from '@ab-task/types';
import { rvCurrentWorkspaceId, rvWorkspaceContexts } from '../variables';

export function getCurrentWorkspaceContext(): IWorkspaceContext | undefined {
    const currentWorkspaceId = rvCurrentWorkspaceId();

    if (typeof currentWorkspaceId !== 'number') {
        return;
    }

    return rvWorkspaceContexts()[currentWorkspaceId];
}
