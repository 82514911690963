/* eslint-disable */
import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc, RoleFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchUserQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchUserQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Emoji' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Task' }
        | { __typename: 'Topic' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | {
              __typename: 'User';
              id: string;
              invitedToWorkspaceIds: Array<string>;
              email: string;
              firstname?: string | null;
              lastname?: string | null;
              displayName: string;
              calculatedName: string;
              timezone: string;
              status: Types.UserStatus;
              avatarS3Key?: string | null;
              paddleSubscriptionId?: number | null;
              createdAt: string;
              updatedAt: string;
              workspaceData: Array<{
                  __typename: 'WorkspaceData';
                  workspaceId: string;
                  suspension?: {
                      __typename: 'Suspension';
                      id: string;
                      initiatorId: string;
                      suspendedAt: string;
                  } | null;
                  accessList: Array<{
                      __typename: 'AccessListItem';
                      projectId?: string | null;
                      roleId: string;
                  }>;
              }>;
          }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchUsersQueryVariables = Types.Exact<{
    filter: Types.IAPIUsersFilter;
    sort?: Types.InputMaybe<Array<Types.IAPIUsersSort> | Types.IAPIUsersSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchUsersQuery = {
    __typename: 'Query';
    users: {
        __typename: 'Users';
        allIds?: Array<number> | null;
        users: Array<{
            __typename: 'User';
            id: string;
            invitedToWorkspaceIds: Array<string>;
            email: string;
            firstname?: string | null;
            lastname?: string | null;
            displayName: string;
            calculatedName: string;
            timezone: string;
            status: Types.UserStatus;
            avatarS3Key?: string | null;
            paddleSubscriptionId?: number | null;
            createdAt: string;
            updatedAt: string;
            workspaceData: Array<{
                __typename: 'WorkspaceData';
                workspaceId: string;
                suspension?: {
                    __typename: 'Suspension';
                    id: string;
                    initiatorId: string;
                    suspendedAt: string;
                } | null;
                accessList: Array<{
                    __typename: 'AccessListItem';
                    projectId?: string | null;
                    roleId: string;
                }>;
            }>;
        }>;
    };
};

export type IAPIUpdateUserMutationVariables = Types.Exact<{
    user: Types.IAPIUserInput;
}>;

export type IAPIUpdateUserMutation = {
    __typename: 'Mutation';
    updateUser: {
        __typename: 'User';
        id: string;
        invitedToWorkspaceIds: Array<string>;
        email: string;
        firstname?: string | null;
        lastname?: string | null;
        displayName: string;
        calculatedName: string;
        timezone: string;
        status: Types.UserStatus;
        avatarS3Key?: string | null;
        paddleSubscriptionId?: number | null;
        createdAt: string;
        updatedAt: string;
        workspaceData: Array<{
            __typename: 'WorkspaceData';
            workspaceId: string;
            suspension?: {
                __typename: 'Suspension';
                id: string;
                initiatorId: string;
                suspendedAt: string;
            } | null;
            accessList: Array<{
                __typename: 'AccessListItem';
                projectId?: string | null;
                roleId: string;
            }>;
        }>;
    };
};

export type IAPISetUserSuspensionMutationVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    userId: Types.Scalars['ID']['input'];
    suspend: Types.Scalars['Boolean']['input'];
}>;

export type IAPISetUserSuspensionMutation = {
    __typename: 'Mutation';
    setUserSuspension: {
        __typename: 'User';
        id: string;
        invitedToWorkspaceIds: Array<string>;
        email: string;
        firstname?: string | null;
        lastname?: string | null;
        displayName: string;
        calculatedName: string;
        timezone: string;
        status: Types.UserStatus;
        avatarS3Key?: string | null;
        paddleSubscriptionId?: number | null;
        createdAt: string;
        updatedAt: string;
        workspaceData: Array<{
            __typename: 'WorkspaceData';
            workspaceId: string;
            suspension?: {
                __typename: 'Suspension';
                id: string;
                initiatorId: string;
                suspendedAt: string;
            } | null;
            accessList: Array<{
                __typename: 'AccessListItem';
                projectId?: string | null;
                roleId: string;
            }>;
        }>;
    };
};

export type IAPISetUserAccessListMutationVariables = Types.Exact<{
    userId: Types.Scalars['ID']['input'];
    accessList: Types.IAPIAccessListInput;
}>;

export type IAPISetUserAccessListMutation = {
    __typename: 'Mutation';
    setUserAccessList: {
        __typename: 'User';
        id: string;
        invitedToWorkspaceIds: Array<string>;
        email: string;
        firstname?: string | null;
        lastname?: string | null;
        displayName: string;
        calculatedName: string;
        timezone: string;
        status: Types.UserStatus;
        avatarS3Key?: string | null;
        paddleSubscriptionId?: number | null;
        createdAt: string;
        updatedAt: string;
        workspaceData: Array<{
            __typename: 'WorkspaceData';
            workspaceId: string;
            suspension?: {
                __typename: 'Suspension';
                id: string;
                initiatorId: string;
                suspendedAt: string;
            } | null;
            accessList: Array<{
                __typename: 'AccessListItem';
                projectId?: string | null;
                roleId: string;
            }>;
        }>;
    };
};

export type IAPIBroadcastUserUpdateMutationVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    user: Types.IAPIUserUpdateBroadcast;
}>;

export type IAPIBroadcastUserUpdateMutation = {
    __typename: 'Mutation';
    broadcastUserUpdate: {
        __typename: 'UserUpdateBroadcastResult';
        workspaceId: string;
        user: {
            __typename: 'User';
            id: string;
            invitedToWorkspaceIds: Array<string>;
            email: string;
            firstname?: string | null;
            lastname?: string | null;
            displayName: string;
            calculatedName: string;
            timezone: string;
            status: Types.UserStatus;
            avatarS3Key?: string | null;
            paddleSubscriptionId?: number | null;
            createdAt: string;
            updatedAt: string;
            workspaceData: Array<{
                __typename: 'WorkspaceData';
                workspaceId: string;
                suspension?: {
                    __typename: 'Suspension';
                    id: string;
                    initiatorId: string;
                    suspendedAt: string;
                } | null;
                accessList: Array<{
                    __typename: 'AccessListItem';
                    projectId?: string | null;
                    roleId: string;
                }>;
            }>;
        };
    };
};

export type IAPIUserUpdatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPIUserUpdatedSubscription = {
    __typename: 'Subscription';
    userUpdated?: {
        __typename: 'UserUpdateBroadcastResult';
        workspaceId: string;
        user: {
            __typename: 'User';
            id: string;
            invitedToWorkspaceIds: Array<string>;
            email: string;
            firstname?: string | null;
            lastname?: string | null;
            displayName: string;
            calculatedName: string;
            timezone: string;
            status: Types.UserStatus;
            avatarS3Key?: string | null;
            paddleSubscriptionId?: number | null;
            createdAt: string;
            updatedAt: string;
            workspaceData: Array<{
                __typename: 'WorkspaceData';
                workspaceId: string;
                suspension?: {
                    __typename: 'Suspension';
                    id: string;
                    initiatorId: string;
                    suspendedAt: string;
                } | null;
                accessList: Array<{
                    __typename: 'AccessListItem';
                    projectId?: string | null;
                    roleId: string;
                }>;
            }>;
        };
    } | null;
};

export const FetchUserDocument = gql`
    query FetchUser($id: ID!) {
        node(id: $id) {
            ...UserFields
        }
    }
    ${UserFieldsFragmentDoc}
`;

/**
 * __useFetchUserQuery__
 *
 * To run a query within a React component, call `useFetchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchUserQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchUserQuery, IAPIFetchUserQueryVariables> &
        ({ variables: IAPIFetchUserQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchUserDocument, options);
    return Apollo.useQuery<IAPIFetchUserQuery, IAPIFetchUserQueryVariables>(
        FetchUserDocument,
        options
    );
}
export function useFetchUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchUserQuery, IAPIFetchUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchUserQuery, IAPIFetchUserQueryVariables>(
        FetchUserDocument,
        options
    );
}
export function useFetchUserSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchUserQuery, IAPIFetchUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchUserQuery, IAPIFetchUserQueryVariables>(
        FetchUserDocument,
        options
    );
}
export type FetchUserQueryHookResult = ReturnType<typeof useFetchUserQuery>;
export type FetchUserLazyQueryHookResult = ReturnType<typeof useFetchUserLazyQuery>;
export type FetchUserSuspenseQueryHookResult = ReturnType<typeof useFetchUserSuspenseQuery>;
export type FetchUserQueryResult = Apollo.QueryResult<
    IAPIFetchUserQuery,
    IAPIFetchUserQueryVariables
>;
export const FetchUsersDocument = gql`
    query FetchUsers($filter: UsersFilter!, $sort: [UsersSort!], $pagination: Pagination) {
        users(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            users {
                ...UserFields
            }
        }
    }
    ${UserFieldsFragmentDoc}
`;

/**
 * __useFetchUsersQuery__
 *
 * To run a query within a React component, call `useFetchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchUsersQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchUsersQuery, IAPIFetchUsersQueryVariables> &
        ({ variables: IAPIFetchUsersQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchUsersDocument, options);
    return Apollo.useQuery<IAPIFetchUsersQuery, IAPIFetchUsersQueryVariables>(
        FetchUsersDocument,
        options
    );
}
export function useFetchUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchUsersQuery, IAPIFetchUsersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchUsersQuery, IAPIFetchUsersQueryVariables>(
        FetchUsersDocument,
        options
    );
}
export function useFetchUsersSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchUsersQuery, IAPIFetchUsersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchUsersQuery, IAPIFetchUsersQueryVariables>(
        FetchUsersDocument,
        options
    );
}
export type FetchUsersQueryHookResult = ReturnType<typeof useFetchUsersQuery>;
export type FetchUsersLazyQueryHookResult = ReturnType<typeof useFetchUsersLazyQuery>;
export type FetchUsersSuspenseQueryHookResult = ReturnType<typeof useFetchUsersSuspenseQuery>;
export type FetchUsersQueryResult = Apollo.QueryResult<
    IAPIFetchUsersQuery,
    IAPIFetchUsersQueryVariables
>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UserInput!) {
        updateUser(user: $user) {
            ...UserFields
        }
    }
    ${UserFieldsFragmentDoc}
`;
export type IAPIUpdateUserMutationFn = Apollo.MutationFunction<
    IAPIUpdateUserMutation,
    IAPIUpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateUserMutation,
        IAPIUpdateUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateUserMutation, IAPIUpdateUserMutationVariables>(
        UpdateUserDocument,
        options
    );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<IAPIUpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateUserMutation,
    IAPIUpdateUserMutationVariables
>;
export const SetUserSuspensionDocument = gql`
    mutation SetUserSuspension($workspaceId: ID!, $userId: ID!, $suspend: Boolean!) {
        setUserSuspension(workspaceId: $workspaceId, userId: $userId, suspend: $suspend) {
            ...UserFields
        }
    }
    ${UserFieldsFragmentDoc}
`;
export type IAPISetUserSuspensionMutationFn = Apollo.MutationFunction<
    IAPISetUserSuspensionMutation,
    IAPISetUserSuspensionMutationVariables
>;

/**
 * __useSetUserSuspensionMutation__
 *
 * To run a mutation, you first call `useSetUserSuspensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserSuspensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserSuspensionMutation, { data, loading, error }] = useSetUserSuspensionMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      userId: // value for 'userId'
 *      suspend: // value for 'suspend'
 *   },
 * });
 */
export function useSetUserSuspensionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPISetUserSuspensionMutation,
        IAPISetUserSuspensionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPISetUserSuspensionMutation,
        IAPISetUserSuspensionMutationVariables
    >(SetUserSuspensionDocument, options);
}
export type SetUserSuspensionMutationHookResult = ReturnType<typeof useSetUserSuspensionMutation>;
export type SetUserSuspensionMutationResult = Apollo.MutationResult<IAPISetUserSuspensionMutation>;
export type SetUserSuspensionMutationOptions = Apollo.BaseMutationOptions<
    IAPISetUserSuspensionMutation,
    IAPISetUserSuspensionMutationVariables
>;
export const SetUserAccessListDocument = gql`
    mutation SetUserAccessList($userId: ID!, $accessList: AccessListInput!) {
        setUserAccessList(userId: $userId, accessList: $accessList) {
            ...UserFields
        }
    }
    ${UserFieldsFragmentDoc}
`;
export type IAPISetUserAccessListMutationFn = Apollo.MutationFunction<
    IAPISetUserAccessListMutation,
    IAPISetUserAccessListMutationVariables
>;

/**
 * __useSetUserAccessListMutation__
 *
 * To run a mutation, you first call `useSetUserAccessListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserAccessListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserAccessListMutation, { data, loading, error }] = useSetUserAccessListMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      accessList: // value for 'accessList'
 *   },
 * });
 */
export function useSetUserAccessListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPISetUserAccessListMutation,
        IAPISetUserAccessListMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPISetUserAccessListMutation,
        IAPISetUserAccessListMutationVariables
    >(SetUserAccessListDocument, options);
}
export type SetUserAccessListMutationHookResult = ReturnType<typeof useSetUserAccessListMutation>;
export type SetUserAccessListMutationResult = Apollo.MutationResult<IAPISetUserAccessListMutation>;
export type SetUserAccessListMutationOptions = Apollo.BaseMutationOptions<
    IAPISetUserAccessListMutation,
    IAPISetUserAccessListMutationVariables
>;
export const BroadcastUserUpdateDocument = gql`
    mutation BroadcastUserUpdate($workspaceId: ID!, $user: UserUpdateBroadcast!) {
        broadcastUserUpdate(workspaceId: $workspaceId, user: $user) {
            workspaceId
            user {
                ...UserFields
            }
        }
    }
    ${UserFieldsFragmentDoc}
`;
export type IAPIBroadcastUserUpdateMutationFn = Apollo.MutationFunction<
    IAPIBroadcastUserUpdateMutation,
    IAPIBroadcastUserUpdateMutationVariables
>;

/**
 * __useBroadcastUserUpdateMutation__
 *
 * To run a mutation, you first call `useBroadcastUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastUserUpdateMutation, { data, loading, error }] = useBroadcastUserUpdateMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useBroadcastUserUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIBroadcastUserUpdateMutation,
        IAPIBroadcastUserUpdateMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPIBroadcastUserUpdateMutation,
        IAPIBroadcastUserUpdateMutationVariables
    >(BroadcastUserUpdateDocument, options);
}
export type BroadcastUserUpdateMutationHookResult = ReturnType<
    typeof useBroadcastUserUpdateMutation
>;
export type BroadcastUserUpdateMutationResult =
    Apollo.MutationResult<IAPIBroadcastUserUpdateMutation>;
export type BroadcastUserUpdateMutationOptions = Apollo.BaseMutationOptions<
    IAPIBroadcastUserUpdateMutation,
    IAPIBroadcastUserUpdateMutationVariables
>;
export const UserUpdatedDocument = gql`
    subscription UserUpdated($workspaceId: ID!) {
        userUpdated(workspaceId: $workspaceId) {
            workspaceId
            user {
                ...UserFields
            }
        }
    }
    ${UserFieldsFragmentDoc}
`;

/**
 * __useUserUpdatedSubscription__
 *
 * To run a query within a React component, call `useUserUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUpdatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useUserUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIUserUpdatedSubscription,
        IAPIUserUpdatedSubscriptionVariables
    > &
        ({ variables: IAPIUserUpdatedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(UserUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIUserUpdatedSubscription,
            IAPIUserUpdatedSubscriptionVariables
        >(UserUpdatedDocument, options),
        connected,
    };
}
export type UserUpdatedSubscriptionHookResult = ReturnType<typeof useUserUpdatedSubscription>;
export type UserUpdatedSubscriptionResult = Apollo.SubscriptionResult<IAPIUserUpdatedSubscription>;
