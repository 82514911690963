import type { QueryError } from 'mysql2';

// see https://mariadb.com/kb/en/mariadb-error-codes/ for full list of codes
const retriableCodes = ['ER_LOCK_WAIT_TIMEOUT', 'ER_QUERY_TIMEOUT', 'ER_STATEMENT_TIMEOUT'];

export class DatabaseError extends Error {
    public params?: Record<string, any>;
    details?: string;
    errors: QueryError[] = [];
    isRetriable = false;

    get code() {
        const lastError = this.errors[this.errors.length - 1];

        return lastError ? lastError.code : undefined;
    }

    constructor(message: string, errors?: QueryError[], params?: Record<string, any>) {
        super();

        this.name = 'DatabaseError';
        this.message = message;

        this.params = {
            ...params,
            errorCode: params?.errorCode || 'INTERNAL',
        };

        if (errors) {
            errors.forEach(error => this.push(error));
        }
    }

    push(error: QueryError) {
        this.isRetriable = retriableCodes.includes(error.code);

        if (!this.errors) {
            this.errors = [error];
        } else {
            this.errors.push(error);
        }
    }
}
