import { getElement } from './load';

export function runTariffs() {
    new Content('.JS--TariffsCards', '.JS--TariffsButton');
}

enum ContentState {
    OPENING = 'OPENING',
    OPEN = 'OPEN',
    CLOSING = 'CLOSING',
    CLOSED = 'CLOSED',
}

export class Content {
    private state: ContentState = ContentState.CLOSED;
    private content: Element;
    private button: Element;
    private transition: NodeJS.Timeout | null = null;

    private removeTransition() {
        if (this.transition) {
            clearTimeout(this.transition);
            this.transition = null;
        }
    }

    private setTransition(action: () => void, timeout: number) {
        this.removeTransition();

        this.transition = setTimeout(() => {
            action();
            this.removeTransition();
        }, timeout);
    }

    private setState(state: ContentState) {
        this.state = state;
        if (state === ContentState.OPENING) {
            this.content.classList.add('Opening');
            this.content.classList.remove('Open');
            this.content.classList.remove('Closing');
            this.content.classList.remove('Closed');
            this.button.classList.add('Flipped');
        } else if (state === ContentState.OPEN) {
            this.content.classList.remove('Opening');
            this.content.classList.add('Open');
            this.content.classList.remove('Closing');
            this.content.classList.remove('Closed');
            this.button.classList.add('Flipped');
        } else if (state === ContentState.CLOSING) {
            this.content.classList.remove('Opening');
            this.content.classList.remove('Open');
            this.content.classList.add('Closing');
            this.content.classList.remove('Closed');
            this.button.classList.remove('Flipped');
        } else if (state === ContentState.CLOSED) {
            this.content.classList.remove('Opening');
            this.content.classList.remove('Open');
            this.content.classList.remove('Closing');
            this.content.classList.add('Closed');
            this.button.classList.remove('Flipped');
        }
    }

    private clickHandler() {
        if (this.state === ContentState.OPENING) {
            // This state lasts for a very short moment,
            // so we ignore click here.
        } else if (this.state === ContentState.OPEN) {
            this.setState(ContentState.CLOSING);

            this.setTransition(() => {
                this.setState(ContentState.CLOSED);
            }, 400);
        } else if (this.state === ContentState.CLOSING) {
            this.removeTransition();
            this.setState(ContentState.OPEN);
        } else if (this.state === ContentState.CLOSED) {
            this.setState(ContentState.OPENING);

            this.setTransition(() => {
                this.setState(ContentState.OPEN);
            }, 1);
        }
    }

    public constructor(contentSelector: string, buttonSelector: string) {
        this.content = getElement(contentSelector);
        this.button = getElement(buttonSelector);

        this.setState(ContentState.CLOSED);

        this.button.addEventListener('click', this.clickHandler.bind(this));
    }
}
