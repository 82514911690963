import { MS_PER_DAY } from '@ab-task/data';

export const TIMEZONES = [
    '-12',
    '-11',
    '-10',
    '-8',
    '-7',
    '-6',
    '-5',
    '-4',
    '-4.5',
    '-3',
    '-2.5',
    '-2',
    '-1',
    '0',
    '1',
    '2',
    '3',
    '4',
    '4.5',
    '5',
    '5.5',
    '5.75',
    '6',
    '6.5',
    '7',
    '8',
    '9',
    '9.5',
    '10',
    '11',
    '12',
    '13',
];

export function getDateDiffInDays(a: Date, b: Date) {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export function isSameDate(a: Date, b: Date) {
    return (
        a.getFullYear() === b.getFullYear() &&
        a.getMonth() === b.getMonth() &&
        a.getDate() === b.getDate()
    );
}
